import styled from "styled-components";

export const ActionPageStyled = styled.div`
  .custom_slider_heading_filter .upcoming_view_all_btn {
    padding: 0px 32px;
    border: 1px solid #000;
    font-family: BentonSansMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 21.6px;
    text-transform: uppercase;
    text-decoration: none;
    height: 41px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    color: #000;
    margin-right: 28px;
  }
  .custom_slider_heading_filter .upcoming_view_all_btn:hover {
    background: #000;
    color: #fff;
  }
`;
