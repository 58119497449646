import React, { useState, useEffect } from "react";
import { Row, Button } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import noImage from "../../assets/image/noImage.jpg";
import { ReactComponent as Blackbid } from "../../assets/image/blackbidIcon.svg";
import "./css/styles.css";
import { followbidCall, getTrandingLots } from "./../../library/Requests";
import { LOGINID } from "./../../Scenes/common";

import { connect, useDispatch } from "react-redux";
import * as actionCreators from "./../../actions/index.js";

import { AFTER_LOGIN_TRIGGER_PAGE } from "./../../library/Requests/eventConstants";

const TrendingLots = (props) => {
  const [trendingData, setTrendingData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState(15);

  const dispatch=useDispatch()

  useEffect(async () => {
    window.scrollTo(0, 0);
    getDataonLoad();
    window.document.addEventListener(
      AFTER_LOGIN_TRIGGER_PAGE,
      function (event) {
        followbidCallFunction(
          props?.afterLoginTriggerPage?.lotId || props.lotIdNumberValue?.lotId
        );
      },
      false
    );
  }, []);

  const getDataonLoad = async (pagetype) => {
    getTrendingLotsOnLoad(currentPage, pagetype);
  };

  const getTrendingLotsOnLoad = async (currentPage, pagetype) => {
    setIsLoading(true);
    const body = {
      page: currentPage,
      pageSize: pageData,
      isTrending: true,
      customerId: LOGINID,
    };
    const resp = await getTrandingLots(body);
    if (pagetype == "LoadMore") {
      setTrendingData([...trendingData, ...resp?.data]);
    } else {
      setTrendingData(resp?.data);
    }
    setTotalCount(resp?.totalCount);
    setIsLoading(false);
  };

  const loadMore = async () => {
    var a = currentPage + 1;
    setCurrentPage(a);
    getTrendingLotsOnLoad(a, "LoadMore");
  };

  const getmainImage = (lotItem) => {
    if (lotItem?.itemImages && lotItem?.itemImages.length > 0) {
      let itemURRL = lotItem?.itemImages.find((i) => i.displayOrder == 1)
        ?.vurtualPath;
      if (
        itemURRL === "string" ||
        itemURRL === null ||
        itemURRL === undefined ||
        itemURRL === ""
      ) {
        return noImage;
      } else {
        return itemURRL;
      }
    }
  };

  const gotoDetailsPage = (lotItem) => {
    props.history.push(`/lotDetails/${lotItem}`);
  };

  const followbidCallFunction = async (id) => {
    props?.afterLoginTriggerPageCall({});
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      if (userIn?.id) {
        const index = trendingData.findIndex((item) => item?.id === id);
        if (index > -1) {
          trendingData[index].ifollowed = true;
        }
        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: true,
          },
        };
        const resp = await followbidCall(body);
        setTrendingData(trendingData);
      }
    } else {
      const jsonD = {
        pageName: "followClick",
        lotId: id,
      };
      props?.afterLoginTriggerPageCall(jsonD);
      props?.LopinPopUpPage(true);
    }
  };

  const unFollowbidCallFunction = async (id) => {
    props?.afterLoginTriggerPageCall({});
    let userInfo = localStorage.getItem("customerInfoDetails");
    if (userInfo != undefined && userInfo != null && userInfo != "") {
      const userIn = JSON.parse(userInfo);
      console.log("userIn tk", userIn);
      if (userIn?.id) {
        const index = trendingData.findIndex((item) => item?.id === id);
        if (index > -1) {
          trendingData[index].ifollowed = false;
        }
        const body = {
          follow: {
            itemId: id,
            customerId: userIn?.id,
            isFollow: false,
          },
        };
        const resp = await followbidCall(body);
        setTrendingData(trendingData);
      }
    } else {
      const jsonD = {
        pageName: "followClick",
        lotId: id,
      };
      props?.afterLoginTriggerPageCall(jsonD);
      props?.LopinPopUpPage(true);
    }
  };

  return (
    <div className="trendinglotsMainDiv">
      <div className="custom_slider_heading_filter d-flex align-items-center">
        <h3 className="mb-0 mr-auto position-relative pl-3 ">
          Lots that might interest you
        </h3>
      </div>
      <Row className="d-flex">
        {trendingData?.map((lotItem, index) => {
          return (
            <div className="trendinglotImageMainDiv col-md-3" id={index}>
              <div
                className="imageDiv"
                onClick={() => gotoDetailsPage(lotItem?.id)}
              >
                <img
                  src={getmainImage(lotItem)}
                  className="trendingLotImage img-fluid border-0 h-100"
                />
              </div>
              <div>
                <h3 className="trendinglotImageTitle">{lotItem?.title}</h3>
                <div className=" d-md-flex align-items-start ">
                  <div className="trendingLotImageTextDiv mr-auto  align-items-center">
                    <span>Estimate</span>
                    <p>
                      {lotItem?.currency} {lotItem?.estimatedPrice}
                      {lotItem?.estimatedPriceTo && (
                        <>
                          - {lotItem?.currency} {lotItem?.estimatedPriceTo}
                        </>
                      )}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    {lotItem?.ifollowed ? (
                      <Button
                        onClick={() => {
                          unFollowbidCallFunction(lotItem?.id);
                          dispatch(actionCreators.followeddata(false))
                        }}
                        className="trendingLotFollowingBtn d-table text-decoration-none "
                      >
                        FOLLOWING
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          followbidCallFunction(lotItem?.id);
                          dispatch(actionCreators.followeddata(true))
                        }}
                        className="trendingLotFollowBtn d-table text-decoration-none "
                      >
                        FOLLOW
                      </Button>
                    )}
                    <Link
                      to={`/lotDetails/${lotItem?.id}`}
                      className="trendingLotFollowBtn d-flex align-items-center d-table text-decoration-none ml-2 trendingLotBidBtn"
                    >
                      <Blackbid className="bidSvg" /> BID
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </Row>
      <div className="spinnerDiv">
        {isLoading && (
          <>
            <div className="spinner-borderWord">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </>
        )}
        {!isLoading && totalCount > trendingData.length && (
          <Row className="loadMoreRow">
            <Button
              onClick={() => loadMore()}
              variant="custom"
              className="loadMoreBtn bottomPadding"
            >
              Load More
            </Button>
          </Row>
        )}
      </div>
    </div>
  );
};

//export default  TrendingLots;

const mapStateToProps = (trendingData) => {
  return trendingData;
};

export default connect(
  mapStateToProps,
  actionCreators
)(withRouter(TrendingLots));
