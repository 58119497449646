export const API_BASE = "/api";

var azure_accountID_id = "fineartblob";
export const azure_contentGuId = "temp";
export const VONTAGE_API_KEY = "47382381"; //"47354851"
export const VONTAGE_API_SECRET = "729e80e57105483f662ae1736f0fd07e81e1fc8c"; //"31c783736d0e36b726b1600cb9e5f8f20d65093b"
// export const SERVER_IP = 'https://api-development5.azurewebsites.net';
// 'https://api-development5.azurewebsites.net/dev';
// 'https://api-development5.azurewebsites.net/staging';
let SERVER_IP_URL = "https://api.fineart.hiphop";
let DOMAIN_URL1 = "https://fineart.hiphop/";
let ENV_SHOT_CODE1 = "prod";
let GOOGLE_TAG_MANAGER_CODE1 = "G-HC8N56YXRS";
let square_applicationId1 = "sq0idp-yso819kblu8aBT2mEh-n5g";
let square_locationId1 = "LMT7891DKS9E8";

if (typeof window !== "undefined") {
  var hostName = window.location.hostname;
  if (hostName === "localhost") {
    SERVER_IP_URL = "https://qaapi.fineart.hiphop";
    azure_accountID_id = "qafahhblob";
    DOMAIN_URL1 = "https://qa.fineart.hiphop/";
    ENV_SHOT_CODE1 = "qa";
    GOOGLE_TAG_MANAGER_CODE1 = "G-HC8N56YXRS";
    square_applicationId1 = "sandbox-sq0idb-TdB-E_OzxAj-G8a-_ruABA";
    square_locationId1 = "LQ1TGRJ32ZSB2";
  } else if (hostName === "qa.fineart.hiphop") {
    SERVER_IP_URL = "https://qaapi.fineart.hiphop";
    azure_accountID_id = "qafahhblob";
    DOMAIN_URL1 = "https://qa.fineart.hiphop/";
    ENV_SHOT_CODE1 = "qa";
    GOOGLE_TAG_MANAGER_CODE1 = "G-HC8N56YXRS";
    square_applicationId1 = "sandbox-sq0idb-TdB-E_OzxAj-G8a-_ruABA";
    square_locationId1 = "LQ1TGRJ32ZSB2";
  }
}
const map_url =
  "https://maps.googleapis.com/maps/api/js?key=AIzaSyDILWRZ3d-zi2xIjmATRtOUNkFJ8kEr4p0&libraries=places";

const EXCHANGERATE_API1 =
  "https://v6.exchangerate-api.com/v6/b9ac85ae6fa041b98dd0a5aa";
// let REACT_SIGNAL_URL1 = "https://qa.broadcast.berides.com/messages";
let REACT_SIGNAL_URL1 = "https://qaapi.fineart.hiphop/hubs/BidHub";

// SERVER_IP_URL = "https://api-fahh.azurewebsites.net";
export const SERVER_IP = SERVER_IP_URL;
export const azure_accountID = azure_accountID_id;
export const DOMAIN_URL = DOMAIN_URL1;
export const GOOGLE_MAP_URL = map_url;
export const ENV_SHOT_CODE = ENV_SHOT_CODE1;
export const EXCHANGERATE_API = EXCHANGERATE_API1;
export const GOOGLE_TAG_MANAGER_CODE = GOOGLE_TAG_MANAGER_CODE1;
export const square_applicationId = square_applicationId1;
export const square_locationId = square_locationId1;
export const signalHubUrl = REACT_SIGNAL_URL1;
