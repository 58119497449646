import React from "react";

import Carousel from "react-bootstrap/Carousel";

import Banner from "./../../assets/image/banner.png";
import BannerTwo from "./../../assets/image/banner2.png";
import { Link } from "react-router-dom";

import { getAllSiteManagersCall } from "./../../library/Requests/SiteManager";
import { getAllCategories } from "./../../library/Requests";
import ContentLoader from "react-content-loader";
import "./styles.css";
import { triggerMixpanelEvent } from "./../../Scenes/common";
import ContentLoaderSection from "./../../components/ContentLoaderSection/ContentLoaderSection";
import Slider, { Settings } from "react-slick";
import NextArrow from "./NextArrow";
import PreviousArrow from "./PreviousArrow";
import line_img from "../../assets/HomePage/Line-29.png";

class TopBannerSlider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      allData: [],
      allCategories: [],
      isLoading: false,
      hover: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllSiteManagers();
    triggerMixpanelEvent("home_page");
  }
  getAllSiteManagers = async () => {
    this.setState({ isLoading: true });
    const resp = await getAllSiteManagersCall("1"); //.splice(1)
    this.setState({ allData: resp?.data });
    const cate = await getAllCategories();
    this.setState({ allCategories: cate?.data, isLoading: false });
  };
  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  onHover = () => {
    this.setState({ hover: true });
  };
  onLeave = () => {
    this.setState({ hover: false });
  };

  readonly settings: Settings = {
    customPaging: (i: number) => <p>{` `}</p>,
    dots: true,
    dotsClass: "slideshow-slider-dots",
    lazyLoad: "progressive",
    infinite: true,
    nextArrow: <NextArrow />,
    prevArrow: <PreviousArrow />,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay:true,
    pauseOnHover: false,
  };

  render() {
    const { allData, allCategories, isLoading, hover } = this.state;
    return (
      <>
        {isLoading ? (
          <>
            <div className="TopSectionLoading">
              <ContentLoaderSection section="topbanner" />
            </div>
            <div className="HomePageCollectionsLoading">
              <ContentLoaderSection section="topbanner1" />
            </div>
          </>
        ) : (
          <div className="custom_top_banner_slider">
           <Slider {...this.settings}>

              {(allData || []).map((i: any, index: any) => (
                <div>
                  <figure>
                    <img
                      src={i.image}
                      alt="benner-slider-img"
                    />
                    <figcaption>
                    <div className="calander-banner-image-text-sec">
                      <span className="position-relative carosel-cat-name  carosel-cat-name-new font-weight-bold pl-2">
                      <img src={line_img} />{" "}

                        {index === 0 ? "Auction" : "Article"}
                      </span>

                      <h3 className="mb-2 font-weight-bold main-sub-page-title heroLineClamp">
                        {i.title}
                      </h3>
                      <p className="carosel-sub-sub-text  main-sub-page-sub-title">{i.subTitle}</p>
                      {/* <div className="carosel-btn-sec">
                        <a href={pageLink} target="_blank">
                          <button>
                            {index === 0 ? "GO TO AUCTION" : "READ"}
                          </button>
                        </a>
                      </div> */}
                      </div>
                    </figcaption>
                  </figure>
                </div>  
                )
              )}
            </Slider>
          </div>
        )}
      </>
    );
  }
}
export default TopBannerSlider;
