import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { Data } from "../../data/auction";
import { Container, Row, Col, Tabs, Tab } from "react-bootstrap";

import TopBannerSlider from "../../components/TopBannerSlider";
import ActionCalendarLeftFilter from "../../components/ActionCalendarLeftFilter";
import ActionCalendarTabMenu from "../../components/ActionCalendarTabMenu";
import {
  getallupcommingauctionsCall,
  getAllAuctions,
  getPastAuctions,
} from "./../../library/Requests";
import { UPDATE_CALENDERDATA } from "./../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "./../../Scenes/common";
import moment from "moment";
import { triggerMixpanelEvent } from "./../../Scenes/common";

import "./css/AuctionCalendar.css";

const AuctionCalendar = () => {
  const [tabKey, setTabKey] = useState("all");
  let months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const [tabMonths, setTabMonths] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [jsonData, setJsonData] = useState({});
  const [AllCollectionsFull, setAllCollectionsFull] = useState({});
  const [AllCollections, setAllCollections]: any = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLoadMore, setisLoadingLoadMore] = useState(false);
  const [isResultsPage, setisResultsPage] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [maintabKey, setMaintabKey]: any = useState("calender");
  const [selectedMonths, setSelectedMonths] = useState(moment().format("MMMM"));
  const [selectedYears, setSelectedYears] = useState(moment().format("YYYY"));

  // setting the tab

  const handleTab = (key: string) => {
    setTabKey(key);
  };

  //end

  // get All auctions Api call

  const getAllActions = async (body: any, type: any) => {
    if (type != "viewmore") {
      setIsLoading(true);
    } else {
      setisLoadingLoadMore(true);
    }
    if (isResultsPage) {
      // this.setState({isLoading:true})
      const resp1 = await getAllAuctions(body);

      await setAllCollectionsFull(resp1);

      setIsLoading(false);
      setisLoadingLoadMore(false);

      let AllCollectionsP: any = [];
      AllCollectionsP = resp1?.pastAuctions;
      if (type == "viewmore") {
        setAllCollections([...AllCollections, ...AllCollectionsP]);
        setIsLoading(false);
      } else {
        setAllCollections([AllCollectionsP]);
        setIsLoading(false);
      }
      updateMonthsDataFilter();
    } else {
      // this.setState({isLoading:true})
      body.SortColumn = "StartOnUtc";
      body.Sortorder = "asc";
      const resp = await getallupcommingauctionsCall(body);
      if (resp?.totalCount == 0) {
        // this.setState({ errorMsg: true });
      }

      setAllCollectionsFull(resp);
      setIsLoading(false);
      setisLoadingLoadMore(false);

      let AllCollectionsP: any = [];

      AllCollectionsP = resp?.auctions;
      if (type == "viewmore") {
        setAllCollections([...AllCollections, ...AllCollectionsP]);
        setIsLoading(false);
      } else {
        setAllCollections(AllCollectionsP);
        setIsLoading(false);
      }
      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      updateMonthsDataFilter();
    }
  };

  useEffect(() => {
    if (maintabKey === "auction-results") {
      const body = {
        page: currentPage,
        pageSize: 18,
        months: [selectedMonths],
        years: [selectedYears],
      };
      setisResultsPage(true);
      getPastActions(body, "");
      triggerMixpanelEvent("auction_results_page");
    } else {
      setisResultsPage(false);
      const body = {
        page: currentPage,
        pageSize: 18,
      };

      getAllActions(body, "");
      triggerMixpanelEvent("auction_calendar_page");
    }
  }, [maintabKey,selectedMonths,selectedYears]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.location.pathname == "/auction-results") {
      const body = {
        page: currentPage,
        pageSize: 18,
        months: [selectedMonths],
        years: [selectedYears],
      };
      setisResultsPage(true);
      getPastActions(body, "");
      triggerMixpanelEvent("auction_results_page");
    } else {
      const body = {
        page: currentPage,
        pageSize: 18,
      };
      triggerMixpanelEvent("auction_calendar_page");
      getAllActions(body, "");
    }

    // const mth: any = [];
    // Data?.map(
    //   (d) =>
    //     (mth[new Date(d.auctionStartDate).getUTCMonth()] =
    //       months[new Date(d.auctionStartDate).getUTCMonth()])
    // );
    // setTabMonths(Object.values(mth))
    document.title = "FAHH";
  }, []);

  const updateMonthsDataFilter = () => {
    const mth: any = [];
    (AllCollections || []).map((d: any) => {
      mth[moment(d?.auctionStartDate).format("M")] = moment(
        d?.auctionStartDate
      ).format("MMMM");
    });
    setTabMonths(Object.values(mth));

    setTabMonths(Object.values(mth));
  };

  const clickViewMore = () => {
    setCurrentPage(currentPage + 1);
    const body: any = jsonData;
    body.page = currentPage + 1;
    if (isResultsPage) {
      let jsonBody = {
        page: currentPage + 1,
        pageSize: 18,
        months: [selectedMonths],
        years: [selectedYears],
      };
      getPastActions(jsonBody, "viewmore");
    } else {
      getAllActions(body, "viewmore");
    }
  };

  const getPastActions = async (body: any, type: any) => {
    if (type != "viewmore") {
      setIsLoading(true);
    } else {
      setisLoadingLoadMore(true);
    }
    body.SortColumn = "EndOnUtc";
    body.Sortorder = "dsc";
    const resp1 = await getPastAuctions(body);
    if (resp1?.totalCount == 0) {
      // this.setState({ errorMsg: true });
    }
    setAllCollectionsFull(resp1);
    setIsLoading(false);
    setisLoadingLoadMore(false);

    if (type == "viewmore") {
      let AllCollectionsP: any = [];
      AllCollectionsP = resp1?.pastAuctions;

      setAllCollections([...AllCollections, ...AllCollectionsP]);

      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      updateMonthsDataFilter();
    } else {
      let AllCollectionsP: any = [];
      AllCollectionsP = resp1?.pastAuctions;
      setAllCollections(AllCollectionsP);

      triggerEventForProfileUpdate(UPDATE_CALENDERDATA);
      updateMonthsDataFilter();
    }
  };

  console.log(AllCollections, "AllCollections", AllCollectionsFull);

  const getUpdatedData = async (body: any) => {
    setCurrentPage(1);
    setJsonData(body);
    if (isResultsPage) {
      let jsonBody = {
        page: currentPage,
        pageSize: 18,
        months: [selectedMonths],
        years: [selectedYears],
      };
      getPastActions(jsonBody, "");
    } else {
      getAllActions(body, "");
    }
  };

  // render() {
  //   const {
  //     AllCollections,
  //     AllCollectionsFull,
  //     isLoading,
  //     isResultsPage,
  //     isLoadingLoadMore,
  //     errorMsg,
  //   } = this.state;

  return (
    <>
      {errorMsg ? (
        <p className="noResults noResults-auctionpafw" id="errorPage">
          No results are available in{" "}
          {isResultsPage ? "Auction Results" : "Auction Calendar"}
          {". "}
        </p>
      ) : (
        <div className="action_calendar_page_content">
          {!isResultsPage && <TopBannerSlider />}
          <div className="action_calendar_page_section">
            <Container>
              <h3 className="mb-0 position-relative pl-3">
                <span className="d-md-inline-block d-none">Auction</span>{" "}
                {isResultsPage ? "Results" : "Calendar"}
              </h3>

              <Tabs
                id="uncontrolled-tab-example"
                className="mb-3 mt-4 auction-calender-tabs"
                activeKey={maintabKey}
                onSelect={(k) => setMaintabKey(k)}
              >
                <Tab eventKey="calender" title="Calendar"></Tab>
                <Tab eventKey="auction-results" title="Auction Results"></Tab>
              </Tabs>
              <Row>
                <Col lg={3}>
                  <ActionCalendarLeftFilter
                    handleTab={handleTab}
                    tabMonths={tabMonths}
                    AllCollections={AllCollections}
                    getUpdatedDataCall={getUpdatedData}
                    AllCollectionsFull={AllCollectionsFull}
                    isResultsPage={isResultsPage}
                    setSelectedMonths={setSelectedMonths}
                    selectedMonths={selectedMonths}
                    selectedYears={selectedYears}
                    setSelectedYears={setSelectedYears}
                  />
                </Col>
                <Col lg={9}>
                  <ActionCalendarTabMenu
                    isLoading={isLoading}
                    isLoadingLoadMore={isLoadingLoadMore}
                    tabKey={tabKey}
                    handleTab={handleTab}
                    AllCollections={AllCollections}
                    AllCollectionsFull={AllCollectionsFull}
                    clickViewMore={clickViewMore}
                    isResultsPage={isResultsPage}
                    selectedMonths={selectedMonths}
                    selectedYears={selectedYears}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      )}
    </>
  );
};

export default AuctionCalendar;
