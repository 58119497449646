import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import time_fill from "./../../assets/main/time-fill.png";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { Row, Col, Container, Button } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import BidWhite from "../../assets/image/bid-white.png";
import * as signalR from "@microsoft/signalr";
import { SERVER_IP } from "./../../library/constants.js";
import PrevBidDetails from "../../components/PrevBidDetails/PrevBidDetails";

import Form from "react-bootstrap/Form";

import { LOGINID } from "./../../Scenes/common";

import moment from "moment";
import {
  getcollectionitembyidCall,
  getlivebidsCall,
  getbidincrementCall,
  gethighestbid,
  getCollectionItemsCall,
  getTimeZones,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  unfollowbidCall,
} from "./../../library/Requests";
import {
  autobidCallBroadCast,
  quickbidCallBroadCast,
} from "./../../library/Requests/PlaceBid";

const LotBox = (props: any) => {
  const {
    biddingShowNo,
    myLot,
    bannerTimeZone,
    currencyName,
    userDetails,
    placeQuickBidCall,
    autobidCallBroadCastCall,
    bidIncrementData,
    youHaveHighestBid,
    collectionData,
  } = props;
  const [showTimer, setShowTimer] = useState("loading" as any);
  const [showActionsBtn, setShowActionsBtn] = useState("placebid");
  const [nextBidAmount, setNextBidAmount] = useState("");
  const [bidsMetRP, setBidsMetRP] = useState("0");
  const [selectedMaxBid, setSelectedMaxBid] = useState("");
  const [highestBidResp, setHighestBidResp] = useState({} as any);
  // const [bidIncrementData, setBidIncrementData] = useState([] as any);
  const [dropdownvalues, setDropdownvalues] = useState([] as any);
  const [showProgressBarSec, setShowProgressBarSec] = useState(false);
  const [showPrevBids, setShowPrevBids] = useState(false);
  const [widthProgress, setWidthProgress] = useState("0%");
  const [lotDetails, setLotDetails] = useState({} as any);
  const [myLot1, setMyLot1] = useState({} as any);
  const [timeOutsval, setTimeOutsval] = useState([] as any);
  const [isClosedLot, setIsClosedLot] = useState("");

  const LotID = myLot?.id;
  //placebid
  //quick&maxbid
  //placequickbiid
  //placemaxbid

  useEffect(() => {
    // if (myLot1?.id !== myLot?.id && myLot?.id) {
    //   setMyLot1(myLot);
    //   // getTimeDefault();
    //   getNextBidAmount();
    //   if (!youHaveHighestBid) {
    //     setShowActionsBtn("quick&maxbid");
    //   }
    //   getLotDetailsCall();
    //   const connection = new signalR.HubConnectionBuilder()
    //     .withUrl(`${SERVER_IP}/hubs/BidHub`)
    //     .withAutomaticReconnect()
    //     .build();
    //   connection.on("BidInsertSignal", (response) => {});
    //   connection
    //     .start()
    //     .then((result) => {
    //       connection.on("BidInsertSignal", (response) => {
    //         getLatestDataFromSignal(response);
    //         console.log("signal responseresponse", response);
    //       });
    //     })
    //     .catch((err) => console.error(err.toString()));
    // }
  }, [myLot]);
  // useEffect(() => {
  // }, [myLot]);
  // const getLatestDataFromSignal = (resp: any) => {
  //   // for (var i = 0 as any; i < 1000; i++) {
  //   //   window.clearInterval(i);
  //   // }
  //   if (resp?.itemId == myLot?.id) {
  //     getLotDetailsCall();
  //     getNextBidAmount();
  //   }
  // };
  // const getLotDetailsCall = async () => {
  //   const collectionItem = await getcollectionitembyidCall(myLot?.id, LOGINID);
  //   // console.log(collectionItem?.data?.extentedExpiresOn);
  //   console.log("lot extented time ", collectionItem?.data?.extentedExpiresOn);
  //   setLotDetails(collectionItem?.data);
  //   if (collectionItem?.data?.outFromBid) {
  //     setShowActionsBtn("quick&maxbid");
  //   }
  // };
  // useEffect(() => {
  //   console.log("myLot?.extentedExpiresOn", myLot?.extentedExpiresOn);
  //   if (myLot?.extentedExpiresOn || myLot?.expiresOn) {
  //     getTimeDefault();
  //   }
  // }, [myLot?.extentedExpiresOn, myLot?.expiresOn]);

  // var inrerValX = null as any;
  // const getTimeDefault = () => {
  //   // console.log("getTimeDefault");
  //   // myLot?.expiresOnUtc
  //   const newTime = myLot?.extentedExpiresOn || myLot?.expiresOn;
  //   console.log("newTime", newTime);
  //   var currUTC = moment(newTime).format("MM/DD/YYYY hh:mm:ss A UTC");
  //   const collTime = new Date(currUTC).getTime();
  //   const currTime = new Date().getTime();
  //   console.log("currTime", currTime);
  //   console.log("collTime", collTime);
  //   if (currTime > collTime) {
  //     console.log("inside 111");
  //     setIsClosedLot("Closed");
  //     setShowTimer("Closed");
  //     // this.setState({ showLiveActionBtn: true });
  //   } else if (currTime < collTime) {
  //     console.log("inside 2222");
  //     StartTimerPage(collTime);
  //     if (inrerValX) {
  //       clearInterval(inrerValX);
  //     }

  //     inrerValX = setInterval(function () {
  //       StartTimerPage(collTime);
  //     }, 1000);

  //     const newValue = localStorage.getItem("newInter_Values_lot_box");
  //     // console.log("newValue", newValue);
  //     const newInterArray = newValue?.split("__");
  //     var newlistINtervals = [] as any;
  //     newInterArray?.map((IIMM: any) => {
  //       // console.log("IIMM", IIMM);
  //       if (IIMM) {
  //         const interLotId = IIMM.split("_")[0];
  //         const interValId = IIMM.split("_")[1];
  //         // console.log("interValId", interValId);
  //         if (interLotId == LotID) {
  //           // console.log("interLotId", interLotId);
  //           window.clearInterval(parseInt(interValId));
  //         } else {
  //           newlistINtervals.push(IIMM);
  //         }
  //       }
  //     });
  //     var newValues1 = newlistINtervals?.join("__");
  //     // var newInterV = newValues1 ? newValues1 : "";
  //     var newInterV = newValue ? newValue : "";
  //     newInterV = newInterV ? newInterV + "__" : "";
  //     newInterV += `${LotID}_${inrerValX}`;
  //     // timeOutsval.map((intvvv: any) => {
  //     //   console.log("intvvv", intvvv);
  //     //   // if(LotID===)
  //     // });
  //     // newInterV.push(`${LotID}_${inrerValX}`);
  //     localStorage.setItem("newInter_Values_lot_box", newInterV);
  //     // if (myLot?.id == "695" || true) {
  //     //   console.log("collTime", collTime);
  //     // }
  //     // console.log("inrerValX", inrerValX);
  //     // var Difference_In_Time = collTime - currTime;
  //     // var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
  //     // var days = Difference_In_Days.toString().substr(0, 1);
  //     // var daysFull = parseInt(days)+1;
  //     // this.setState({ biddingShowText: "Bidding Starts in:" });
  //     // console.log("Difference_In_Days",daysFull);
  //   } else {
  //     console.log("inside 4444");
  //     // setShowTimer("");
  //   }
  // };

  // const StartTimerPage = (countDownDate: any) => {
  //   // if (myLot?.id == "695" || true) {
  //   // console.log(`countDownDate_${myLot?.sno}`, countDownDate);
  //   // }
  //   var now = new Date().getTime();
  //   if (now < countDownDate) {
  //     var distance = countDownDate - now;
  //     var days = Math.floor(distance / (1000 * 60 * 60 * 24));
  //     var hours = Math.floor(
  //       (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  //     );
  //     var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
  //     // if (myLot?.id == "695" || true) {
  //     //   console.log(`minutes_${myLot?.id}`, minutes);
  //     // }

  //     var seconds = Math.floor((distance % (1000 * 60)) / 1000);
  //     let daysTime = "";
  //     var hours1 = hours > 9 ? hours : `0${hours}`;
  //     var minutes1 = minutes > 9 ? minutes : `0${minutes}`;
  //     var seconds1 = seconds > 9 ? seconds : `0${seconds}`;
  //     if (days > 0) {
  //       daysTime = days + "D " + hours1 + " : " + minutes1 + " : " + seconds1;
  //     } else {
  //       daysTime = hours1 + " : " + minutes1 + " : " + seconds1;
  //     }
  //     if (daysTime == "00 : 00 : 00") {
  //       // window.location.reload();
  //     }
  //     // if (myLot?.id == "695" || true) {
  //     //   console.log("minutes1", minutes1);
  //     // }
  //     setShowTimer(daysTime);
  //     if (daysTime == "00 : 01 : 00" || daysTime == "00 : 45 : 10") {
  //       //|| daysTime == "30D 01 : 05 : 30"
  //       //
  //       showTimeProgressBar();
  //       setShowProgressBarSec(true);
  //     } else if (minutes > 1) {
  //       setShowProgressBarSec(false);
  //     }
  //   } else {
  //     setShowTimer("");
  //     setIsClosedLot("Closed");
  //     setShowProgressBarSec(false);

  //     // this.getCollectionData(this.state.collectionData?.id)
  //   }
  // };
  // var i = 0;

  // const showTimeProgressBar = () => {
  //   if (i == 0) {
  //     i = 1;
  //     var elem = document.getElementById("myProgress-myBar");
  //     var width = 0;
  //     var id = setInterval(function () {
  //       if (width >= 60) {
  //         clearInterval(id);
  //         i = 0;
  //         setShowProgressBarSec(false);
  //       } else {
  //         width++;
  //         const newW = width * 1.66;
  //         // elem.style.width = width + "%";
  //         setWidthProgress(`${newW}%`);
  //       }
  //     }, 1000);
  //   }
  // };
  // const getNextBidAmount = async () => {
  //   const bidResp = await gethighestbid(myLot?.id);
  //   setHighestBidResp(bidResp);
  //   setNextBidAmount(bidResp?.nextBidAmount);

  //   setBidsMetRP(bidResp?.bidsMetReservedPriceCount);
  //   if (bidIncrementData?.length < 5) {
  //     const jsonVal = await getbidincrementCall();
  //     // setBidIncrementData(jsonVal?.increment);
  //     generateDropDownvalues(bidResp?.bidDetails, jsonVal?.increment);
  //   } else {
  //     generateDropDownvalues(bidResp?.bidDetails, bidIncrementData);
  //   }
  // };
  // const generateDropDownvalues = async (
  //   bidDetails: any,
  //   bidIncrementData: any
  // ) => {
  //   const iniPrice = bidDetails?.amount
  //     ? bidDetails?.amount
  //     : myLot?.initialBiddingPrice;
  //   if (iniPrice && bidIncrementData) {
  //     const amou1 = iniPrice;
  //     let bidAmout = parseInt(amou1);
  //     let tempFullStr = "";
  //     (bidIncrementData || []).map((item: any, index: any) => {
  //       if (item.priceFrom <= bidAmout && item.priceTo >= bidAmout) {
  //         let increMA = item.increment;
  //         bidAmout = bidAmout + increMA;
  //         tempFullStr = tempFullStr + "___" + bidAmout;
  //         const priceDiff = item.priceTo - item.priceFrom;
  //         const repeat = (priceDiff / increMA).toFixed();
  //         const repeatT = parseInt(repeat) + 2;
  //         for (var i = 0; i < repeatT; i++) {
  //           if (item.priceFrom <= bidAmout && item.priceTo >= bidAmout) {
  //             bidAmout = bidAmout + increMA;
  //             tempFullStr = tempFullStr + "___" + bidAmout;
  //           }
  //         }
  //       }
  //     });
  //     const newListTemp = tempFullStr?.split("___");
  //     if (newListTemp?.length < 10) {
  //       for (let index = 0; index < 10; index++) {
  //         bidAmout = bidAmout + 5000;
  //         tempFullStr = tempFullStr + "___" + bidAmout;
  //       }
  //     }
  //     // const { highestBidDetails } = this.state;
  //     setDropdownvalues(tempFullStr.split("___"));
  //     setSelectedMaxBid(tempFullStr.split("___")[1]);
  //     // this.setState({ highestBidDetails });
  //   }
  // };
  // const handlePlaceBid = () => {
  //   setShowActionsBtn("quick&maxbid");
  // };
  // const handleCancel = () => {
  //   setShowActionsBtn("quick&maxbid");
  // };
  // const handleQuickBod = () => {
  //   setShowActionsBtn("placequickbiid");
  // };
  // const handleSetMaxBid = () => {
  //   setShowActionsBtn("placemaxbid");
  // };
  // const placeQuickBid = async () => {
  //   if (nextBidAmount != "") {
  //     const body = {
  //       quickBid: {
  //         currency: currencyName,
  //         itemId: myLot?.id,
  //         customerId: userDetails?.id,
  //         maxAmount: nextBidAmount,
  //         type: "Online",
  //       },
  //     };
  //     await placeQuickBidCall(body);
  //     setShowActionsBtn("placebid");
  //   }
  // };
  // const PlaceAutoBid = async () => {
  //   if (selectedMaxBid != "") {
  //     // const { collectionData, currenciesList } = this.state;
  //     // let currencyName = currenciesList.find(
  //     //   (i: any) => collectionData?.currency == i?.id
  //     // )?.currencyCode;

  //     const body = {
  //       autoBid: {
  //         itemId: myLot?.id,
  //         customerId: userDetails?.id,
  //         currency: currencyName,
  //         maxAmount: selectedMaxBid,
  //         type: "Online",
  //       },
  //     };
  //     // this.setState({ pageLoading: true });
  //     const resp = await autobidCallBroadCastCall(body);
  //     setShowActionsBtn("placebid");
  //   }
  // };
  const handleShowPrevBids = (count: any) => {
    if (count > 0) {
      setShowPrevBids(true);
    }
  };
  const hideModal = () => {
    setShowPrevBids(false);
  };
  // console.log("lotDetails", lotDetails);
  // console.log("timeOutsval", timeOutsval);
  // console.log("isClosedLot", isClosedLot);
  const cBid = myLot?.currentBid || myLot?.initialBiddingPrice;
  return (
    <>
      <div className="card-box">
        {/* <div className="card-box-top-row">
          <div className="card-box-top-rightl-last">
              <span className="show-timer-sec">Ends In: {showTimer}</span>
            </div>
        </div> */}
        <div className="card-box-bottom">
          <div className="card-box-b-img-row">
            <div className="card-box-top-left">Lot {myLot?.sno} </div>
            <a href={`/lotDetails/${myLot?.id}`} target="_blank">
              <img src={myLot?.itemImages[0]?.vurtualPath} />{" "}
            </a>
          </div>
          <div>
            <div className="card-box-con-title">
              {" "}
              <a href={`/lotDetails/${myLot?.id}`} target="_blank">
                {myLot?.title}
              </a>
            </div>
            <div className="card-box-con-middle-row">
              <a href={`/lotDetails/${myLot?.id}`} target="_blank">
                <div className="card-box-con-estimate">
                  Estimate:{" "}
                  <span>
                    {currencyName} {myLot?.estimatedPrice?.toLocaleString()} -{" "}
                    {currencyName} {myLot?.estimatedPriceTo?.toLocaleString()}
                  </span>
                </div>
              </a>
              <div className="lots_cost lots_cost_lat lots_cost_lat_coll_details">
                {myLot?.status === 6 ? (
                  <>
                    <span className=" lotDetailsTextRightSide">Lot Sold: </span>
                    <span className="strong ">
                      {currencyName} {myLot?.soldFor?.toLocaleString()}
                    </span>
                  </>
                ) : myLot?.status === 7 || isClosedLot === "Closed" ? (
                  <span className="d-block lotDetailsTextRightSide">
                    Bidding is Closed
                  </span>
                ) : (
                  <>
                    <span className="d-block lotDetailsTextRightSide">
                      Current Bid:{" "}
                      <span className="strong  ">
                        {currencyName} {cBid?.toLocaleString()}
                        {/* {highestBidDetails?.amount?.toLocaleString()} */}
                      </span>
                      {` `} (
                      <span
                        className="bids-count-no1"
                        onClick={() => {
                          handleShowPrevBids(myLot?.bidsCount);
                        }}
                      >
                        {myLot?.bidsCount}{" "}
                        {myLot?.bidsCount > 1 ? "Bids" : "Bid"}
                      </span>
                      , Reserve
                      {myLot?.lotMetReservice ? "" : " Not"} Met)
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="card-box-b-con-row">
              <>
                {showTimer && (
                  <div className="card-box-reserve-met-time">
                    <span className="show-timer-sec">
                      Lot Closes:{" "}
                      <span className="show-timer-sec-timer">{showTimer}</span>
                    </span>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LotBox;
