// import axios from "axios";
// export function loadColor(){
//     return(dispatch)=>{
//         return axios.get("http://www.colr.org/json/color/random").then((response)=>{
//             dispatch(changeColor("#"+response.data.new_color));
//         })
//     }
// }
export function LopinPopUpPage(value = false) {
  return {
    type: "LOGIN_POPUP_UPDATE",
    payload: value,
  };
}
export function afterLoginTriggerPageCall(value = {}) {
  return {
    type: "AFTER_LOGIN_TRIGGER_PAGE",
    payload: value,
  };
}
export function loginSuccessData(value = {}) {
  return {
    type: "LOGIN_SUCCESS",
    payload: value,
  };
}
export function followeddata(value = {}) {
  if (value) {
    window.scrollTo(0, 0);
  }
  return {
    type: "FOLLOWED_DATA",
    payload: value,
  };
}
export function storeLotIdNumberValue(value = {}) {
  return {
    type: "STORE_LOTID_NUMBER_VALUE",
    payload: value,
  };
}

// export function changeColor(color){
//     return{
//         type:"CHANGE_COLOR",
//         color:color
//     }
// }
