import React, { Component } from "react";
import { Link, useHistory, withRouter } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";

import { Nav, Container, Form, FormControl, Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import Logo from "./../../assets/image/Footer_Logo.svg";
import ModalLogo from "../../assets/main/fahh_logo.png";
import { loginActionCall } from "./../../library/Requests";
import ForgotPassword from "../../components/ForgotPassword";
import Pass_hide from "./../../assets/password-hide.png";
import Pass_show from "./../../assets/password-show.png";
import { ReactComponent as CloseIcon } from "../../assets/image/closeIcon.svg";

import { connect } from "react-redux";
import * as actionCreators from "./../../actions/index.js";
// import Box from "../component/box.js";
import {
  AFTER_LOGIN_TRIGGER_PAGE,
  TRIGGER_LOGIN_POPUP,
  AFTER_PLACEBID_TRIGGER_PAGE,
  AFTER_REGISTERBID_TRIGGER_PAGE,
} from "./../../library/Requests/eventConstants";
import { triggerEventForProfileUpdate } from "./../../Scenes/common";
import {
  triggerMixpanelEvent,
  triggerUserFprofileInMixpanel,
} from "./../../Scenes/common";

class LoginPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userName: "",
      password: "",
      erroruserName: false,
      errorpassword: false,
      errUserNameText: "",
      errpasswordText: "",
      NOData: false,
      userId: "",
      loginError: "",
      showForgotPassword: false,
      passwordType: "password",
    };
  }

  componentDidMount() {
    const self = this;
    window.document.addEventListener(
      TRIGGER_LOGIN_POPUP,
      function (event) {
        localStorage.removeItem("customerInfoDetails");
        self.props.history.push(`/`);
        self.props.LopinPopUpPage(true);
      },
      false
    );
    console.log("props", this.props);
  }
  handleKeypress = (e) => {
    if (e?.keyCode === 13) {
      this.loginAction();
    }
  };
  handleForgotPassword = () => {
    this.setState({ showForgotPassword: true });
    console.log("handleForgotPassword");
    triggerMixpanelEvent("forgot_password_click");
  };
  handleHideForgotPassword = () => {
    this.setState({ showForgotPassword: false });
    this.props.LopinPopUpPage(false);
  };
  togglePassword = () => {
    if (this.state.passwordType === "text") {
      this.setState({ passwordType: "password" });
    } else {
      this.setState({ passwordType: "text" });
    }
  };

  ChangeValue = async (selectedType, selectedvalue) => {
    this.setState({ [selectedType]: selectedvalue });
    if (selectedType == "userName") {
      this.setState({ erroruserName: false });
    } else {
      this.setState({ errorpassword: false });
    }
  };
  loginAction = async () => {
    const { userName, password } = this.state;
    if (userName != "" && password != "") {
      const body = {
        checkoutAsGuest: false,
        email: userName,
        password: password,
      };
      const resp = await loginActionCall(body);
      if (resp?.success) {
        this.props.LopinPopUpPage(false);
        this.props.loginSuccessData(resp?.customer);
        localStorage.removeItem("customerInfoDetails");
        localStorage.removeItem("cat");
        localStorage.removeItem("crt");
        localStorage.setItem(
          "customerInfoDetails",
          JSON.stringify(resp?.customer)
        );
        localStorage.setItem("cat", resp?.accessToken);
        localStorage.setItem("crt", resp?.refreshToken);
        console.log(
          "this.props?.afterLoginTriggerPage?.pageName",
          this.props?.afterLoginTriggerPage
        );
        triggerMixpanelEvent("login_success");
        triggerUserFprofileInMixpanel(resp?.customer);
        if (
          this.props?.afterLoginTriggerPage?.returnUrl &&
          this.props?.afterLoginTriggerPage?.returnUrl !== ""
        ) {
          window.location.href = this.props?.afterLoginTriggerPage?.returnUrl;
        } else if (
          this.props?.afterLoginTriggerPage &&
          this.props?.afterLoginTriggerPage?.pageName &&
          this.props?.afterLoginTriggerPage?.pageName != ""
        ) {
          const pageName = this.props?.afterLoginTriggerPage?.pageName;
          if (pageName == "placeBid") {
            triggerEventForProfileUpdate(AFTER_PLACEBID_TRIGGER_PAGE);
          }
          if (pageName == "registerBid") {
            triggerEventForProfileUpdate(AFTER_REGISTERBID_TRIGGER_PAGE);
          } else {
            this.props.storeLotIdNumberValue(this.props?.afterLoginTriggerPage);
            triggerEventForProfileUpdate(AFTER_LOGIN_TRIGGER_PAGE);
          }
        } else {
          var locUrl = this.props?.location.pathname;
          if (locUrl) {
            if (
              locUrl?.includes("lotDetails") ||
              locUrl?.includes("collectionDetails")
            ) {
              window.location.reload();
            } else {
              window.location.href = "/";
            }
          } else {
            window.location.href = "/";
          }
        }
      } else if (resp?.errors.length > 0) {
        this.setState({ loginError: resp?.errors[0] });
        triggerMixpanelEvent("login_error", { error_message: resp?.errors[0] });
      }
    } else {
      if (userName === "") {
        this.setState({
          erroruserName: true,
          errUserNameText: "Please enter the user name",
        });
      }
      if (password === "") {
        this.setState({
          errorpassword: true,
          errpasswordText: "Please enter the password",
        });
      }
    }
  };

  render() {
    const { show } = this.props;
    const {
      userName,
      password,
      erroruserName,
      errorpassword,
      errUserNameText,
      errpasswordText,
      userId,
      loginError,
      showForgotPassword,
      passwordType,
    } = this.state;
    console.log("this.props", this.props);
    const hideModal = () => {
      this.props.LopinPopUpPage(false);
    };
    const showPage = this.props?.showLopinPopUp ?? false;
    return (
      <>
        {showForgotPassword ? (
          <ForgotPassword
            onHide={this.handleHideForgotPassword}
            show={showForgotPassword}
          />
        ) : (
          <Modal
            show={showPage}
            onHide={() => {
              this.props.LopinPopUpPage(false);
            }}
            centered
            className="login_modal"
          >
            <Modal.Body>
              {/* <Modal.Title className="BidIncrementTitle">
                <h1>Bid Increments Table</h1>
              </Modal.Title> */}
              <CloseIcon
                className="modalCloseIcon-login-popup"
                onClick={hideModal}
              />
              <div className="login-popup-content-sec-body">
                <h3 className="login-popup-content-sec-body-con">
                  <span>Welcome!</span>
                  <img
                    src={ModalLogo}
                    alt="login_logo"
                    className="login_logo login_logo_sign_in_model  "
                  />
                </h3>
                <p className="sign_in_continue_text">Log in to continue!</p>
                <Form>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Email address*</Form.Label>
                    <Form.Control
                      value={userName}
                      onChange={(e) => {
                        this.ChangeValue("userName", e.target.value);
                      }}
                      type="email"
                      onKeyDown={this.handleKeypress}
                      placeholder="Email"
                    />
                    {erroruserName && (
                      <Form.Text>
                        {/* Sorry, we can’t find an account with this email address */}
                        {errUserNameText}
                      </Form.Text>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword">
                    <Form.Label>Password*</Form.Label>
                    <div className="create-password-sec">
                      <Form.Control
                        value={password}
                        onChange={(e) => {
                          this.ChangeValue("password", e.target.value);
                        }}
                        onKeyDown={this.handleKeypress}
                        type={passwordType}
                        placeholder="Password"
                      />
                      <div className="input-group-btn">
                        <span className="btn" onClick={this.togglePassword}>
                          {passwordType === "password" ? (
                            <img
                              className="password-hide-img"
                              src={Pass_show}
                            />
                          ) : (
                            <img
                              className="password-hide-img"
                              src={Pass_hide}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    {errorpassword && (
                      <Form.Text>
                        {/* Invalid Password */}
                        {errpasswordText}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <div className="forgot_psw_text">
                    <span
                      onClick={this.handleForgotPassword}
                      className="forgot_psw text-decoration-none"
                    >
                      Forgot password?
                    </span>
                  </div>
                  {loginError != "" && (
                    <div className="login_error_msg">{loginError}</div>
                  )}
                </Form>
                <div className="login_keepmelogin_btn d-flex align-items-center flex-wrap">
                  <Form.Group
                    controlId="formBasicCheckbox"
                    className="mr-auto mb-0 keep-margin-login-check d-md-block"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Keep me logged in"
                      defaultChecked
                    />
                  </Form.Group>
                  <Button
                    variant="secondary login_btn"
                    onClick={() => {
                      this.loginAction();
                    }}
                  >
                    LOGIN
                  </Button>
                </div>
                <div className="sign_up_text">
                  <h5>
                    Don’t have an account?
                    <span
                      className="font-weight-bold font-weight-bold-login-poup"
                      onClick={() => {
                        window.location.href =
                          "/sign-up?returnUrl=" + window.location.pathname;
                        this.props.LopinPopUpPage(false);
                      }}
                    >
                      {" "}
                      Sign up
                    </span>
                  </h5>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return state;
};
// export default withRouter(LoginPopup);

export default connect(mapStateToProps, actionCreators)(withRouter(LoginPopup));

// import React from 'react';
// import {connect} from "react-redux";
// import * as actionCreators from "../actions/index.js"
// import Box from "../component/box.js";
// class BoxCon extends React.Component{
//     render(){
//         return(
//             <Box handleClick={this.props.loadColor} color={this.props.color}></Box>
//         )
//     }
// }

// const mapStateToProps=(state)=>{
//     return state
// };

// export default connect (mapStateToProps, actionCreators)(BoxCon);
