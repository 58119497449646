import React from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import FormCheck from "react-bootstrap/FormCheck";

import AccountSlideBar from "../../components/AccountSlideBar";

import ManageProfileModal from "../../components/ManageProfileModal";
import ChangePasswordModal from "../../components/ChangePasswordModal";
import VerifyAccountModal from "../../components/VerifyAccountModal";

import "./css/Profile.css";
import {
  getbusinessuserbyidCall,
  editbusinessuserCall,
} from "./../../library/Requests";
import {
  getcustomeraddressCall,
  editcustomeraddressCall,
  deletecustomeraddressCall,
} from "./../../library/Requests/MyAccount";
import { isMobile } from "react-device-detect";
import { ReactComponent as RightChevronIcon } from "../../assets/image/RightChevronIcon.svg";
import CustomModal from "../../components/CustomModel";
import { triggerMixpanelEvent } from "./../../Scenes/common";
class Profile extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      manageAddress: false,
      userData: {},
      PopUpdata: {},
      userAddress: [],
      isLoading: false,
      showConformationModel: false,
      userStatus: "Completed",
      loadingAddress: false,
      addressWarningPopup: false,
    };
  }

  componentDidMount() {
    this.checkLogin();
    triggerMixpanelEvent("profile_page", {});
    document.title = "FAHH-Profile Page";
  }
  getUserDetails = async (id: any) => {
    this.setState({ isLoading: true });
    const resp = await getbusinessuserbyidCall(id);
    const respt = resp?.customer;
    if (resp?.customer?.id) {
      // let userInfo = localStorage.getItem("customerInfoDetails");
      // if (userInfo != undefined && userInfo != null && userInfo != "") {
      //   const userIn = JSON.parse(userInfo);
      //   userIn.billingAddressId = resp?.addressId;
      localStorage.removeItem("customerInfoDetails");
      localStorage.setItem(
        "customerInfoDetails",
        JSON.stringify(resp?.customer)
      );
      // }
    }

    this.setState({ userData: respt, isLoading: false });
    const PopUpdata1 = {
      firstName: respt?.firstName,
      lastName: respt?.lastName,
      title: respt?.title,
      email: respt?.email,
      phoneNumber: respt?.phoneNumber,
      phoneCode: respt?.phoneCode,
    };
    this.setState({ PopUpdata: PopUpdata1 });
    const userAddress = resp?.address;
    if (!userAddress || !userAddress?.address1) {
      this.setState({ userStatus: "step2" });
    } else if (!respt?.addressProof || !respt?.identityProof) {
      this.setState({ userStatus: "step3" });
    }
    if (respt?.isVerified) {
      this.setState({ userStatus: "" });
    }
    if (!respt?.active && respt?.isVerified) {
      this.setState({ userStatus: "step3" });
    }

    this.setState({ loadingAddress: true });

    const resp1 = await getcustomeraddressCall(id);
    this.setState({ loadingAddress: false, userAddress: resp1?.address });
  };
  checkLogin = () => {
    const userD = localStorage.getItem("customerInfoDetails");
    if (userD != undefined && userD != null && userD != "") {
      const userIn = JSON.parse(userD);
      if (userIn?.id) {
        this.setState({ userIn: userIn?.id });
        var Lid = this.props?.match?.params?.id;
        if (Lid) {
          this.getUserDetails(Lid);
        } else {
          this.getUserDetails(userIn?.id);
        }
      } else {
        this.props.history.push(`/`);
      }
    } else {
      this.props.history.push(`/`);
    }
  };
  changeValueInput = async (selectedType: any, selectedvalue: any) => {
    const PopUpdata1 = this.state.PopUpdata;
    const selectedvalue1 = selectedvalue.trim() === "" ? "" : selectedvalue;
    if (selectedType == "phoneNumber") {
      const re = /^[0-9-\b]+$/;
      if (selectedvalue1 === "" || re.test(selectedvalue1)) {
        PopUpdata1[selectedType] = selectedvalue1?.replace(/[\D]/g, "");
      }
    } else {
      PopUpdata1[selectedType] = selectedvalue1;
    }
    this.setState({ PopUpdata: PopUpdata1 });
  };
  onConfirmSave = async () => {
    const { PopUpdata, userData } = this.state;
    const body = {
      customer: {
        id: userData?.id,
        title: PopUpdata.title,
        firstName: PopUpdata.firstName,
        lastName: PopUpdata.lastName,
        email: PopUpdata.email,
        phoneNumber: PopUpdata?.phoneNumber,
        phoneCode: PopUpdata?.phoneCode,
      },
    };
    const resp = await editbusinessuserCall(body);
    this.getUserDetails(userData?.id);
    // this.setState({manageAddress: true})
  };
  radiChangeBtn = async (user: any) => {
    const userAddress1 = this.state.userAddress;
    userAddress1.map((userda: any, index2: any) => {
      userAddress1[index2].isPrimary = false;
    });
    const index = userAddress1.findIndex((item: any) => item?.id === user?.id);
    if (index > -1) {
      userAddress1[index].isPrimary = true;
      this.setState({ userAddress: userAddress1 });
    }
    const body = {
      costomerId: this.state?.userData?.id,
      address: {
        id: userAddress1[index]?.id,
        isPrimary: true,
      },
    };
    const resp = await editcustomeraddressCall(body);
  };
  editAddressUser = (user: any) => {
    this.props.history.push(`/edit-address/${user?.id}`);
  };
  deleteAddressUser = async (user: any) => {
    console.log("user", user);
    if (user?.isPrimary) {
      this.setState({ addressWarningPopup: true });
    } else {
      this.setState({ showConformationModel: true });
    }
    this.setState({ selectedAddress: user });
  };
  deleteAddressUserNew = async () => {
    const { PopUpdata, userData } = this.state;

    let user = this.state.selectedAddress;
    this.setState({ showConformationModel: false });
    const userAddress1 = this.state.userAddress;
    const index = userAddress1.findIndex((item: any) => item?.id === user?.id);
    if (index > -1) {
      userAddress1.splice(index, 1);
      this.setState({ userAddress: userAddress1 });
    }
    const resp = await deletecustomeraddressCall(user?.id);
    this.getUserDetails(userData?.id);
  };
  getfullName = (user: any) => {
    let fullname = user?.title ? `${user?.title} ` : "";
    fullname += user?.firstName ? user?.firstName : "";
    fullname += user?.lastName ? ` ${user?.lastName}` : "";
    return fullname;
  };
  getfullAddress = (user: any) => {
    let fullname = user?.address1 ? user?.address1 : "";
    fullname += user?.address2 ? `, ${user?.address2}` : "";
    fullname += user?.city ? `, ${user?.city}` : "";
    fullname += user?.state ? `, ${user?.state}` : "";
    fullname += user?.county ? `, ${user?.county}` : "";
    fullname += user?.zipPostalCode ? `, ${user?.zipPostalCode}` : "";
    return fullname;
  };
  comPleteProfile = () => {
    this.props.history.push(`/sign-up/${this.state.userStatus}`);
  };
  formatPhoneNumber = (phoneNumberString: any) => {
    if (phoneNumberString) {
      var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
      var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        var intlCode = match[1] ? "+1 " : "";
        return [intlCode, "", match[2], "-", match[3], "-", match[4]].join("");
      }
      return null;
    } else {
      return "";
    }
  };

  render() {
    const {
      userData,
      PopUpdata,
      userAddress,
      manageAddress,
      isLoading,
      showConformationModel,
      userStatus,
      loadingAddress,
      addressWarningPopup,
    } = this.state;
    const self = this;
    return (
      <>
        <div className="profile_page_content account_all_page">
          <Container>
            <Row className="profile_page_left_right_content">
              <AccountSlideBar />
              <Col lg={9}>
                <div className="profile_page_right_content account_all_right_content">
                  {isLoading ? (
                    <div className="spinner_Loading_icon">
                      <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="profile_page_active_bid account_all_right_section">
                        {!userData?.active && userData?.isVerified && (
                          <div className="user-rejected-sec-con">
                            <div className="user-rejected-sec-con-sec">
                              <p>
                                Your ID proof document has been rejected, It
                                appears that there were issues with the provided
                                ID. To proceed, please resubmit a clear and
                                valid ID image.
                              </p>
                              <button onClick={this.comPleteProfile}>
                                REUPLOAD
                              </button>
                            </div>
                          </div>
                        )}
                        <Row className="account_all_right_content_heading pb-3 header">
                          <Col>
                            <h4 className="mb-0">Basic Information</h4>
                          </Col>
                          <Col className="text-right manageProfileCol">
                            <div className="btn-wrap">
                              <ManageProfileModal
                                userDataManage={PopUpdata}
                                ChangeValue={this.changeValueInput}
                                onConfirmSave={this.onConfirmSave}
                              />
                            </div>
                          </Col>
                        </Row>
                        <div className="profile_no_activity">
                          <Row className="form-group-wrap">
                            <Col md={"auto"} className="pb-4 info">
                              <label>Full Name</label>
                              <p className="mb-0 data">
                                {userData?.title}{" "}
                                {`${userData?.firstName} ${userData?.lastName}`}
                              </p>
                            </Col>
                            <Col md={"auto"} className="pb-4 info">
                              <label>Email Address</label>
                              <p className="mb-0 data">{userData?.email} </p>
                            </Col>
                            <Col md={"auto"} className="pb-4 info">
                              <label>Phone Number</label>
                              <p className="mb-0 data">
                                {" "}
                                {userData?.phoneCode ? "+" : ""}
                                {userData?.phoneCode}{" "}
                                {this.formatPhoneNumber(userData?.phoneNumber)}{" "}
                              </p>
                            </Col>
                            <Col md={"auto"} className="pb-4 info">
                              <label>Password</label>
                              <p className="mb-0 pb-3 data">********</p>
                              <p className="mb-0 pb-3 data">
                                {" "}
                                <ChangePasswordModal />
                              </p>
                            </Col>
                          </Row>
                          {isMobile && false ? (
                            ""
                          ) : (
                            <>
                              {userStatus == "step2" ||
                              userStatus == "step3" ? (
                                <Row className="btm-content">
                                  <Col>
                                    <p className="pb-3 mb-0">
                                      Your account has not been verified. To
                                      participate in auctions and enjoy all of
                                      the features of FineArt.HipHop, please
                                      take a moment to verify your account.
                                    </p>
                                    <Button
                                      variant="primary"
                                      className="btn btn-outline-primary btn-md onHover"
                                      onClick={this.comPleteProfile}
                                    >
                                      COMPLETE PROFILE
                                    </Button>
                                  </Col>
                                </Row>
                              ) : userData?.isBlocked ? (
                                <Row className="btm-content">
                                  <Col>
                                    <p className="pb-3 mb-0 blockedRedcoclor">
                                      Your account has been blocked. For more
                                      details please contact
                                      support@fineart.hiphop
                                    </p>
                                  </Col>
                                </Row>
                              ) : userStatus == "Completed" ? (
                                <Row className="btm-content">
                                  <Col>
                                    <p className="pb-3 mb-0">
                                      Your account is not verified. To
                                      participate in auctions, please take a
                                      moment to verify your account.
                                    </p>
                                    <VerifyAccountModal userData={userData} />
                                  </Col>
                                </Row>
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                  {!isLoading && (
                    <>
                      {loadingAddress ? (
                        <div className="spinner_Loading_icon">
                          <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      ) : (
                        <>
                          <div
                            className={`profile_page_past_bid account_all_right_section mt-4 p-lg-4 p-md-3 pb-0 ${
                              manageAddress && "active"
                            }`}
                          >
                            <Row className="account_all_right_content_heading pb-3">
                              <Col md={6} sm={4}>
                                <h4 className="mb-0">Address Book</h4>
                              </Col>
                              <Col
                                md={6}
                                sm={8}
                                className="text-right d-flex align-items-center justify-content-sm-end justify-content-between"
                              >
                                <div className="btn-wrap mr-1 mr-sm-3 add_btn">
                                  <Link
                                    to="/add-address"
                                    className="btn btn-outline-primary btn-md onHover"
                                  >
                                    ADD ADDRESS
                                  </Link>
                                </div>
                                <div className="btn-wrap manage_btn">
                                  <Button
                                    className="btn btn-outline-primary btn-md onHover"
                                    onClick={() => {
                                      this.setState({ manageAddress: true });
                                    }}
                                  >
                                    MANAGE ADDRESS
                                  </Button>
                                </div>
                                <div className="btn-wrap cancel_btn">
                                  <Button
                                    className="btn btn-outline-primary btn-md onHover"
                                    onClick={() => {
                                      this.setState({ manageAddress: false });
                                    }}
                                  >
                                    CANCEL
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row className="profile_no_activity">
                              {(userAddress || []).map(
                                (user: any, index: any) => {
                                  return (
                                    <>
                                      <Col
                                        lg={3}
                                        md={4}
                                        className="select_address_content"
                                      >
                                        {!user?.isPrimary && (
                                          <p className="select_address_content-heading">Address {index + 1}</p>
                                        )}
                                        {(manageAddress || user?.isPrimary) && (
                                          <div
                                            className="radioWrapw"
                                            onClick={() => {
                                              self.radiChangeBtn(user);
                                            }}
                                          >
                                            <Form.Check
                                              type="radio"
                                              checked={user?.isPrimary || false}
                                              label={
                                                manageAddress &&
                                                !user?.isPrimary
                                                  ? "Make Primary"
                                                  : "Primary"
                                              }
                                              name={`formHorizontalRadios${index}`}
                                            />
                                          </div>
                                        )}
                                        <p>{this.getfullName(user)}</p>
                                        <p>{this.getfullAddress(user)}</p>
                                        <p>
                                          {user?.phoneCode
                                            ? "+" + user?.phoneCode + ""
                                            : ""}{" "}
                                          {this.formatPhoneNumber(
                                            user?.phoneNumber
                                          )}{" "}
                                        </p>
                                        {manageAddress && (
                                          <p className="editDeleteUser">
                                            {" "}
                                            <span
                                              onClick={() => {
                                                this.editAddressUser(user);
                                              }}
                                            >
                                              {" "}
                                              EDIT
                                            </span>{" "}
                                            {!user?.isPrimary && (
                                              <span
                                                onClick={() => {
                                                  this.deleteAddressUser(user);
                                                }}
                                              >
                                                {" "}
                                                DELETE
                                              </span>
                                            )}
                                          </p>
                                        )}
                                      </Col>
                                    </>
                                  );
                                }
                              )}
                            </Row>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
          <CustomModal
            show={showConformationModel}
            titleText="Are you sure you want to delete this address?"
            deleteText="Yes"
            cancelText="No"
            onCancel={() => this.setState({ showConformationModel: false })}
            onDelete={() => this.deleteAddressUserNew()}
          />
          <CustomModal
            show={addressWarningPopup}
            titleText="Please make it another Address as Primary"
            deleteText="OK"
            cancelText=""
            onCancel={() => this.setState({ addressWarningPopup: false })}
            onDelete={() => {}}
          />
        </div>
      </>
    );
  }
}

export default Profile;
