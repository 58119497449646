import ContentLoader from "react-content-loader";

const ContentLoaderSection = (props: any) => {
  const { section } = props;
  return (
    <>
      {section === "lotdetails" ? (
        <>
          <ContentLoader viewBox="0 0 800 400" height={400} width={800}>
            <rect x="470" y="104" rx="5" ry="5" width="220" height="10" />
            <rect x="470" y="124" rx="5" ry="5" width="220" height="10" />
            <rect x="470" y="144" rx="5" ry="5" width="220" height="10" />
            <rect x="64" y="18" rx="0" ry="0" width="346" height="200" />
            <rect x="229" y="300" rx="0" ry="0" width="0" height="0" />
            <rect x="111" y="340" rx="0" ry="0" width="0" height="0" />
            <rect x="121" y="342" rx="0" ry="0" width="0" height="0" />
            <rect x="470" y="18" rx="0" ry="0" width="300" height="25" />
            <rect x="470" y="58" rx="0" ry="0" width="300" height="6" />
            <rect x="470" y="68" rx="0" ry="0" width="300" height="6" />
            <rect x="470" y="78" rx="0" ry="0" width="300" height="6" />
            <rect x="798" y="135" rx="0" ry="0" width="0" height="0" />
            <rect x="731" y="132" rx="0" ry="0" width="0" height="0" />
            <rect x="470" y="179" rx="0" ry="0" width="70" height="30" />
            <rect x="560" y="179" rx="0" ry="0" width="70" height="30" />
            <rect x="112" y="250" rx="5" ry="5" width="60" height="60" />
            <rect x="198" y="250" rx="5" ry="5" width="60" height="60" />
            <rect x="295" y="250" rx="5" ry="5" width="60" height="60" />
            <rect x="70" y="340" rx="0" ry="0" width="131" height="12" />
            <rect x="70" y="370" rx="0" ry="0" width="566" height="12" />
            <rect x="70" y="390" rx="0" ry="0" width="566" height="12" />
          </ContentLoader>
        </>
      ) : section === "grid" ? (
        <>
          <ContentLoader
            width={800}
            height={575}
            viewBox="0 0 800 575"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="537" y="9" rx="2" ry="2" width="140" height="10" />
            <rect x="14" y="30" rx="2" ry="2" width="667" height="11" />
            <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
            <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
            <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
            <rect x="12" y="283" rx="2" ry="2" width="211" height="211" />
            <rect x="240" y="281" rx="2" ry="2" width="211" height="211" />
            <rect x="468" y="279" rx="2" ry="2" width="211" height="211" />
            <circle cx="286" cy="536" r="12" />
            <circle cx="319" cy="535" r="12" />
            <circle cx="353" cy="535" r="12" />
            <rect x="378" y="524" rx="0" ry="0" width="52" height="24" />
            <rect x="210" y="523" rx="0" ry="0" width="52" height="24" />
            <circle cx="210" cy="535" r="12" />
            <circle cx="428" cy="536" r="12" />
          </ContentLoader>
        </>
      ) : section === "auctioncalender" ? (
        <>
          <ContentLoader
            width={800}
            height={575}
            viewBox="0 0 800 575"
            backgroundColor="#f3f3f3"
            foregroundColor="#ecebeb"
          >
            <rect x="537" y="9" rx="2" ry="2" width="140" height="10" />
            <rect x="14" y="30" rx="2" ry="2" width="667" height="11" />
            <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
            <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
            <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
            <rect x="12" y="283" rx="2" ry="2" width="211" height="211" />
            <rect x="240" y="281" rx="2" ry="2" width="211" height="211" />
            <rect x="468" y="279" rx="2" ry="2" width="211" height="211" />
            <circle cx="286" cy="536" r="12" />
            <circle cx="319" cy="535" r="12" />
            <circle cx="353" cy="535" r="12" />
            <rect x="378" y="524" rx="0" ry="0" width="52" height="24" />
            <rect x="210" y="523" rx="0" ry="0" width="52" height="24" />
            <circle cx="210" cy="535" r="12" />
            <circle cx="428" cy="536" r="12" />
          </ContentLoader>
        </>
      ) : section === "topbanner" ? (
        <>
          <ContentLoader viewBox="0 0 100% 470" height={470} width={"100%"}>
            <rect x="100" y="0" rx="5" ry="5" width="80%" height="20" />
            <rect x="100" y="42" rx="5" ry="5" width="80%" height="400" />
          </ContentLoader>
        </>
      ) : section === "topbanner1" ? (
        <>
          <ContentLoader viewBox="0 0 100% 507" height={507} width={"100%"}>
            <rect x="30" y="20" rx="0" ry="0" width="150" height="23" />
            <rect x="30" y="60" rx="0" ry="0" width="350" height="330" />
            <rect x="30" y="400" rx="0" ry="0" width="350" height="15" />
            <rect x="30" y="425" rx="0" ry="0" width="140" height="15" />

            <rect x="430" y="60" rx="0" ry="0" width="350" height="330" />
            <rect x="430" y="400" rx="0" ry="0" width="350" height="15" />
            <rect x="430" y="425" rx="0" ry="0" width="140" height="15" />

            <rect x="830" y="60" rx="0" ry="0" width="350" height="330" />
            <rect x="830" y="400" rx="0" ry="0" width="350" height="15" />
            <rect x="830" y="425" rx="0" ry="0" width="140" height="15" />
          </ContentLoader>
          <ContentLoader viewBox="0 0 100% 507" height={507} width={"100%"}>
            <rect x="30" y="20" rx="0" ry="0" width="150" height="23" />
            <rect x="30" y="60" rx="0" ry="0" width="350" height="330" />
            <rect x="30" y="400" rx="0" ry="0" width="350" height="15" />
            <rect x="30" y="425" rx="0" ry="0" width="140" height="15" />

            <rect x="430" y="60" rx="0" ry="0" width="350" height="330" />
            <rect x="430" y="400" rx="0" ry="0" width="350" height="15" />
            <rect x="430" y="425" rx="0" ry="0" width="140" height="15" />

            <rect x="830" y="60" rx="0" ry="0" width="350" height="330" />
            <rect x="830" y="400" rx="0" ry="0" width="350" height="15" />
            <rect x="830" y="425" rx="0" ry="0" width="140" height="15" />
          </ContentLoader>
        </>
      ) : (
        <>
          <rect x="537" y="9" rx="2" ry="2" width="140" height="10" />
          <rect x="14" y="30" rx="2" ry="2" width="667" height="11" />
          <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
          <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
          <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
          <rect x="12" y="283" rx="2" ry="2" width="211" height="211" />
          <rect x="240" y="281" rx="2" ry="2" width="211" height="211" />
          <rect x="468" y="279" rx="2" ry="2" width="211" height="211" />
          <circle cx="286" cy="536" r="12" />
          <circle cx="319" cy="535" r="12" />
          <circle cx="353" cy="535" r="12" />
          <rect x="378" y="524" rx="0" ry="0" width="52" height="24" />
          <rect x="210" y="523" rx="0" ry="0" width="52" height="24" />
          <circle cx="210" cy="535" r="12" />
          <circle cx="428" cy="536" r="12" />
        </>
      )}
    </>
  );
};

export default ContentLoaderSection;
