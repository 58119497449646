import { Link } from "react-router-dom";

import moment from "moment";
import { CardItemStyled } from "../ActiveActionsSlider/CardItemStyled.styled";

const ItemCard = (props: any) => {
  const { item } = props;
  return (
    <CardItemStyled>
      <Link
        to={`/collectionDetails/${item.id}`}
        key={item.id}
        className="action_slider_content Collection_action_slider_content text-decoration-none text-dark"
      >
        <div className="action_slider_img overflow-hidden">
          <img src={item.coverImage} className="img-fluid border-0" />
        </div>
        <div className="action_slider_body_content">
          <div className="bidding-text">Bidding Not Started</div>
          <ul className="d-flex align-items-center pl-0 mb-0 flex-wrap">
            <li className="action_slider_date_text pr-2 border-0">
              {item?.auctionEndDate ? (
                moment(item?.auctionStartDate).format("D") ===
                moment(item?.auctionEndDate).format("D") ? (
                  <span>
                    {moment(item?.auctionStartDate).format("MMMM D, YYYY")}
                  </span>
                ) : (
                  <>
                    <span className="active-end-date">
                      {moment(item?.auctionStartDate).format("MMMM D")}
                    </span>
                    {"-"}
                    <span className="active-start-date">
                      {moment(item?.auctionEndDate).format("MMMM D, YYYY")}
                    </span>
                  </>
                )
              ) : (
                <span>
                  {moment(item?.auctionStartDate).format("MMMM D, YYYY")}
                </span>
              )}
            </li>
            <li className="px-2">
              Bidding Starts{" "}
              {moment(item?.auctionStartTime, "HHmmss").format("h:mm A")}{" "}
              {item?.timeZoneAbbr === "EST" ? "ET" : item?.timeZoneAbbr}
            </li>
            <li className="px-2">
              <Link
                to={item.actionLink}
                className="text-decoration-none text-dark"
              >
                {item.auctionType}
              </Link>
            </li>
          </ul>
          <h3 className="action_slider_heading home-page-title-coll overflow-hidden lineClamp">
            {item.title}
          </h3>
          <div className="active-auctions-brow-btns">
            <Link
              to={`/${
                item.auctionType === "Live Auction2"
                  ? `live-auction-landing/${item.id}`
                  : `collectionDetails/${item.id}`
              }`}
              className="active-auction-btn"
            >
              Preview AUCTION
            </Link>
            {!item?.isRegistredToAuction ? (
              <Link
                to={`/${
                  item.auctionType === "Live Auction1"
                    ? `live-auction-landing/${item.id}`
                    : `collectionDetails/${item.id}`
                }`}
                className="active-auction-btn"
              >
                Register
              </Link>
            ) : (
              <Link
                to={`/${
                  item.auctionType === "Live Auction1"
                    ? `live-auction-landing/${item.id}`
                    : `collectionDetails/${item.id}`
                }`}
                className="active-auction-btn registered-button"
              >
                Registered
              </Link>
            )}
          </div>
        </div>
      </Link>
    </CardItemStyled>
  );
};
export default ItemCard;
