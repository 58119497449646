import React, { useState } from "react";

import { Link } from "react-router-dom";

import noImage from "../../assets/image/noImage.jpg";

import moment from "moment";
import { CardItemStyled } from "./CardItemStyled.styled";
import bidBlack from "../../assets/image/bid-black.png";
import bidWhite from "../../assets/image/bid-white.png";

const CardItem = (props: any) => {
  const { item } = props;

  const [placeBidHover, setPlaceBidHover] = useState(false);

  console.log(placeBidHover, "bidBlack");
  return (
    <CardItemStyled>
      <Link
        to={`/collectionDetails/${item.id}`}
        key={item.id}
        className="action_slider_content Collection_action_slider_content text-decoration-none text-dark"
      >
        <div className="action_slider_img overflow-hidden">
          <img
            src={
              item?.coverImage != "string" ||
              item?.coverImage != null ||
              item?.coverImage != undefined ||
              item?.coverImage != ""
                ? item.coverImage
                : noImage
            }
            className="img-fluid border-0"
          />
        </div>
        <div className="action_slider_body_content">
          <div className="bidding-text">Bidding Open</div>
          <ul className="d-flex align-items-center pl-0 mb-0 flex-wrap">
            <li className="action_slider_date_text pr-2 border-0">
              {item?.auctionEndDate ? (
                moment(item?.auctionStartDate).format("D") ===
                moment(item?.auctionEndDate).format("D") ? (
                  <span>
                    {moment(item?.auctionStartDate).format("MMMM D, YYYY")}
                  </span>
                ) : (
                  <>
                    <span className="active-start-date">
                      {moment(item?.auctionStartDate).format("MMMM D")}
                    </span>
                    {"-"}
                    <span className="active-end-date">
                      {moment(item?.auctionEndDate).format("MMMM D, YYYY")}
                    </span>
                  </>
                )
              ) : (
                <span>
                  {moment(item?.auctionStartDate).format("MMMM D, YYYY")}
                </span>
              )}
            </li>
            <li className="px-2">
              Lots Closing{" "}
              {moment(item?.auctionStartTime, "HHmmss").format("h:mm A")}{" "}
              {item?.timeZoneAbbr === "EST" ? "ET" : item?.timeZoneAbbr}
            </li>
            <li className="px-2">
              <Link
                to={item.actionLink}
                className="text-decoration-none text-dark"
              >
                {item.auctionType}
              </Link>
            </li>
          </ul>
          <h3 className="action_slider_heading home-page-title-coll overflow-hidden">
            {item.title}
          </h3>
          <div className="active-auctions-brow-btns">
            <Link
              to={`/${
                item.auctionType === "Live Auction1"
                  ? `live-auction-landing/${item.id}`
                  : `collectionDetails/${item.id}`
              }`}
              className="active-auction-btn"
            >
              BROWSE AUCTION
            </Link>
            {!item?.isRegistredToAuction ? (
              <Link
                to={`/${
                  item.auctionType === "Live Auction1"
                    ? `live-auction-landing/${item.id}`
                    : `collectionDetails/${item.id}`
                }`}
                className="active-auction-btn"
              >
                Register
              </Link>
            ) : (
              <Link
                to={`/${
                  item.auctionType === "Live Auction1"
                    ? `live-auction-landing/${item.id}`
                    : `collectionDetails/${item.id}`
                }`}
                className="active-auction-btn"
                onMouseEnter={() => {
                  setPlaceBidHover(true);
                }}
                onMouseLeave={() => {
                  setPlaceBidHover(false);
                }}
              >
                <img
                  src={!placeBidHover ? bidBlack : bidWhite}
                  className="bid_icon mr-1 "
                />
                Bid
              </Link>
            )}
          </div>
        </div>
      </Link>
    </CardItemStyled>
  );
};
export default CardItem;
