import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import Home from "./pages/Home";
import TrendingLots from "./pages/TrendingLots";
import collectionDetails from "./pages/collectionDetails";
import lotDetails from "./pages/lotDetails";
import PlaceBid from "./pages/PlaceBid";
import LiveAuctionLanding from "./pages/LiveAuctionLanding";
import Profile from "./pages/Profile";
import MyBids from "./pages/MyBids";
import MyPurchases from "./pages/MyPurchases";
import ItemPurchase from "./pages/ItemPurchase";
import PaymentConfirmation from "./pages/PaymentConfirmation";
import MyFollowedLots from "./pages/MyFollowedLots";
import EmailPreferences from "./pages/EmailPreferences";
import Support from "./pages/Support";
import Faq from "./pages/Faq";
import AddAddress from "./pages/AddAddress";
import SignUp from "./pages/SignUp";
import AuctionCalendar from "./pages/AuctionCalendar";
// import LiveVideo from "./pages/LiveVideo";
import viewArticle from "./pages/viewArticle";
import PrintPage from "./pages/PrintPage";
import AboutUs from "./pages/AboutUs";
import Contactus from "./pages/Contactus";
import BuyingGuides from "./pages/BuyingGuides";
import SellingGuides from "./pages/SellingGuides";
import GetanEstimate from "./pages/GetanEstimate";
import PaymentGuides from "./pages/PaymentGuides";
import ShippingGuides from "./pages/ShippingGuides";
import StorageGuides from "./pages/StorageGuides";
import HowBidWorks from "./pages/HowBidWork";
import Article1 from "./pages/StaticArticles/Article1";
import Article2 from "./pages/StaticArticles/Article2";
import Article3 from "./pages/StaticArticles/Article3";
import PrivacyPolicy from "./pages/StaticPages/PrivacyPolicy";
import TermsAndConditions from "./pages/StaticPages/TermsAndConditions";
import CreateNewPassword from "./pages/CreateNewPassword/CreateNewPassword";
import AccountActivation from "./pages/AccountActivation/AccountActivation";
import FreshDeskCreateTicket from "./pages/FreshDeskCreateTicket/FreshDeskCreateTicket";
import ComingSoonPage1 from "./pages/ComingSoonPage1/ComingSoonPage1";
import ComingSoonPage2 from "./pages/ComingSoonPage2/ComingSoonPage2";
import CollectionLotStatus from "./pages/CollectionLotStatus/CollectionLotStatus";

import ACH from "./pages/ACH/ACH";
import GateCode from "./pages/GateCode/GateCode";
import { getGateCode } from "./Scenes/common";

const MainRoutes: React.FC = () => {
  const gateCodeV = getGateCode();
  return (
    <Switch>
      {!gateCodeV ? (
        <>
          <Route exact path="/" component={withRouter(ComingSoonPage2)} />
          <Route exact path="/gatecode" component={withRouter(GateCode)} />
        </>
      ) : (
        <>
          <Route exact path="/" component={withRouter(Home)} />
          <Route exact path="/sign-up" component={withRouter(SignUp)} />
          <Route exact path="/sign-up/:id" component={withRouter(SignUp)} />
          <Route
            exact
            path="/collectionDetails/:id"
            component={withRouter(collectionDetails)}
          />
          <Route
            exact
            path="/lotDetails/:id"
            component={withRouter(lotDetails)}
          />

          <Route exact path="/howBiddingWorks" component={HowBidWorks} />
          <Route exact path="/howBiddingWorks/:id" component={HowBidWorks} />
          <Route exact path="/placeBid/:id" component={withRouter(PlaceBid)} />
          <Route
            exact
            path="/live-auction-landing/:id"
            component={withRouter(LiveAuctionLanding)}
          />
          <Route exact path="/profile" component={withRouter(Profile)} />
          <Route exact path="/profile/:id" component={withRouter(Profile)} />
          <Route
            exact
            path="/collectionDataPrint/:id"
            component={withRouter(PrintPage)}
          />
          <Route
            exact
            path="/collectionDataDownload/:id"
            component={withRouter(PrintPage)}
          />
          <Route
            exact
            path="/TrendingLots"
            component={withRouter(TrendingLots)}
          />
          <Route exact path="/myBids" component={withRouter(MyBids)} />
          <Route
            exact
            path="/myPurchases"
            component={withRouter(MyPurchases)}
          />
          <Route
            exact
            path="/itemPurchase/:id"
            component={withRouter(ItemPurchase)}
          />
          <Route
            exact
            path="/PaymentConfirmation/:id"
            component={withRouter(PaymentConfirmation)}
          />
          <Route
            exact
            path="/myFollowedLots"
            component={withRouter(MyFollowedLots)}
          />
          <Route
            exact
            path="/emailpreferences"
            component={withRouter(EmailPreferences)}
          />

          <Route exact path="/support" component={withRouter(Support)} />
          <Route exact path="/faq" component={withRouter(Faq)} />
          <Route exact path="/ach" component={withRouter(ACH)} />
          <Route exact path="/add-address" component={withRouter(AddAddress)} />
          <Route
            exact
            path="/edit-address/:id"
            component={withRouter(AddAddress)}
          />
          <Route
            exact
            path="/auction-calendar"
            component={withRouter(AuctionCalendar)}
          />
          <Route
            exact
            path="/auction-results"
            component={withRouter(AuctionCalendar)}
          />
          <Route
            exact
            path="/viewArticle/:id"
            component={withRouter(viewArticle)}
          />

          <Route
            exact
            path="/collectionLotStatus/:id"
            component={CollectionLotStatus}
          />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/contactus" component={Contactus} />
          <Route exact path="/buyingGuides" component={BuyingGuides} />
          <Route exact path="/sellingGuides" component={SellingGuides} />
          <Route exact path="/getanEstimate" component={GetanEstimate} />
          <Route exact path="/paymentGuides" component={PaymentGuides} />
          <Route exact path="/shippingGuides" component={ShippingGuides} />
          <Route exact path="/storageGuides" component={StorageGuides} />
          <Route exact path="/article1" component={Article1} />
          <Route exact path="/article2" component={Article2} />
          <Route exact path="/article3" component={Article3} />
          <Route exact path="/introducingFineArtHipHop" component={Article1} />
          <Route exact path="/theBirthOfHipHop" component={Article2} />
          <Route exact path="/frozenMoments" component={Article3} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route
            exact
            path="/termsandconditions"
            component={TermsAndConditions}
          />
          <Route
            exact
            path="/createnewpassword"
            component={CreateNewPassword}
          />
          <Route
            exact
            path="/accountActivation"
            component={AccountActivation}
          />
          <Route
            exact
            path="/freshdeskCreateTicket"
            component={FreshDeskCreateTicket}
          />
        </>
      )}
      <Route exact path="/*" component={withRouter(ComingSoonPage2)} />
      {/* <Route exact path="/liveVideo" component={LiveVideo} /> */}
    </Switch>
  );
};

export default MainRoutes;
