import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

import AccountSlideBar from "../../components/AccountSlideBar";
import PurchasesListcontent from "../../components/PurchasesListcontent";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import moment from "moment-timezone";

import "./css/Purchases.css";
import { getcurrenciesCall } from "./../../library/Requests";
import {
  getcustomerpurchasedbidsCall,
  getPaymentDetailsCall,
} from "./../../library/Requests/MyAccount";
import { LOGINID } from "./../../Scenes/common";
import Loader from "./../..//components/Common/Loader";
import { triggerMixpanelEvent } from "./../../Scenes/common";
class MyPurchases extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      MyPurchasesLots: [],
      isLoading: false,
    };
  }
  componentDidMount() {
    console.log("props", this.props);
    if (LOGINID) {
      this.getcurrenciesDataCall();
      this.getDataonLoad();
      triggerMixpanelEvent("purchases_page");
      document.title = "FAHH-myPurchases";
    } else {
      this.props.history.push(`/?returnUrl=${this.props?.location?.pathname}`);
    }
  }

  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
  };
  getDataonLoad = async () => {
    this.setState({ isLoading: true });
    const resp = await getcustomerpurchasedbidsCall(LOGINID);
    this.setState({ MyPurchasesLots: resp?.data, isLoading: false });
    // const resp1 = await getPaymentDetailsCall(resp?.data[0]?.orderId);
  };
  makePaymentOrder = (id: any) => {
    this.props.history.push(`/itemPurchase/${id}`);
  };
  render() {
    const { currenciesList, MyPurchasesLots, isLoading } = this.state;
    return (
      <>
        <div className="purchases_page_content account_all_page">
          {isLoading && <Loader />}
          <Container>
            <Row className="purchases_page_left_right_content">
              <AccountSlideBar />
              <Col lg={9}>
                <div className="purchases_page_right_content account_all_right_content  d-lg-block">
                  <div className="purchases_page_active_bid account_all_right_section  purchase_right_section pb-0">
                    <div className="account_all_right_content_heading header_text_page">
                      <h4 className="mb-0">Purchases</h4>
                    </div>
                    {(MyPurchasesLots || []).map((data: any, index: any) => {
                      return (
                        <>
                          {data?.items?.length > 0 && (
                            <div className="PurchaseDiv">
                              <div className="headingtext headingtext-header">
                                <p className="OrderIdText">
                                  Order ID: #{data?.orderNo}
                                </p>
                                <p className="DateText">
                                  {moment(data?.ordredDate).format(
                                    "MMMM D, YYYY"
                                  )}{" "}
                                  {/* {`XXXX${data?.orderId}`} */}
                                </p>
                                <p className="PaymentStatus">
                                  <span className="StatusText">Status:</span>{" "}
                                  {data?.orderStatus}
                                </p>
                              </div>

                              {data?.items?.map((obJD: any, index1: any) => {
                                return (
                                  <div className="ImageStatusDiv">
                                    <div className="image-status-div-left">
                                      <Card className="ImageCardDiv">
                                        <img
                                          src={obJD?.imageUrl}
                                          className="paymentItemImage"
                                        />
                                      </Card>
                                      <div className="DetailsOfImages">
                                        <p className="PurchaseNameText">
                                          {obJD?.collectionItemTitle}
                                        </p>
                                        <p className="LotSoldUsdText">
                                          <span className="LotSoldText">
                                            Lot Sold:{" "}
                                          </span>
                                          {obJD?.currency}{" "}
                                          {obJD?.amount?.toLocaleString()}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="PaymentStatusDiv">
                                      {/* index === 0 && */}
                                      {data?.orderStatus ==
                                        "Waiting for Payment" &&
                                        index1 === 0 && (
                                          <Button
                                            onClick={() => {
                                              this.makePaymentOrder(
                                                data?.orderId
                                              );
                                            }}
                                            className="MakePaymentBtn"
                                          >
                                            MAKE PAYMENT
                                          </Button>
                                        )}
                                      {/* <Button className="DownloadInvoiceBtn">
                                Download Invoice
                              </Button> */}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          )}
                        </>
                      );
                    })}
                    {MyPurchasesLots?.length === 0 && !isLoading && (
                      <div className="noRecordsData">
                        You have not made any purchases in the last 12 months.
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default MyPurchases;
