import React, { useEffect } from "react";
import { useCountdown } from "./hooks/useCountDown";
import ShowCounter, { InspectionCounter } from "./ShowCounter";

const ExpiredNotice = (props: any) => {
  useEffect(() => {
    props.updateIsExpiredButton();
  }, []);

  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      {/* <p>Please select a future date and time.</p> */}
    </div>
  );
};
const CountdownTimer = ({
  targetDate,
  isMarketPlace,
  isLeastTimer,
  card,
  updateIsExpiredButton,
  showProgressBarsec,
  showExtraText,
}: any) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return (
      <ExpiredNotice
        updateIsExpiredButton={updateIsExpiredButton}
        showExtraText={showExtraText}
      />
    );
  } else {
    return (
      <>
        <ShowCounter
          days={days}
          hours={hours}
          minutes={minutes}
          seconds={seconds}
          showProgressBarsec={showProgressBarsec}
          isMarketPlace={isMarketPlace}
          isLeastTimer={isLeastTimer}
          card={card}
          showExtraText={showExtraText}
        />
      </>
    );
  }
};
export const InspectionTimer = ({ targetDate }: any) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <span>0 h 0 m</span>;
  } else {
    return (
      <InspectionCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
