import styled from "styled-components";
import { ReactComponent as NextIcon } from "../../assets/HomePage/hero-image-right-arrow.svg";
import { ReactComponent as NextIconWhite } from "../../assets/HomePage/hero-image-right-white-icon.svg";
import { useState } from "react";

const NextArrow = (props: any) => {
  const { onClick } = props;
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <NextArrowStyles>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="next-arrow-sec next-arrow-sec111"
        onClick={onClick}
      >
        {!hovered ? (
          <div className="next-icon">
            <NextIcon onClick={() => onClick()} />
          </div>
        ) : (
          <>
            <div className="next-icon-white">
              <NextIconWhite
                onClick={() => onClick()}
                className="next-icon-white"
              />
            </div>
            <div className="next-button">Next</div>
          </>
        )}
      </div>
    </NextArrowStyles>
  );
};

export default NextArrow;

export const NextArrowStyles = styled.div`
  .next-arrow-sec {
    position: absolute;
    top: 46%;
    right: 16px;
    border-radius: 24px;
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
  }
  .next-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 24px;
    background: #fff;
    z-index: 2;
    svg {
      width: 26px;
      height: 26px;
    }
  }
  .next-icon-white {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 24px;
    background: #000;
    z-index: 2;
    svg {
      width: 26px;
      height: 26px;
    }
  }
  .next-button {
    background: #fff;
    width: 114px;
    border-top-left-radius: 24px;
    border-bottom-left-radius: 24px;
    position: absolute;
    height: 40px;
    right: 16px;
    z-index: 1;
    padding: 10px 0 10px 30px;
    color: #000;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
  }

  @media (pointer: fine) {
    .next-button {
      //   opacity: 1;
      //   max-width: 100px;
      transition: 0.4s ease-in-out, 0.4s ease-in-out, 0.4s ease-in-out 0.1s,
        0.4s ease-in-out;
    }

    // .arrow_withLabel__jKcbY.arrow_arrowLeft___cDgb:hover .arrow_label___pa1_ {
    //   padding-left: var(--size);
    // }

    // .arrow_withLabel__jKcbY.arrow_arrowRight__5ZsHj:hover .arrow_label___pa1_ {
    //   padding-right: var(--size);
    // }
  }
`;
