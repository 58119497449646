import { get, post, put, del } from "./helpers";
import { API_BASE, SERVER_IP } from "../constants";
import { LOGINID } from "./../../Scenes/common";

import _ from "lodash";
import Axios from "axios";
import axios from "axios";
// import { AES, enc } from "crypto-js";

// import { ENCRYPTED_KEY } from "../../library/constants.js";

// const axios = require('axios').default;

export const getTrandingLots = async (body) => {
  body.status = [1];
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/getcollectionitembycollectionid`,
    body
  );
};
export const getAllAuctions = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getallauctions`,
    body
  );
};
export const getPastAuctions = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getpastauctions`,
    body
  );
};
export const getallupcommingauctionsCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/AuctionManagement/getallactiveandupcommingauctions`,
    body
  );
};
export const placeLiveBidCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/BroadCast/placeLiveBid`, body);
};
export const gethighestbid = async (id) => {
  return await get(`${SERVER_IP}${API_BASE}/Bid/gethighestbid?itemId=${id}`);
};
export const getbidincrementCall = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Settings/getbidincrement`);
};
export const getlivecollectionitemsCall = async (id) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/getlivecollectionitems`,
    id
  );
};
export const getlivebidsCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/BroadCast/getlivebids?ItemId=${id}`
  );
};
export const placeAutoBidCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/BroadCast/autobid`, body);
};
export const getCollectionItemsCall = async (body) => {
  // const CancelToken = axios.CancelToken;
  // const source = CancelToken.source();

  // // source.cancel('Operation canceled by the user.');

  // const response = await axios.post(url,body, {
  //     cancelToken: source.token
  //   })

  // //   const resp = axios.post(url,id, {
  // //     cancelToken: source.token
  // //   })
  // //   // cancel the request (the message parameter is optional)
  // //   // source.cancel('Operation canceled by the user.');
  // //   return resp;
  // // // const response = await Axios.post(url, body);
  // // //     return response.data;

  //     // const response = await Axios.post(url, body);
  //     return response?.data;
  body.status = [1, 6, 7];
  // body.status=1;
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/getcollectionitembycollectionid`,
    body
  );
};

export const getcollectionitembyidCall = async (id, LOGID) => {
  const loginI = LOGID ? `&CustomerId=${LOGID}` : "";
  return await get(
    `${SERVER_IP}${API_BASE}/Collection/getcollectionitembyid?id=${id}${loginI}`
  );
};
export const getItemidsbyCollectionIdCall = async (id) => {
  const body = {
    CollectionId: id,
    status: [1, 6, 7],
  };
  return await post(`${SERVER_IP}${API_BASE}/Collection/getItemids`, body);
};

export const loginActionCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Customer/login`, body);
};
export const getbusinessuserbyidCall = async (id) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getbusinessuserbyid?Id=${id}`
  );
};
export const editbusinessuserCall = async (body) => {
  return await put(`${SERVER_IP}${API_BASE}/Customer/editbusinessuser`, body);
};

export const createNewUser = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Customer/registerbusinessuser`,
    body
  );
};

export const createUserAddress = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Customer/addcustomeraddress`,
    body
  );
};

export const customerkycCall = async (body) => {
  return await put(`${SERVER_IP}${API_BASE}/Customer/customerkyc`, body);
};

export const createNewHSM = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/SiteManagement/createsitemanager`,
    body
  );
};

export const createNewCollection = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/createcollection`,
    body
  );
};

export const getCollectionByIdCall = async (
  id,
  userI = null,
  exteaText = ""
) => {
  const userI1 = userI ? userI : LOGINID;
  const loginI = userI1 ? `&CustomerId=${userI1}` : "";
  return await get(
    `${SERVER_IP}${API_BASE}/Collection/getcollectionbyid?ID=${id}${loginI}&exteaText=${exteaText}`
  );
};

export const getAllBusinessUsers = async () => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getallbusinessusers?PageSize=200&Page=1`
  );
};
export const getAllUserRoles = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Customer/getroles`);
};
export const getTimeZones = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Collection/gettimezoneinfo`);
};
export const getAllUsers = async () => {
  return await get(
    `${SERVER_IP}${API_BASE}/Customer/getallusers?PageSize=200&Page=1`
  );
};
export const getDraftedCollections = async (search) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Collection/getallcollection?status=2&PageSize=200&Page=1&SearchText=${search}`
  );
};
export const getPublishedCollections = async (search) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Collection/getallcollection?status=1&PageSize=200&Page=1&SearchText=${search}`
  );
};

export const getAllCategories = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Collection/getitemcategories`);
};

export const followbidCall = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Bid/followbid`, body);
};
export const unfollowbidCall = async (body) => {
  return await put(`${SERVER_IP}${API_BASE}/Bid/unfollowbid`, body);
};

export const saveLotInfoDetails = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/createcollectionitem`,
    body
  );
};

export const getsasToken = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Settings/getsastoken`);
};

export const imageasyncupload = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Settings/asyncupload`, body);
};
export const saveImagesforItem = async (body) => {
  return await post(`${SERVER_IP}${API_BASE}/Collection/createitemimage`, body);
};

export const newGetcollectionguidCall = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Collection/getcollectionguid`);
};

export const getUpdatedLoginDetails = async () => {
  let userInfo = localStorage.getItem("customerInfoDetails");
  if (userInfo != undefined && userInfo != null && userInfo != "") {
    const userIn = JSON.parse(userInfo);
    if (userIn?.id) {
      const resp = await get(
        `${SERVER_IP}${API_BASE}/Customer/getbusinessuserbyid?Id=${userIn?.id}`
      );
      console.log("respresp", resp);
      if (resp.customer?.id) {
        localStorage.removeItem("customerInfoDetails");
        localStorage.setItem(
          "customerInfoDetails",
          JSON.stringify(resp?.customer)
        );
      }
    }
  }
};
export const createitemprovenanceCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/createitemprovenance`,
    body
  );
};

export const createitemlogisticinfoCall = async (body) => {
  return await post(
    `${SERVER_IP}${API_BASE}/Collection/createitemlogisticinfo`,
    body
  );
};

export const getcountriesCall = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Settings/getcountries`);
};
export const getcurrenciesCall = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Settings/getcurrencies`);
};
export const getstatesCall = async (CountryID) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Settings/getstates?CountryID=${CountryID}`
  );
};
export const getcitysCall = async () => {
  return await get(`${SERVER_IP}${API_BASE}/Settings/getcountries`);
};

export const getAllActiveBids = async (body) => {
  return await get(
    `${SERVER_IP}${API_BASE}/Bid/getActiveLotsByCollection?${new URLSearchParams(
      body
    ).toString()}`
  );
};
// return APIService.get(`User/GetAllBusinessUsers?${new URLSearchParams(body).toString()}`);
//
