import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Data } from "../../data/auction";

import ActionCalendarTabContent from "../../components/ActionCalendarTabContent";
import moment from "moment";
import "./css/ActionCalendarTabMenu.css";
import { UPDATE_CALENDERDATA } from "./../../library/Requests/eventConstants";

const ActionCalendarTabMenu = (props: any) => {
  const [details, setDetails] = useState([]);
  const [tabMonths, setTabMonths] = useState([]);
  const [show, setShow] = useState([]);

  let months: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  useEffect(() => {
    const mth: any = [];
    (props.AllCollections || []).map((d: any) => {
      mth[moment(d?.auctionStartDate).format("M")] = moment(
        d?.auctionStartDate
      ).format("MMMM");
    });
    if (props.isResultsPage) {
      setTabMonths(Object.values(mth));
      setDetails(props.AllCollections);
    } else {
      let array: any = ["Active", "Upcoming"];
      setTabMonths(array);
      setDetails(props.AllCollections);
    }
  }, [props.AllCollections]);

  const getList = (tabTitle: string) => {
    const { tabKey, handleTab, AllCollections, isResultsPage } = props;
    return AllCollections?.filter((doc: any) => {
      if (isResultsPage) {
        let d: any = new Date(doc.auctionStartDate);
        return moment(doc.auctionStartDate).format("MMMM") === tabTitle;
      } else {
        const expiresOnDate = new Date(doc.auctionStartDate);
        const currentDate = new Date();
        if (tabTitle === "Active") {
          if (currentDate > expiresOnDate) {
            return doc;
          }
        } else if (tabTitle === "Upcoming") {
          if (currentDate < expiresOnDate) {
            return doc;
          }
        } else {
          return doc;
        }
      }
    });
  };
  const thisMonth: any = moment().format("MMMM");

  return (
    <>
      <div className="action_calendar_right_tab custom_action_calendar_tabs">
        {!props.isResultsPage ? (
          <Tabs
            defaultActiveKey="all"
            activeKey={props.tabKey}
            onSelect={props.handleTab}
            id="uncontrolled-tab-example"
          >
            <Tab eventKey={"all"} title={"All"}>
              <div className="action_tab_content">
                {props?.isLoading ? (
                  <div className="spinner_Loading_icon">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <ActionCalendarTabContent
                    isLoadingLoadMore={props.isLoadingLoadMore}
                    isLoading={props?.isLoading}
                    viewMore={props.clickViewMore}
                    allCollFull={props.AllCollectionsFull}
                    selectedTab={"All"}
                    listDetailsNew={props.AllCollections}
                    listDetails={details}
                    isResultsPage={props.isResultsPage}
                    key={"all"}
                  />
                )}
              </div>
            </Tab>
            {tabMonths.map((tabTitle: any) => {
              return (
                <Tab
                  eventKey={tabTitle}
                  key={tabTitle}
                  title={thisMonth === tabTitle ? "This Month" : tabTitle}
                >
                  <div className="action_tab_content">
                    <ActionCalendarTabContent
                      isLoadingLoadMore={props.isLoadingLoadMore}
                      isResultsPage={props.isResultsPage}
                      isLoading={props?.isLoading}
                      viewMore={props.clickViewMore}
                      allCollFull={props.AllCollectionsFull}
                      selectedTab={
                        thisMonth === tabTitle ? "This Month" : tabTitle
                      }
                      listDetailsNew={props.AllCollections}
                      listDetails={getList(tabTitle)}
                      key={tabTitle}
                    />
                  </div>
                </Tab>
              );
            })}
          </Tabs>
        ) : (
          <div className="auction-calender-resulus-sec">
          <ActionCalendarTabContent
            isLoadingLoadMore={props.isLoadingLoadMore}
            isResultsPage={props.isResultsPage}
            isLoading={props?.isLoading}
            viewMore={props.clickViewMore}
            allCollFull={props.AllCollectionsFull}
            selectedMonth={props?.selectedMonths}
            selectedYear={props.selectedYears}
            listDetailsNew={props.AllCollections}
            listDetails={props.AllCollections}
            // key={tabTitle}
          />
          </div>
        )}
      </div>
    </>
  );
};

export default ActionCalendarTabMenu;
