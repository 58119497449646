import React, { useEffect } from "react";
import { useCountdown } from "./hooks/useCountDown";
import ShowCounter, { InspectionCounter } from "./ShowCounter";
import moment from "moment";

const ExpiredNotice = (props: any) => {
  useEffect(() => {
    props.updateIsExpiredButton();
  }, []);

  return (
    <div className="expired-notice">
      <span>Expired!!!</span>
      {/* <p>Please select a future date and time.</p> */}
    </div>
  );
};
const CountdownTimer = ({
  targetDate,
  isMarketPlace,
  isLeastTimer,
  card,
  showProgressBarsec,
  updateIsExpiredButton,
  auctionEnd_UTC,
}: any) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <ExpiredNotice updateIsExpiredButton={updateIsExpiredButton} />;
  } else {
    return (
      <>
        {auctionEnd_UTC && days <= 6 ? (
          <>
            {moment(auctionEnd_UTC).format("M/DD/YY h:mm A")}
            {" ET "}
          </>
        ) : (
          <>
            <ShowCounter
              days={days}
              hours={hours}
              showProgressBarsec={showProgressBarsec}
              minutes={minutes}
              seconds={seconds}
              isMarketPlace={isMarketPlace}
              isLeastTimer={isLeastTimer}
              card={card}
            />
          </>
        )}
      </>
    );
  }
};
export const InspectionTimer = ({ targetDate }: any) => {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (days + hours + minutes + seconds <= 0) {
    return <span>0 h 0 m</span>;
  } else {
    return (
      <InspectionCounter
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  }
};

export default CountdownTimer;
