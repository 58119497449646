import React from "react";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import TopBannerSlider from "../../components/TopBannerSlider";
import TheWordSlider from "../../components/TheWordSlider";
import LotsSlider from "../../components/LotsSlider";
import ActionPage from "../ActionsPage";
import ComingSoonPage1 from "./../ComingSoonPage1/ComingSoonPage1";
import ComingSoonPage2 from "./../ComingSoonPage2/ComingSoonPage2";
import { STATIC_GATE_CODE } from "./../../library/constants";
import { getGateCode } from "./../../Scenes/common";
import Banner from "./../../components/Banner";
import ContentLoaderSection from "./../../components/ContentLoaderSection/ContentLoaderSection";
import { getAllSiteManagersCall } from "./../../library/Requests/SiteManager";
import { getAllCategories } from "./../../library/Requests";

class Home extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      allData: [],
      allCategories: [],
      isLoading: false,
      hover: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllSiteManagers();
  }
  getAllSiteManagers = async () => {
    this.setState({ isLoading: true });
    const resp = await getAllSiteManagersCall("1"); //.splice(1)
    this.setState({ allData: resp?.data });
    const cate = await getAllCategories();
    this.setState({ allCategories: cate?.data, isLoading: false });
  };
  render() {
    console.log("getGateCode", getGateCode());
    console.log("STATIC_GATE_CODE", STATIC_GATE_CODE);
    const { allData, allCategories, isLoading, hover } = this.state;
    console.log("allData", allData);
    console.log("allCategories", allCategories);

    return (
      <>
        {STATIC_GATE_CODE === getGateCode() ? (
          <>
            {isLoading ? (
              <>
                <div className="TopSectionLoading">
                  <ContentLoaderSection section="topbanner" />
                </div>
                <div className="HomePageCollectionsLoading">
                  <ContentLoaderSection section="topbanner1" />
                </div>
              </>
            ) : (
              <Banner bannerList={allData} />
            )}
            {/* <TopBannerSlider /> */}
            <div className="custom_action_slider_section">
              <Container>
                <ActionPage />
              </Container>
            </div>
            <div className="word_slider_section">
              <Container>
                <div className="custom_slider_heading_filter d-flex align-items-center">
                  <h3 className="mb-0 mr-auto position-relative pl-3">
                    the Word
                  </h3>
                </div>
              </Container>
              <div className="word_slider_bg_section">
                <Container>
                  <TheWordSlider />
                </Container>
              </div>
            </div>
            <div className="custom_lots_slider_section">
              {/* <Container> */}

              <LotsSlider />
              {/* </Container> */}
            </div>
          </>
        ) : (
          <>
            <ComingSoonPage2 />{" "}
          </>
        )}
      </>
    );
  }
}
export default Home;
