import React, { useState, Component, useCallback } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Button, Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Slider from "react-rangeslider";
import {
  Slider as SliderR,
  Handles,
  Tracks,
  Rail,
  Ticks,
} from "react-compound-slider";
import { Handle, Track, Tick, SliderRail } from "./components";
// import RangeSlider from "reactrangeslider";
import { UPDATE_FILTERS } from "../../library/Requests/eventConstants";

import BottomArrow from "../../assets/image/Bottom_arrow.svg";
import FilterBtn from "../../assets/image/filter.png";
import CloseIcon from "../../assets/image/close.svg";
import { LOGINID } from "./../../Scenes/common";

import "react-rangeslider/lib/index.css";

import "./css/BrowseFilterList.css";
import { isMobile } from "react-device-detect";

// interface Props {
//   collectionItemsFull:any;
//   changesData?:any;
// }

class BrowseFilterList extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      artistsList: [],
      categoriesList: [],
      collectionData: {},
      filterMenuOpen: false,
      bidMin: 1,
      bidMax: 900000,
      domain: [10, 20],
      updateValue: [10, 20],
      extimateMin: 1,
      extimateMax: 900000,
      domainEstimate: [10, 20],
      updateValueEstimate: [10, 20],
      openKey: "",
      selectedArtists: [],
      selectedCategories: [],
      firstTimeUpdate: false,
      firstTimeUpdateBid: false,
      avoidByDefauldFirstCall: false,
      metReservePriceValue: "",
      reserveMeetCount: "",
      reserveNotMeetCount: "",
    };
  }

  componentDidMount() {
    // this.updateStateValues()
    const self = this;
    window.document.addEventListener(
      UPDATE_FILTERS,
      function (event) {
        self.updateStateValues();
      },
      false
    );
  }

  updateStateValues = () => {
    const { bidMin, bidMax, extimateMin, extimateMax } = this.state;
    const maxEstimat = this.props?.collectionItemsFull?.maxEstimatePrice;
    const maxCurrentBidPrice =
      this.props?.collectionItemsFull?.maxCurrentBidPrice;
    const bidinfo = [bidMin, maxCurrentBidPrice];
    const estimateinfo = [extimateMin, maxEstimat];
    const artistsList = this.props?.collectionItemsFull?.artists;
    const categoriesList = this.props?.collectionItemsFull?.categories;
    console.log("4444444");
    this.setState({
      avoidByDefauldFirstCall: true,
      domain: bidinfo,
      updateValue: bidinfo,
      domainEstimate: estimateinfo,
      updateValueEstimate: estimateinfo,
      artistsList: artistsList,
      categoriesList: categoriesList,
      collectionData: this.props?.collectionData,
      reserveMeetCount: this.props?.collectionItemsFull?.reserveMetCount,
      reserveNotMeetCount: this.props?.collectionItemsFull?.reserveNotMetCount,
    });
  };

  onUpdate = (value: any) => {
    const { updateValue } = this.state;
    if (updateValue[0] != value[0] || updateValue[1] != value[1]) {
      this.setState({ updateValue: value });
      // this.getRefreshData();
    }
  };
  handleBidUpdate = async (value0: any, value1: any) => {
    const { updateValue } = this.state;
    var prevUpdateV = [updateValue[0], updateValue[1]];
    const _this = this;
    const re = /^[0-9\b]+$/;
    if (value0 === "" || re.test(value0)) {
      prevUpdateV[0] = value0 ? parseInt(value0) : value0;
    }
    if (value1 === "" || re.test(value1)) {
      prevUpdateV[1] = value1 ? parseInt(value1) : value1;
    }
    if (updateValue[0] != value0 || updateValue[1] != value1) {
      await this.setState({ updateValue: prevUpdateV });
      setTimeout(() => {
        _this.getRefreshData();
      }, 200);
    }
  };
  handleEstimateUpdate = async (value0: any, value1: any) => {
    console.log("value0", value0);
    console.log("value1", value1);
    const { updateValueEstimate } = this.state;
    var prevUpdateV = [updateValueEstimate[0], updateValueEstimate[1]];
    const _this = this;
    const re = /^[0-9\b]+$/;
    if (value0 === "" || re.test(value0)) {
      prevUpdateV[0] = value0 ? parseInt(value0) : value0;
    }
    if (value1 === "" || re.test(value1)) {
      prevUpdateV[1] = value1 ? parseInt(value1) : value1;
    }
    if (updateValueEstimate[0] != value0 || updateValueEstimate[1] != value1) {
      await this.setState({ updateValueEstimate: prevUpdateV });
      setTimeout(() => {
        _this.getRefreshData();
      }, 200);
    }
  };
  onUpdateDataCall = (value: any) => {
    const { updateValue, firstTimeUpdateBid, avoidByDefauldFirstCall } =
      this.state;
    if (avoidByDefauldFirstCall) {
      this.setState({ firstTimeUpdateBid: true });
      if (
        updateValue[0] != value[0] ||
        updateValue[1] != value[1] ||
        firstTimeUpdateBid
      ) {
        this.setState({ updateValue: value });
        this.getRefreshData();
      }
    }
  };
  onUpdateEstimate = (value: any) => {
    const { updateValueEstimate } = this.state;
    if (
      updateValueEstimate[0] != value[0] ||
      updateValueEstimate[1] != value[1]
    ) {
      this.setState({ updateValueEstimate: value });
      // this.getRefreshData();
    }
  };
  onUpdateEstimateDataCall = (value: any) => {
    const { updateValueEstimate, firstTimeUpdate, avoidByDefauldFirstCall } =
      this.state;
    if (avoidByDefauldFirstCall) {
      this.setState({ firstTimeUpdate: true });
      if (
        updateValueEstimate[0] != value[0] ||
        updateValueEstimate[1] != value[1] ||
        firstTimeUpdate
      ) {
        this.setState({ updateValueEstimate: value });
        this.getRefreshData();
      }
    }
  };
  FilterValueSelect = (e: any, artist: any, type: any) => {
    const self = this;
    if (type == "Artists") {
      if (e.target.checked) {
        this.setState({
          selectedArtists: this.state.selectedArtists.concat(artist),
        });
      } else {
        const existData = this.state.selectedArtists;
        const index = existData.findIndex((el: any, ind: any) => el == artist);
        existData.splice(index, 1);
        this.setState({ selectedArtists: existData });
      }
    } else if (type == "Category") {
      if (e.target.checked) {
        this.setState({
          selectedCategories: this.state.selectedCategories.concat(artist),
        });
      } else {
        const existData = this.state.selectedCategories;
        const index = existData.findIndex(
          (el: any, ind: any) => el.id == artist
        );
        existData.splice(index, 1);
        this.setState({ selectedCategories: existData });
      }
    }
    setTimeout(() => {
      self.getRefreshData();
    }, 10);
  };

  clearAllFilters = (e: any, type: any) => {
    const self = this;
    if (type == "ClearAll") {
      window.location.reload();
      if (e.target.click) {
        document
          .querySelectorAll("input[type=checkbox]")
          .forEach((el: any) => (el.checked = false));
        this.setState({ selectedArtists: [], selectedCategories: [] });
      } else {
        this.setState({ ...this.state });
      }
    }
    setTimeout(() => {
      self.getRefreshData();
    }, 10);
  };
  handleOnChangeReservePrice = async (valueF: any) => {
    const Lavrltext = valueF ? "Yes" : "No";
    await this.setState({ metReservePriceValue: Lavrltext });
    this.getRefreshData();
  };
  getRefreshData = () => {
    const {
      selectedArtists,
      selectedCategories,
      updateValue,
      updateValueEstimate,
      metReservePriceValue,
    } = this.state;
    const currentBidFrom = updateValue[0] ? updateValue[0]?.toFixed() : 0;
    const currentBidTo = updateValue[1] ? updateValue[1]?.toFixed() : 0;
    const estimateBidFrom = updateValueEstimate[0]
      ? updateValueEstimate[0]?.toFixed()
      : 0;
    const estimateBidTo = updateValueEstimate[1]
      ? updateValueEstimate[1]?.toFixed()
      : 0;
    let body: any = {};
    body = {
      page: 1,
      pageSize: 20,
      getOnlyTotalCount: false,
      collectionId: this.props.collectionid,
      customerId: LOGINID,
    };
    if (selectedArtists.length > 0) {
      body.artist = selectedArtists;
    }
    if (selectedCategories.length > 0) {
      body.categoryId = selectedCategories;
    }
    if (metReservePriceValue !== "") {
      const paramReserve = metReservePriceValue === "Yes" ? true : false;
      body.metReservePrice = paramReserve;
    }
    if (
      this.props?.collectionItemsFull?.maxCurrentBidPrice != currentBidTo ||
      currentBidFrom != 1
    ) {
      if (currentBidFrom != undefined && currentBidFrom != "NaN")
        body.currentBidFrom = currentBidFrom;

      if (currentBidTo != undefined && currentBidTo != "NaN")
        body.currentBidTo = currentBidTo;
    }

    if (
      this.props?.collectionItemsFull?.maxEstimatePrice != estimateBidTo ||
      estimateBidFrom != 1
    ) {
      if (estimateBidFrom != undefined && estimateBidFrom != "NaN")
        body.estimateBidFrom = estimateBidFrom;

      if (estimateBidTo != undefined && estimateBidTo != "NaN")
        body.estimateBidTo = estimateBidTo;
    }

    this.props.getUpdatedDataCall(body);
  };
  render() {
    const {
      bidMin,
      bidMax,
      filterMenuOpen,
      extimateMin,
      extimateMax,
      artistsList,
      domain,
      updateValue,
      domainEstimate,
      updateValueEstimate,
      openKey,
      categoriesList,
      collectionData,
      reserveMeetCount,
      reserveNotMeetCount,
    } = this.state;
    let currencyName = this.props?.currenciesList.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;

    return (
      <>
        <div className="action_calenda_filter_menu">
          <h3
            className="filter_heading"
            onClick={() => {
              this.setState({ filterMenuOpen: true });
            }}
          >
            <img
              src={FilterBtn}
              alt="filter-btn"
              className="mr-2 d-inline-block d-lg-none"
            />
            {/* Filter{isMobile ? " & Sort" : " by"} */}
            {!isMobile && (
              <span
                className="clearAllStyles"
                onClick={(e) => {
                  this.clearAllFilters(e, "ClearAll");
                }}
              >
                Clear all
              </span>
            )}
          </h3>
          <Accordion
            defaultActiveKey={openKey}
            className={`${filterMenuOpen && "active"}`}
          >
            <div className="responsive_menu_close mb-3 d-flex align-items-center d-lg-none">
              <Link
                to="/auction-calendar"
                className="mr-auto text-decoration-none"
                onClick={(e) => {
                  this.clearAllFilters(e, "ClearAll");
                }}
              >
                Clear All
              </Link>
              <div
                className="close_icon"
                onClick={() => {
                  this.setState({ filterMenuOpen: false });
                }}
              >
                <img src={CloseIcon} alt="close_icon" />
              </div>
            </div>
            <div className="mobile_right_sidebar_content">
              {isMobile ? (
                <Card className="rounded-0 border-0">
                  <Card.Header className="p-0 border-0 bg-transparent">
                    <Accordion.Toggle
                      className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                      as={Button}
                      variant="link"
                      eventKey="5"
                      onClick={() => {
                        this.setState({ openKey: "5" });
                      }}
                    >
                      <span className="mr-auto d-block">Sort by</span>
                      <img src={BottomArrow} alt="dropdown arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body className="p-0">
                      <div className="sortByFormCheckLabelText">
                        <Form.Group>
                          <Form.Check
                            type="radio"
                            id={"1"}
                            name="sortByFilterMobile"
                            value="Sno_asc"
                            label={"Lot Number (Low to High)"}
                            onClick={this.props?.sortColumnChange}
                          />
                          <Form.Check
                            type="radio"
                            id={"2"}
                            name="sortByFilterMobile"
                            value="Sno_desc"
                            label={"Lot Number (High to Low)"}
                            onClick={this.props?.sortColumnChange}
                          />
                          <Form.Check
                            type="radio"
                            id={"3"}
                            name="sortByFilterMobile"
                            value="EstimatedPrice_asc"
                            label={"Estimate (Low to High)"}
                            onClick={this.props?.sortColumnChange}
                          />
                          <Form.Check
                            type="radio"
                            id={"4"}
                            name="sortByFilterMobile"
                            value="EstimatedPrice_desc"
                            label={"Estimate (High to Low)"}
                            onClick={this.props?.sortColumnChange}
                          />
                        </Form.Group>
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              ) : (
                ""
              )}

              <Card className="rounded-0 border-0">
                <Card.Header className="p-0 border-0 bg-transparent">
                  <Accordion.Toggle
                    className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                    as={Button}
                    variant="link"
                    eventKey="1"
                    onClick={() => {
                      this.setState({ openKey: "1" });
                    }}
                  >
                    <span className="mr-auto d-block">Current Bid</span>
                    <img src={BottomArrow} alt="dropdown arrow" />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body className="p-0">
                    {/* <SliderR
                      className="position-relative two_side_slider"
                      domain={domain} // [min, max]
                      values={[bidMin, bidMax]}
                      onUpdate={this.onUpdate}
                      onChange={this.onUpdateDataCall}
                    >
                      <Rail>
                        {({ getRailProps }) => (
                          <SliderRail getRailProps={getRailProps} />
                        )}
                      </Rail>
                      <Handles>
                        {({ handles, getHandleProps }) => (
                          <div className="slider-handles two_side_slider_handle">
                            {handles.map((handle) => (
                              <Handle
                                key={handle.id}
                                handle={handle}
                                domain={domain}
                                getHandleProps={getHandleProps}
                              />
                            ))}
                          </div>
                        )}
                      </Handles>
                      <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                          <div className="slider-tracks two_side_slider_tracks">
                            {tracks.map(({ id, source, target }) => (
                              <Track
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                              />
                            ))}
                          </div>
                        )}
                      </Tracks>
                    </SliderR>
                    <div className="value d-flex align-items-center">
                      <span className="d-inline-block">
                        {currencyName} {updateValue[0]?.toFixed()}
                      </span>
                      <span className="d-inline-block ml-auto">
                        {currencyName} {updateValue[1]?.toFixed()}
                      </span>
                    </div> */}
                    <div className="current-bid-filter-sec">
                      <div className="bid-from-sec-left">
                        <div className="bid-from-sec-left-top">From</div>
                        <div className="bid-from-sec-left-bottom">
                          <div>
                            <input
                              value={updateValue[0]}
                              onChange={(e) => {
                                this.handleBidUpdate(
                                  e.target.value,
                                  updateValue[1]
                                );
                              }}
                            />{" "}
                            <span>{currencyName}</span>
                          </div>
                        </div>
                      </div>
                      <div className="bid-from-sec-center">-</div>
                      <div className="bid-from-sec-right">
                        <div className="bid-from-sec-left-top">To</div>
                        <div className="bid-from-sec-left-bottom">
                          <div>
                            <input
                              value={updateValue[1]}
                              onChange={(e) => {
                                this.handleBidUpdate(
                                  updateValue[0],
                                  e.target.value
                                );
                              }}
                            />{" "}
                            <span>{currencyName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="rounded-0 border-0">
                <Card.Header className="p-0 border-0 bg-transparent">
                  <Accordion.Toggle
                    className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                    as={Button}
                    variant="link"
                    eventKey="2"
                    onClick={() => {
                      this.setState({ openKey: "2" });
                    }}
                  >
                    <span className="mr-auto d-block">Estimate</span>
                    <img src={BottomArrow} alt="dropdown arrow" />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body className="p-0">
                    {/* <SliderR
                      className="position-relative two_side_slider"
                      domain={domainEstimate} 
                      values={[extimateMin, extimateMax]} 
                      onUpdate={this.onUpdateEstimate}
                      onChange={this.onUpdateEstimateDataCall}
                    >
                      <Rail>
                        {({ getRailProps }) => (
                          <SliderRail getRailProps={getRailProps} /> 
                        )}
                      </Rail>
                      <Handles>
                        {({ handles, getHandleProps }) => (
                          <div className="slider-handles two_side_slider_handle">
                            {handles.map((handle) => (
                              <Handle
                                key={handle.id}
                                handle={handle}
                                domain={domainEstimate}
                                getHandleProps={getHandleProps}
                              />
                            ))}
                          </div>
                        )}
                      </Handles>
                      <Tracks left={false} right={false}>
                        {({ tracks, getTrackProps }) => (
                          <div className="slider-tracks two_side_slider_tracks">
                            {tracks.map(({ id, source, target }) => (
                              <Track
                                key={id}
                                source={source}
                                target={target}
                                getTrackProps={getTrackProps}
                              />
                            ))}
                          </div>
                        )}
                      </Tracks>
                    </SliderR>
                    <div className="value d-flex align-items-center">
                      <span className="d-inline-block">
                        {currencyName} {updateValueEstimate[0]?.toFixed()}
                      </span>
                      <span className="d-inline-block ml-auto">
                        {currencyName} {updateValueEstimate[1]?.toFixed()}
                      </span>
                    </div> */}
                    <div className="current-bid-filter-sec">
                      <div className="bid-from-sec-left">
                        <div className="bid-from-sec-left-top">From Low</div>
                        <div className="bid-from-sec-left-bottom">
                          <div>
                            <input
                              value={updateValueEstimate[0]}
                              onChange={(e) => {
                                this.handleEstimateUpdate(
                                  e.target.value,
                                  updateValueEstimate[1]
                                );
                              }}
                            />{" "}
                            <span>{currencyName}</span>
                          </div>
                        </div>
                      </div>
                      <div className="bid-from-sec-center">-</div>
                      <div className="bid-from-sec-right">
                        <div className="bid-from-sec-left-top">To High</div>
                        <div className="bid-from-sec-left-bottom">
                          <div>
                            <input
                              value={updateValueEstimate[1]}
                              onChange={(e) => {
                                this.handleEstimateUpdate(
                                  updateValueEstimate[0],
                                  e.target.value
                                );
                              }}
                            />{" "}
                            <span>{currencyName}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="rounded-0 border-0">
                <Card.Header className="p-0 border-0 bg-transparent">
                  <Accordion.Toggle
                    className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                    as={Button}
                    variant="link"
                    eventKey="3"
                    onClick={() => {
                      this.setState({ openKey: "3" });
                    }}
                  >
                    <span className="mr-auto d-block">Reserve</span>
                    <img src={BottomArrow} alt="dropdown arrow" />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="3">
                  <Card.Body className="p-0">
                    <div className="filter_list">
                      <div>
                        <div className="reserve_met_row_item">
                          <div>
                            <input
                              type="radio"
                              id="ReserveMeet"
                              name="reser_price_met_sec"
                              value="ReserveMeet"
                              onChange={() => {
                                this.handleOnChangeReservePrice(true);
                              }}
                            />
                              <label htmlFor="ReserveMeet">Reserve Met</label>
                          </div>
                          <div>({reserveMeetCount})</div>
                        </div>
                        <div className="reserve_met_row_item">
                          <div>
                            <input
                              type="radio"
                              id="ReserveNotMeet"
                              name="reser_price_met_sec"
                              value="ReserveNotMeet"
                              onChange={() => {
                                this.handleOnChangeReservePrice(false);
                              }}
                            />
                            {` `}
                            <label htmlFor="ReserveNotMeet">
                              Reserve Not Met
                            </label>
                          </div>
                          <div>({reserveNotMeetCount})</div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              <Card className="rounded-0 border-0">
                <Card.Header className="p-0 border-0 bg-transparent">
                  <Accordion.Toggle
                    className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                    as={Button}
                    variant="link"
                    eventKey="4"
                    onClick={() => {
                      this.setState({ openKey: "4" });
                    }}
                  >
                    <span className="mr-auto d-block">Artist/Designer</span>
                    <img src={BottomArrow} alt="dropdown arrow" />
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="4">
                  <Card.Body className="p-0">
                    <div className="filter_list">
                      {(artistsList || []).map((i: any) => {
                        return (
                          <Form.Group controlId={i}>
                            <Form.Check
                              onClick={(e: any) => {
                                this.FilterValueSelect(e, i, "Artists");
                              }}
                              type="checkbox"
                              value={i}
                              label={i}
                            />
                          </Form.Group>
                        );
                      })}
                      {/* <Link
                      to="/"
                      className="show_more_filter_list text-decoration-none"
                    >
                      Show More
                    </Link> */}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* {categoriesList?.length > 1 && (
                <Card className="rounded-0 border-0">
                  <Card.Header className="p-0 border-0 bg-transparent">
                    <Accordion.Toggle
                      className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                      as={Button}
                      variant="link"
                      eventKey="4"
                      onClick={() => {
                        this.setState({ openKey: "4" });
                      }}
                    >
                      <span className="mr-auto d-block">Category</span>
                      <img src={BottomArrow} alt="dropdown arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body className="p-0">
                      <div className="filter_list">
                        {(categoriesList || []).map((i2: any) => {
                          return (
                            <Form.Group controlId={i2.id}>
                              <Form.Check
                                type="checkbox"
                                onClick={(e: any) => {
                                  this.FilterValueSelect(e, i2.id, "Category");
                                }}
                                value={i2.id}
                                label={i2.category}
                              />
                            </Form.Group>
                          );
                        })}
                       
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              )} */}
            </div>
            <div className="submit_filter d-lg-none d-block">
              <span
                onClick={() => {
                  this.setState({ filterMenuOpen: false });
                  // this.getRefreshData();
                }}
                className="filter_btn_submit filter_btn_submit_apply border-0 d-block mx-auto text-center text-white text-decoration-none"
              >
                Apply
              </span>
            </div>
          </Accordion>
        </div>
      </>
    );
  }
}
export default BrowseFilterList;
