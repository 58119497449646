const mainReducer = (state = {}, action) => {
  const type = action.type;
  if (type === "LOGIN_POPUP_UPDATE") {
    return {
      ...state,
      showLopinPopUp: action.payload,
    };
  } else if (type === "AFTER_LOGIN_TRIGGER_PAGE") {
    return {
      ...state,
      afterLoginTriggerPage: action.payload,
    };
  } else if (type === "LOGIN_SUCCESS") {
    return {
      ...state,
      user: action.payload,
    };
  } else if (type === "FOLLOWED_DATA") {
    return {
      ...state,
      isFollowed: action.payload,
    };
  } else if (type === "STORE_LOTID_NUMBER_VALUE") {
    return {
      ...state,
      lotIdNumberValue: action.payload,
    };
  } else {
    return {
      ...state,
    };
  }
};

export default mainReducer;
