import React, { useState } from "react";

import { Link } from "react-router-dom";

import { Container, Row, Col, Card, Button } from "react-bootstrap";
import twitter_new from "./../../assets/twitter_new.svg";

import Accordion from "react-bootstrap/Accordion";
import Logo_new from "./../../assets/main/fahh_logo.png";

import FooterLogo from "./../../assets/image/Footer_Logo.svg";
import Facebook from "./../../assets/image/facebook.svg";
import Twitter from "./../../assets/image/twitter.svg";
import GooglePluse from "./../../assets/instagram-fill.svg";
import Pintrest from "./../../assets/image/pintrest.svg";

import BottomArrow from "./../../assets/image/Bottom_arrow.svg";
import PlayStore from "./../../assets/image/play_store.svg";
import AppStore from "./../../assets/image/app_store.svg";
import { submitforNewsLetterCall } from "./../../library/Requests/MyAccount";
export const Footer: React.FC = () => {
  const [footerCollapseOne, setFooterCollapseOne] = useState(false);
  const [footerCollapseTwo, setFooterCollapseTwo] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleFooterChange = (e: any) => {
    const { name, value } = e.target;
    setEmail(value);
    setEmailError("");
  };
  const handleSubmit = () => {
    if (email) {
      setEmailError("");
      const emailRegex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (emailRegex.test(String(email).toLowerCase())) {
        setEmailError("");
        saveUserInfoCall();
      } else {
        setEmailError("Please enter valid Email.");
      }
    }
  };
  const saveUserInfoCall = async () => {
    setIsLoading(true);
    const resp = await submitforNewsLetterCall({ email });
    setIsLoading(false);
    console.log("resp", resp);
    setSuccessMsg("Thank you for Subscribing.");
  };
  return (
    <>
      <footer className="page_footer">
        <div className="footer_newsletter">
          <Container>
            <Row className="align-items-center">
              <Col lg={7} sm={12}>
                <div className="footer_newletter_text text-lg-left text-center">
                  <h3 className="text-white mb-lg-2 mb-3">
                    Subscribe to our Newsletter
                  </h3>
                  <p className="text-white mb-lg-0 mb-4">
                    Stay informed with news and information about upcoming
                    auctions and the Hip Hop inspired fine art and collectibles
                    world.
                  </p>
                </div>
              </Col>
              <Col lg={5} sm={12}>
                <div className="footer_newletter_email_input d-flex align-items-centner ml-lg-auto mx-auto">
                  {!successMsg ? (
                    <>
                      <input
                        type="email"
                        value={email}
                        onChange={handleFooterChange}
                        placeholder="Type your Email address"
                        className="px-3 border-0 shadow-none"
                      />
                      <Button
                        onClick={handleSubmit}
                        disabled={isLoading}
                        className="border-0 shadow-none d-flex"
                      >
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5 12H19"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M12 5L19 12L12 19"
                            stroke="white"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <span className="mx-auto">JOIN</span>
                      </Button>
                    </>
                  ) : (
                    <>
                      <p className="footer_success_text">{successMsg}</p>
                    </>
                  )}
                </div>
                <div className="footer_newletter_email_input d-flex align-items-centner ml-lg-auto mx-auto">
                  {emailError && (
                    <p className="footer_error_msg">{emailError}</p>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* <div className="footer_newsletter_mobile_app">
          <Container>
            <Row className="align-items-center">
              <Col lg={7} sm={12}>
                <div className="footer_newsletter_mobile_text text-lg-left text-center mb-lg-0 mb-3">
                  <h3>EXPERIENCE THE FINEART.HIPHOP APP</h3>
                </div>
              </Col>
              <Col lg={5} sm={12}>
                <div className="footer_newsletter_app_img d-flex justify-content-lg-end justify-content-center">
                  <Link to="/" className="play_store_img">
                    <img src={PlayStore} alt="PlayStore" />
                  </Link>
                  <Link to="/" className="app_store_img">
                    <img src={AppStore} alt="PlayStore" />
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div> */}
        <Container>
          <div className="footer_link_content">
            <Row>
              <Col lg={4} className="footer_logo">
                <a href="/">
                  <img src={Logo_new} alt="footer_logo" />
                </a>
                <p>© 2024 All rights reserved by FineArt.HipHop</p>
              </Col>
              <Col lg={2}>
                <div className="footer_links_list desktop_footer_link_list">
                  <h3 className="footer_links_heading">Support</h3>
                  <ul className="footer_link_nav p-0 mb-0">
                    <li className="footer_link_item">
                      <a
                        href="/contactus"
                        className="footer_link text-decoration-none"
                      >
                        Contact Us
                      </a>
                    </li>

                    <li className="footer_link_item">
                      <a
                        href="/shippingGuides"
                        className="footer_link text-decoration-none"
                      >
                        Shipping
                      </a>
                    </li>
                    <li className="footer_link_item">
                      <a
                        href="/paymentGuides"
                        className="footer_link text-decoration-none"
                      >
                        Payment
                      </a>
                    </li>

                    {/* <li className="footer_link_item">
                      <a
                        href="/faq"
                        className="footer_link text-decoration-none"
                      >
                        FAQ
                      </a>
                    </li> */}
                  </ul>
                </div>
                <Accordion defaultActiveKey="0" className="d-none">
                  <Card className="footer_links_list border-0">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="1"
                      className={`footer_links_heading p-0 bg-transparent border-0 d-flex align-items-center ${
                        footerCollapseOne ? "show" : ""
                      }`}
                      onClick={() => setFooterCollapseOne(!footerCollapseOne)}
                    >
                      Support
                      <img
                        src={BottomArrow}
                        alt="Bottom_arrow"
                        className="ml-auto"
                      />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <ul className="footer_link_nav p-0 mb-0">
                        <li className="footer_link_item">
                          <a
                            href="/contactus"
                            className="footer_link text-decoration-none"
                          >
                            Contact Us
                          </a>
                        </li>

                        <li className="footer_link_item">
                          <a
                            href="/shippingGuides"
                            className="footer_link text-decoration-none"
                          >
                            Shipping
                          </a>
                        </li>
                        <li className="footer_link_item">
                          <a
                            href="/paymentGuides"
                            className="footer_link text-decoration-none"
                          >
                            Payment
                          </a>
                        </li>

                        {/* <li className="footer_link_item">
                          <a
                            href="/faq"
                            className="footer_link text-decoration-none"
                          >
                            FAQ
                          </a>
                        </li> */}
                      </ul>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
              <Col lg={2}>
                <div className="footer_links_list desktop_footer_link_list">
                  <h3 className="footer_links_heading">Corporate</h3>
                  <ul className="footer_link_nav p-0 mb-0">
                    <li className="footer_link_item">
                      <a
                        href="/aboutus"
                        className="footer_link text-decoration-none"
                      >
                        About FAHH
                      </a>
                    </li>
                    <li className="footer_link_item">
                      <a
                        href="/privacypolicy"
                        className="footer_link text-decoration-none"
                      >
                        Privacy Policy
                      </a>
                    </li>
                    {/* <li className="footer_link_item">
                      <a
                        href="/pressguides"
                        className="footer_link text-decoration-none"
                      >
                        Press
                      </a>
                    </li> */}
                  </ul>
                </div>
                <Accordion defaultActiveKey="0" className="d-none">
                  <Card className="footer_links_list border-0">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="1"
                      className={`footer_links_heading p-0 bg-transparent border-0 d-flex align-items-center ${
                        footerCollapseTwo ? "show" : ""
                      }`}
                      onClick={() => setFooterCollapseTwo(!footerCollapseTwo)}
                    >
                      Corporate
                      <img
                        src={BottomArrow}
                        alt="Bottom_arrow"
                        className="ml-auto"
                      />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <ul className="footer_link_nav p-0 mb-0">
                        <li className="footer_link_item">
                          <a
                            href="/aboutus"
                            className="footer_link text-decoration-none"
                          >
                            About FAHH
                          </a>
                        </li>
                        <li className="footer_link_item">
                          <a
                            href="/privacypolicy"
                            className="footer_link text-decoration-none"
                          >
                            Privacy Policy
                          </a>
                        </li>
                        {/* <li className="footer_link_item">
                          <a
                            href="/pressguides"
                            className="footer_link text-decoration-none"
                          >
                            Press
                          </a>
                        </li> */}
                      </ul>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
              <Col lg={2}>
                <div className="footer_links_list desktop_footer_link_list">
                  <h3 className="footer_links_heading">More</h3>
                  <ul className="footer_link_nav p-0 mb-0">
                    <li className="footer_link_item">
                      <a
                        href="/termsandconditions"
                        className="footer_link text-decoration-none"
                      >
                        Terms & Conditions
                      </a>
                    </li>
                    {/* <li className="footer_link_item">
                      <a
                        href="/cookiesetting"
                        className="footer_link text-decoration-none"
                      >
                        Cookie Setting
                      </a>
                    </li> */}
                  </ul>
                </div>
                <Accordion defaultActiveKey="0" className="d-none">
                  <Card className="footer_links_list border-0">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="1"
                      className={`footer_links_heading p-0 bg-transparent border-0 d-flex align-items-center ${
                        footerCollapseTwo ? "show" : ""
                      }`}
                      onClick={() => setFooterCollapseTwo(!footerCollapseTwo)}
                    >
                      More
                      <img
                        src={BottomArrow}
                        alt="Bottom_arrow"
                        className="ml-auto"
                      />
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <ul className="footer_link_nav p-0 mb-0">
                        <li className="footer_link_item">
                          <a
                            href="/termsandconditions"
                            className="footer_link text-decoration-none"
                          >
                            Terms & Conditions
                          </a>
                        </li>
                        {/* <li className="footer_link_item">
                          <a
                            href="/cookiesetting"
                            className="footer_link text-decoration-none"
                          >
                            Cookie Setting
                          </a>
                        </li> */}
                      </ul>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Col>
              <Col lg={2}>
                <div className="footer_links_list">
                  <h3 className="footer_links_heading d-lg-block d-none">
                    Social Media
                  </h3>
                  <h3 className="footer_links_heading d-block d-lg-none">
                    Follow Us
                  </h3>
                  <ul className="footer_social_nav p-0 mb-0 d-flex align-items-center">
                    <li className="footer_social_item">
                      <a
                        target="_blank"
                        href="https://twitter.com/fineart_hiphop"
                        className="footer_social_link text-decoration-none d-flex align-items-center justify-content-center"
                      >
                        <img src={twitter_new} alt="facebook" />
                      </a>
                    </li>
                    <li className="footer_social_item">
                      <a
                        href="https://www.instagram.com/fineart.hiphop/"
                        className="footer_social_link text-decoration-none d-flex align-items-center justify-content-center"
                      >
                        <img src={GooglePluse} alt="facebook" />
                      </a>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
    </>
  );
};
