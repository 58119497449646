import styled from "styled-components";

export const BiddingInfoStyled = styled.div<{
  widthProgress?: any;
}>`
  .main-sec-top-progress-bar {
    width: 300px;
    display: flex;
    justify-content: end;
    padding-top: 6px;
    max-width: 300px;
  }
  .my-progress-myProgress {
    width: 100%;
    background-color: #ddd;
    height: 7px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  .myProgress-myBar {
    height: 7px;
    width: ${({ widthProgress }) => widthProgress || "0%"};
    background-color: #eb5454;
    border-radius: 4px;
  }
  .full-time-sec-amount-sec-count {
    display: flex;
    // grid-template-columns: 1fr 1fr;
    gap: 30px;
  }
  .place-bid-time-sec-count-time {
    min-width: max-content;
  }
  .place-bid-time-sec-count-bar {
    display: contents;
    max-width: 300px;
  }
  .auction-time-sec p{
 color: var(--Black, #212121);
// 
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.15px;
  }
  .starting-time-sec {
    color: #000;

    // 
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
  }
  .place-bid-sec-con {
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .place-bid-sec-con-btn {
    width: 92%;
  }
  .place-bid-sec-con-btn button {
    border-radius: 4px;
    background: #8a0f0f;
    color: #fff;
    height: 52px;
    width: 100%;
    border: none;
    text-align: center;
    // font-family: Oswald;
    font-size: 19px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  .place-bid-sec-con-info {
  }
  .how-bid-works-sec {
    display: flex;
    justify-content: space-between;
    width:92%;
    margin-top: 20px;
    p {
      color: #000;

      
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.15px;
    }
    span {
      color: var(--Red-Colour, #8a0f0f);
      
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.15px;
      cursor: pointer;
    }
  }
  .how-bid-works-sec-left,
  .how-bid-works-sec-right {
    display: flex;
    gap: 8px;
    align-items: center;
  }
  .how-bid-works-sec-left p,
  .how-bid-works-sec-right p {
    margin-bottom: 0px;
  }
  .how-bid-works-sec-left svg,
  .how-bid-works-sec-right svg {
    cursor: pointer;
  }
  .how-bid-works-sec-center {
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    margin-top: 6px;
  }
  .how-bid-works-sec-right {
  }
  .bids_enter_btn_lots button {
    width: 100%;
  }
  .green-color-txt {
    color: var(--Green-1, #219653);
    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.15px;
    margin-bottom: 22px;
  }
  .green-color-txt-outbid {
    color: #ef9b29;
    margin-bottom: 20px;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.15px;
  }
  .bid-amount-sec-amount {
    color: var(--Black, #212121);
    
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.15px;
  }
  .bids-count-txt-num {
    color: var(--Red-Colour, #8a0f0f);
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
    text-decoration-line: underline;
    cursor: pointer;
  }
  .bids-count-txt-num-count0{
    cursor: auto;
    text-decoration-line: none;
  }
  .timer-low-sec-count00,
  .time-red-color {
    color: red;
  }
  .error_msg {
    color: red !important;
  }
  .bid-history-table-view tr td {
    padding: 24px 40px !important;
  }
  .bid-history-table-view .bid-history-table-view-time{
    padding: 24px 0px !important;
  }
  .disable-quick-bid-text {
    margin-top: 20px;
    display:flex;
    align-items:center;
    gap:8px;
  }
  .place-bid-sec-full select {
    height: 48px;
    color: #000;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px; /* 175% */
    letter-spacing: 0.5px;
  }
  .currencydiv-table-view {
    max-height: 500px;
  }
  .place-bid-header {
    display: flex;
    justify-content: space-between;
    padding-top: 32px;
  }
  .place-bid-header p {
    margin-bottom: 0px;
    color: var(--Black, #212121);
    
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.15px;
  }
  .place-bid-time-sec-count-time p {
    color: var(--Black, #212121);
        font-family: "benton-sans", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
    margin-bottom: 0px;
  }
  .place-bid-time-sec-count-time-placebid p {
    color: #fff;
  }
  .starting-time-sec {
    margin-top: 20px;
  }
  .starting-time-sec p {
    color: #000;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.15px;
    margin-bottom:24px;
    display:flex;
    align-items:center;
  }
  .starting-time-sec .bid-amount-sec-amount {
    color: var(--Black, #212121);
    
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.15px;
    margin-left:10px;
    margin-right:10px;
  }
  .bids_enter_current_bid_text {
    color: #000;
    
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16.8px;
  }
  .bids_enter_lots_btn_same {
    height: 52px;
  }
  .disable-quick-bid-text {
    
    font-size: 14px;
    font-weight: 500;
  }
  .close-icon-sec {
    cursor: pointer;
  }
  .bid-pop-body-sec {
    display: flex;
  }
  .text-center-or-text {
    color: #494949;
    
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.32px;
    margin 12px auto;
  }
  .bids_enter_lots_btn_same {
    height: 52px;
    min-height: 52px;
    color: #FFF;
font-family: Oswald;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.25px;
text-transform: uppercase;
  }
  .bid-history-header {
    display: flex;
    justify-content: space-between;
     align-items: center;
  }
  .bid-history-header p{
    color: #000;
    font-family: Oswald;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 29px;
    margin-bottom:0px;
  }
  .confirm-bid-body {
    padding:30px;
  }
  .CurrencyTableBody tr:first-child{
    background: #F7F7F7;
  }
  .CurrencyTableBody th{
    padding:24px 40px;
    color: var(--Black, #212121);

font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
letter-spacing: 0.15px;
  }
  .CountryTr td{
    padding:24px 40px !important;
    color: var(--Black, #212121);

font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 0.15px;
  }
  .CountryTr{
    border-bottom: 1px solid #C4C4C4;
  }
  .CurrencyTableBody tr:nth-child(2) td{
    color: var(--Black, #212121);

font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.15px;
  }
  .max-bid-input{
    position:relative;
  }
  .place-bid-popover{
    position:absolute;
    top:-2px;
    left:166px;
  }
  .max-bid-input .label{
    margin-bottom:8px
  }
  .max-bid-input .select-vehicle-dropdown{
    margin-bottom:12px;
  }
   .max-bid-input .select-vehicle-dropdown .select__control .select__value-container{
    padding:2px 20px;
   }
   .place-bid-dollar{
    position: absolute;
    top: 47px;
    left: 10px;
    font-family: 'Montserrat';
   }
   .quick-bid-button{
    display:flex;
    gap:15px;
    align-items:center;
   }
@media (max-width: 1280px) {
.how-bid-works-sec span{
font-size:13px;
}
.how-bid-works-sec p{
  font-size:13px;
}}
.bidcount-text-cursor{
  cursor:auto;
  text-decoration-line: none;
}
.closed-v-status-item {
color: #EB5757;

font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.15px;
margin-bottom: 20px;
}
.closed-v-status-item.you-won{
  color: var(--Green-1, #219653);
}
.please-clk-btn-sold{
  color: #000;

}

.pre-purchase-header{
  color: var(--Black, #212121);

font-size: 22px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.15px;
}
.pre-purchase-text{
  color: #000;
  
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 143.75% */
  letter-spacing: 0.25px;
}
.pre-purchase-footer{
  display: flex;
  width: 100%;
  gap: 12px;
}
.pre-purchase-footer .btn{
  min-width:300px;
  width:314px;
  min-height:54px;
  height:54px;
text-align: center;
font-family: Oswald;
font-size: 19px;
font-style: normal;
font-weight: 400;
line-height: normal;
letter-spacing: 1.25px;
text-transform: uppercase;
}
.pre-purchase-text .max-bid-text{
  color: #000;

font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 23px;
letter-spacing: 0.25px;
}
.pre-purchase-text a{
  color: var(--Red-Colour, #8A0F0F);

font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 23px;
letter-spacing: 0.25px;
text-decoration-line: underline;
}
.pre-purch-deligence{
  color: var(--Black, #212121);

font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
letter-spacing: 0.15px;
}
.lot-timer-sec{
display:inline;
gap:6px;
align-items: center;
}
.lot-timer-sec p{
 margin-bottom:5px;
  }
.lot-timer-sec .full-time-sec-amount-sec-count{
margin-top:-1px;}

@media screen and (max-width: 675px) {
  .place-bid-sec-con{
    gap: 8px;
  }
}
`;
