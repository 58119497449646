import styled from "styled-components";

export const CardItemStyled = styled.div`
  .active-auctions-brow-btns {
    display: flex;
    gap: 16px;
  }
  .active-auction-btn {
    text-decoration: none;
    width: 100%;
    height: 38px;
    color: #000;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: BentonSansMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.04em;
    text-align: right;
    text-transform: uppercase;
    border-radius: 4px;
  }
  .active-auction-btn:hover, .registered-button{
    color: #fff;
    background: #000;
  }
  .active-start-date {
    font-family: BentonSansLight;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
  }
  .active-end-date {
    font-family: BentonSansLight;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 1px;
  }
  .action_slider_body_content ul {
    height: 65px;
  }
  .bidding-text {
    font-family: "BentonSansMedium";
    font-size: 14px;
  }
`;
