import moment from "moment";
import vect2 from "../assets/image/vect2.png";
import mixpanel from "mixpanel-browser";
import { MIXPANEL_ID, STATIC_GATE_CODE } from "./../library/constants";
import { ENV_SHOT_CODE } from "./../config";
import TagManager from "react-gtm-module";

mixpanel.init(MIXPANEL_ID);

export const triggerEventForProfileUpdate = (eventName) => {
  var event = new Event(eventName);
  window.document.dispatchEvent(event);
};

export const convert24to12HoursFormat = (timeString1) => {
  var now = moment(timeString1, "HHmmss").format("h:mm a");
  return now;
};
export const validateImageFormat = (type) => {
  if (type == "image/jpeg" || type == "image/png") {
    return true;
  } else {
    showUserMessage("Please upload Jpeg/Png images only.");
    return false;
  }
};
// export const LOGINID = JSON.parse(localStorage.getItem('customerInfoDetailws')).id;
export const LOGINID =
  localStorage.getItem("customerInfoDetails") != null
    ? JSON.parse(localStorage.getItem("customerInfoDetails")).id
    : undefined;

export const showUserMessage = (msg) => {
  document.getElementById("showUserMessageDivID").innerHTML = msg;
  document.getElementById("showUserMessageDivID1").style.display = "flex";
  setTimeout(() => {
    document.getElementById("showUserMessageDivID1").style.display = "none";
  }, 5000);
};

export const appendToMesaages = (value, typeMessage, text) => {
  console.log(value);
  var lights = document.getElementsByClassName("activeBidInsertRowItem");
  while (lights.length)
    lights[0].className = lights[0].className.replace(
      /\bactiveBidInsertRowItem\b/g,
      ""
    );

  var type = value;
  if (typeMessage == "warningMessage" && text != "") {
    var currencyName1 = document.getElementById("currencyName").value;
    document.getElementById("messagesListParent")?.insertAdjacentHTML(
      "beforeend",
      ` <div>
            <div class='auction_live_sec d-flex align-items-center'>
              <p class='warningMessgeTextsent'>${text}</p>
            </div>
          </div>`
    );
  } else {
    var currencyName1 = document.getElementById("currencyName").value;
    document.getElementById("messagesListParent")?.insertAdjacentHTML(
      "beforeend",
      ` <div id="BidInsertRowItem" class="activeBidInsertRowItem">
            <div class='auction_live_sec d-flex align-items-center'>
              <div class='live_sec_icon'><span class='live_sec_icon_img'>
                  <img src=${vect2} />
                  </span>
              </div>
              <div class='live_sec_cont'>
                <p class='live_sec_cont_type'>${value.split("_")[1]}</p>
                <p class='live_sec_cont_amount'>${value.split("_")[0]} ${
        value.split("_")[2]
      } ${currencyName1} </p>
              </div>
            </div>
          </div>`
    );
  }
  setTimeout(function () {
    var lights = document.getElementsByClassName("activeBidInsertRowItem");
    while (lights?.length)
      lights[0].className = lights[0].className.replace(
        /\bactiveBidInsertRowItem\b/g,
        ""
      );
  }, 2000);
  document.getElementById("auction_box_inner_list").scrollTop = 999999;
};

export const formatPhoneNumber = (phoneNumberString) => {
  const raw = phoneNumberString?.replace(/[\D]/g, "");
  if (phoneNumberString) {
    if (raw.length >= 10) {
      return `${raw.slice(0, 3)}-${raw.slice(3, 6)}-${raw.slice(6, 10)}`.trim();
    } else if (raw.length <= 10 && raw.length > 6) {
      return `${raw.slice(0, 3)}-${raw.slice(3, 6)}-${raw.slice(6, 10)}`.trim();
    } else if (raw.length <= 6 && raw.length > 3) {
      return `${raw.slice(0, 3)}-${raw.slice(3, 6)} ${raw.slice(6, 10)}`.trim();
    } else {
      return `${raw}`.trim();
    }
  } else return "";
};

export const triggerMixpanelEvent = (eventName, jsonData = {}) => {
  console.log("Mixpanel,eventName ", eventName, jsonData);
  const localObj = localStorage.getItem("customerInfoDetails");
  const login_id1 =
    localObj != null && localObj != undefined && localObj != ""
      ? JSON.parse(localObj).id
      : undefined;
  var jsonObjData = {};
  if (login_id1) {
    const userInfo = JSON.parse(localObj);
    if (userInfo?.id) {
      console.log("userInfo", userInfo);
      jsonObjData.name = `${userInfo?.firstName} ${userInfo?.lastName}`;
      jsonObjData.user_id = userInfo?.id;
      jsonObjData.email = userInfo?.email;
      jsonObjData.isVerified = userInfo?.isVerified;
      jsonObjData.active = userInfo?.active;
      jsonObjData.page_url = window?.location?.href;
      jsonObjData.event = eventName;
    }
  }
  // const jsonObjData1 = {
  //   event: eventName,
  //   page_url: window?.location?.href,
  //   user_id: userId,
  //   user_id_new: userId,
  //   name: nameV,
  //   email: emailV,
  //   userType,
  //   UserObject: userObjectV,
  //   created_date,
  //   created_date_mill: time,
  //   marketPlaceName: userMarketPlaceName,
  // };

  const finalJson = { ...jsonData, ...jsonObjData };
  console.log("Mixpanel,finalJson ", eventName, finalJson);
  // mixpanel.identify();
  mixpanel.track(eventName, finalJson);
  // window.dataLayer = window.dataLayer || [];
  try {
    TagManager.dataLayer.push({
      ...jsonObjData,
    });
  } catch (e) {
    console.log("e", e);
  }
};

export const triggerUserFprofileInMixpanel = (useD) => {
  // mixpanel.onBoarding_project.identify(userId);
  // analytics.identify("onBoarding_"+userId, {
  //   name: nameV,
  //   email: emailV
  // });
  // analytics.track(eventId_segment, jsonObjData_segment);
  // mixpanel.onBoarding_project.track(eventName, finalJson );
  // mixpanel.onBoarding_project.people.set({
  //   'name': nameV,
  //   'user_id': userId,
  //   $email: emailV,
  // });
  try {
    if (useD && useD?.id) {
      mixpanel.identify(useD?.id);
      const nameD = `${useD?.firstName} ${useD?.lastName}`;
      mixpanel.people.set({
        name: nameD,
        user_id: useD?.id,
        $email: useD?.email,
      });
    } else {
      console.log("vvvvvvvv");
      mixpanel.identify();
      mixpanel.people.set({
        name: "Guest User",
      });
    }
  } catch (e) {
    console.log("e", e);
  }
};
export const getGateCode = () => {
  const currTime = new Date().getTime();
  console.log("currTime", currTime);
  // if (currTime > 1695473116082) {
  return STATIC_GATE_CODE;
  // } else
  if (ENV_SHOT_CODE === "qa") {
    return STATIC_GATE_CODE;
  } else {
    return localStorage.getItem("gateCode2");
  }
};

export const displayAmount = (value) => {
  return parseFloat(value?.toFixed(2))?.toLocaleString();
};
// export const convertUTCToLocaltime = (time, timeformat) => {
//   return moment
//     .utc(time)
//     .local()
//     .format(timeformat || "MMM DD YYYY, h:mm A");
// };
// export const convertUTCTime = (time, timeformat) => {
//   return moment
//     .utc(time)
//     .local()
//     .format(timeformat || "MMM DD YYYY, h:mm A");
// };

// export const convertUTCToLocalDate = (time) => {
//   return parseInt(moment.utc(time).local().format("MMM DD YYYY"));
// };
// export const convertUTCToLMilleSec = (time) => {
//   return parseInt(moment.utc(time).local().format("x"));
// };
// export const convertLMilleSec = () => {
//   return parseInt(Date.now());
//   // return moment.now().format("x");
// };

// new
export const convertUTCToLocaltime = (time, timeformat) => {
  return moment
    .utc(time)
    .local()
    .format(timeformat || "MMM DD YYYY, h:mm A");
};
export const convertUTCTime = (time, timeformat) => {
  return moment
    .utc(time)
    .local()
    .format(timeformat || "MMM DD YYYY, h:mm A");
};

export const convertUTCToLocalDate = (time) => {
  return parseInt(moment.utc(time).local().format("MMM DD YYYY"));
};
export const convertUTCToLMilleSec = (time) => {
  return parseInt(moment.utc(time).local().format("x"));
};
export const convertLMilleSec = () => {
  return parseInt(Date.now());
  // return moment.now().format("x");
};
