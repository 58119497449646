import React, { useState } from "react";
import { Link } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import { Button, Card } from "react-bootstrap";
import Form from "react-bootstrap/Form";

import BottomArrow from "../../assets/image/Bottom_arrow.svg";
import FilterBtn from "../../assets/image/filter.png";
import CloseIcon from "../../assets/image/close.svg";
import { isMobile } from "react-device-detect";

import "./css/ActionCalendarLeftFilter.css";
import { AnyAaaaRecord } from "dns";
import { LOGINID } from "./../../Scenes/common";
// import "antd/dist/antd.css";
import { DatePicker, Space } from "antd";
import moment from "moment";
const { RangePicker } = DatePicker;

const ActionCalendarLeftFilter = (props: any) => {
  const { selectedMonths, setSelectedMonths, setSelectedYears, selectedYears } =
    props;
  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [showLimit, setShowLimit] = useState(5);
  const [sDate, setSDate]: any = useState(
    moment(new Date()).subtract(7, "days").format("YYYY-MM-DD")
  );
  const [eDate, setEDate]: any = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const FilterValueSelect = (e: any, artist: any, type: any) => {
    if (type == "Events") {
      if (e.target.checked) {
        setSelectedEvents(selectedEvents.concat(artist));
      } else {
        const existData = selectedEvents;
        const index = existData?.findIndex((el: any, ind: any) => el == artist);
        existData?.splice(index, 1);

        setSelectedEvents(existData);
      }
    } else if (type == "Category") {
      if (e.target.checked) {
        setSelectedCategories(selectedCategories.concat(artist));
      } else {
        const existData = selectedCategories;
        const index = existData?.findIndex((el: any, ind: any) => el == artist);
        existData?.splice(index, 1);

        setSelectedCategories(existData);
      }
    } else if (type === "Month") {
      setSelectedMonths(artist);
    } else if (type === "Year") {
      setSelectedYears(artist);
    }
  };
  const clearAllFilters = (e: any, type: any) => {
    if (type == "ClearAll") {
      if (e.target.click) {
        document
          .querySelectorAll("input[type=checkbox]")
          .forEach((el: any) => (el.checked = false));

        setSelectedEvents([]);
        setSelectedYears(moment().format("YYYY"));
        setSelectedCategories([]);
        setSelectedMonths(moment().format("MMMM"));
      }
    }
  };

  console.log(selectedYears, "artist");

  //   let body: any = {};
  //   body = {
  //     page: 1,
  //     pageSize: 3,
  //     getOnlyTotalCount: false,
  //     collectionId: .props.collectionid,
  //     customerId: LOGINID,
  //   };
  //   if (selectedEvents.length > 0) {
  //     body.auctionType = selectedEvents;
  //   }
  //   if (selectedCategories.length > 0) {
  //     body.categoryId = selectedCategories;
  //   }
  //   if (selectedMonths.length > 0) {
  //     body.months = selectedMonths;
  //   }
  //   if (selectedYears.length > 0) {
  //     body.years = selectedYears;
  //   }

  //   this.props.getUpdatedDataCall(body);
  // };
  const clickShowMore = () => {
    setShowLimit(showLimit + 5);
  };

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const thisMonth: any = months[new Date().getMonth()];

  const { handleTab, tabMonths, AllCollections } = props;
  const categoriesList = props?.AllCollectionsFull?.categories;
  const auctionTypeList = props?.AllCollectionsFull?.auctionType;
  const monthsList = props?.AllCollectionsFull?.months;
  const yearsList = props?.AllCollectionsFull?.years;

  const handleDateChange = (seDate: any) => {
    console.log(seDate, "seDate");
    if (seDate) {
      const sDate = moment(seDate[0]).format("YYYY-MM-DD");
      const eDate = moment(seDate[1]).format("YYYY-MM-DD");
      setSDate(sDate);
      setEDate(eDate);
      const body = {
        stateDate: sDate,
        endDate: eDate,
      };
    }
  };

  return (
    <>
      <div className="action_calendar_left_filter">
        <div className="custom_slider_heading_filter pt-0 d-flex align-items-center">
          <Form.Group
            controlId="exampleForm.ControlSelect1"
            className="ml-auto d-md-none"
          >
            <Form.Control
              as="select"
              onChange={(event) => handleTab(event.target.value)}
            >
              <option value="all">All</option>
              {tabMonths.map((m: any) => {
                return (
                  <option key={m} value={m}>
                    {thisMonth === m ? "This Month" : m}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>
        <div className="action_calenda_filter_menu">
          <div className="results-text">Showing 121 results</div>
          <Accordion
            defaultActiveKey="0"
            className={`${filterMenuOpen && "active"}`}
          >
            <div className="responsive_menu_close mb-3 d-flex align-items-center d-lg-none">
              <Link
                to="/auction-calendar"
                className="mr-auto text-decoration-none"
                onClick={(e) => {
                  clearAllFilters(e, "ClearAll");
                }}
              >
                Clear All
              </Link>
              <div
                className="close_icon"
                onClick={() => {
                  setFilterMenuOpen(true);
                }}
              >
                <img src={CloseIcon} alt="close_icon" />
              </div>
            </div>
            <div className="mobile_right_sidebar_content">
              <section className="sort-by-text">
                <h2>Sort By</h2>
                <span>Clear all</span>
              </section>
              {props?.isResultsPage && (
                <>
                  <Card className="rounded-0 border-0">
                    <Card.Header className="p-0 border-0 bg-transparent">
                      <Accordion.Toggle
                        className="w-100  text-left text-decoration-none rounded-0"
                        as={Button}
                        variant="link"
                        eventKey="0"
                      >
                        <span className="mr-auto d-block mb-2">Year</span>
                        <div className="d-flex justify-content-between">
                          <span>{selectedYears}</span>
                          <img src={BottomArrow} alt="dropdown arrow" />
                        </div>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body className="p-0">
                        <div className="filter_list calender-filter-list">
                          {(yearsList || []).map((i: any, index: any) => {
                            return (
                              <div
                                onClick={(e: any) => {
                                  FilterValueSelect(e, i, "Year");
                                }}
                                className={selectedYears == i ? "selected" : ""}
                              >
                                {i}
                              </div>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card className="rounded-0 border-0">
                    <Card.Header className="p-0 border-0 bg-transparent">
                      <Accordion.Toggle
                        className="w-100  text-left text-decoration-none rounded-0"
                        as={Button}
                        variant="link"
                        eventKey="1"
                      >
                        <span className="mr-auto d-block mb-2">Month</span>
                        <div className="d-flex justify-content-between">
                          <span>{selectedMonths}</span>
                          <img src={BottomArrow} alt="dropdown arrow" />
                        </div>
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body className="p-0">
                        <div className="filter_list calender-filter-list">
                          {(monthsList || []).map((i: any, index: any) => {
                            return (
                              <div
                                onClick={(e: any) => {
                                  FilterValueSelect(e, i, "Month");
                                }}
                                className={
                                  selectedMonths === i ? "selected" : ""
                                }
                              >
                                {i}
                              </div>
                            );
                          })}
                        </div>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </>
              )}
              {!props?.isResultsPage && (
                <Card className="rounded-0 border-0 calender-">
                  <section className="ascending-filter">
                    <Form.Group>
                      <Form.Check
                        type="radio"
                        id={"1"}
                        name="enddate-ascending"
                        value="Sno_asc"
                        label={"End Date - Ascending"}
                        // onClick={this.props?.sortColumnChange}
                      />
                      <Form.Check
                        type="radio"
                        id={"2"}
                        name="enddate-ascending"
                        value="Sno_desc"
                        label={"End Date - Descending"}
                        // onClick={this.props?.sortColumnChange}
                      />
                    </Form.Group>
                  </section>

                  <div className="date-filter-sec">
                    <h3>Date</h3>
                  </div>

                  <RangePicker
                    onChange={handleDateChange}
                    placeholder={["From", "To"]}
                    className="date-range-picker"
                  />

                  <Card.Header className="p-0 border-0 bg-transparent">
                    <Accordion.Toggle
                      className="w-100 d-flex align-items-center mt-3 text-left text-decoration-none rounded-0"
                      as={Button}
                      variant="link"
                      eventKey="2"
                    >
                      <span className="mr-auto d-block">Sales</span>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Card.Body className="p-0">
                    <div className="filter_list">
                      {(auctionTypeList || []).map((i: any) => {
                        return (
                          <Form.Group controlId={i.type} key={i.type}>
                            <Form.Check
                              onClick={(e: any) => {
                                FilterValueSelect(e, i?.type, "Events");
                              }}
                              type="checkbox"
                              label={`${
                                i.auction === "Online" ? "Online Only" : "Live"
                              }`}
                            />
                          </Form.Group>
                        );
                      })}
                    </div>
                  </Card.Body>
                </Card>
              )}
              {/* <Card className="rounded-0 border-0">
                  <Card.Header className="p-0 border-0 bg-transparent">
                    <Accordion.Toggle
                      className="w-100 d-flex align-items-center text-left text-decoration-none rounded-0"
                      as={Button}
                      variant="link"
                      eventKey="3"
                    >
                      <span className="mr-auto d-block">Category</span>
                      <img src={BottomArrow} alt="dropdown arrow" />
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body className="p-0">
                      <div className="filter_list">
                        {(categoriesList || []).map((i2: any, index: any) => {
                          return (
                            <>
                              {showLimit > index && (
                                <Form.Group controlId={i2.id} key={i2.id}>
                                  <Form.Check
                                    onClick={(e: any) => {
                                      this.FilterValueSelect(
                                        e,
                                        i2?.id,
                                        "Category"
                                      );
                                    }}
                                    type="checkbox"
                                    label={i2.category}
                                  />
                                </Form.Group>
                              )}
                            </>
                          );
                        })}
                        {showLimit < categoriesList?.length && (
                          <Button
                            onClick={this.clickShowMore}
                            className="show_more_filter_list text-decoration-none"
                          >
                            Show More
                          </Button>
                        )}
                      </div>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> */}
            </div>
          </Accordion>
        </div>
      </div>
    </>
  );
};
export default ActionCalendarLeftFilter;
