import React, { Component } from "react";
import { isMobile } from "react-device-detect";
import { ReactComponent as ChevronLeftIcon } from "../../assets/image/chevronLeft.svg";
import "./css/styles.css";

class HowBidWorks extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.goBack = this.goBack.bind(this); // i think you are missing this
  }

  goBack() {
    this.props.history.goBack();
  }
  render() {
    return (
      <div className="howBidWorkContainer">
        <div className="headerSectionBidWorks">
          <p className="bidWorkBackText" onClick={() => this.goBack()}>
            <span className="chevronLeft">
              <ChevronLeftIcon />
            </span>{" "}
            Back
          </p>
          <p className="bidWorksHeading">How to Bid Online</p>
        </div>
        <div className="bidWorkTextDiv">
          <div>
            {/* <p>
              Browse an auction on FineArt.HipHop. We encourage you to contact
              our specialists at:{" "}
              <a
                className="email-field-sec-a"
                href="mailto:support@fineart.hiphop"
              >
                support@fineart.hiphop
              </a>{" "}
              if you require assistance.
            </p> */}
            <p>
              Go to the FineArt.HipHop Calendar to search for Active and
              Upcoming auctions. Select an auction, browse and place bids on
              lots. You are able to place a bid on a lot from the start of the
              auction until bidding on a lot is closed. See below for how to bid
              in online only auctions.
            </p>
            <p>
              Watch your bids! If you are outbid in an online auction, you will
              be notified on the Auction and Lot Detail Pages or via email.
            </p>
            <p>
              After bidding on your lot has closed, you will be notified on the
              Auction and Lot Detail Pages or via email. If you are the winning
              bidder, you will receive an invoice shortly after the auction
              closes which will include your itemized charges, payment
              information and property collection instructions.
            </p>
            <p>
              If you require assistance, please contact support@fineart.hiphop.
            </p>
          </div>
          <hr />
          <div>
            <p className="howBidWorkHeadingTexts">ONLINE ONLY AUCTIONS</p>
            <p>
              Select the lots you wish to bid on and place your maximum bid. You
              will be asked to either log in or create a new account. The
              platform will place incremental bids on your behalf up the maximum
              amount specified. Use the “Quick Bid” feature to increase your bid
              to the next increment.
            </p>
            <p>
              The closing time noted at the top of the auction page indicates
              when the first lot will begin to close. As the auction closes, a
              countdown timer will be displayed for each lot one minute before
              it closes. Each lot closes in one minute increments and will be
              extended by two minutes if a bid is placed within the final one
              minute before the lot's scheduled closing time. An auction for a
              lot may be extended for up to one hour to accommodate competitive
              bidding.
            </p>
          </div>
          <hr />
          {/* <div>
            <p className="howBidWorkHeadingTexts">LIVE AUCTIONS</p>
            <p>
              There are four different ways to bid in a live auction: by
              telephone, online, in the room, and by either absentee or advance
              bidding ahead of the auction. You can register for telephone,
              online, or in-room bidding via the Register to Bid link on the
              auction overview page. ‘Advance bids' or ‘absentee bids', both of
              which enable you to set your maximum bid on a lot, can be placed
              online or via the Bids Department ahead of the auction. Please
              note that auctions will either have advance bidding or absentee
              bidding, never both.
            </p>
            <p>
              At the stated date and time, a live auction will begin when the
              auctioneer opens each lot for bidding. Clients are able to
              participate by bidding in the saleroom, by telephone, or online
              via FAHHs.com or the FAHH's app. Bids will be accepted in the
              order they are received and the highest bid placed will win each
              lot. When competitive bidding subsides, the auctioneer can send a
              ‘fair warning' message to indicate that the lot will close
              imminently. Please note that placing the leading maximum bid
              before the live auction begins does not guarantee that another
              client will not outbid you before the auctioneer closes the lot.
            </p>
            <p>
              <b>Absentee bid:</b> A maximum bid placed ahead of the auction
              which will be executed on your behalf by the auctioneer during the
              live auction, at the lowest price possible and never for more than
              the maximum bid amount you indicate. Successful absentee bidders
              will be notified after the auction.
            </p>
            <p>
              <b>Advance bid:</b> A maximum bid placed ahead of the auction
              which will be executed on your behalf in response to any other
              bids placed on the lot. If your maximum bid is below the reserve,
              responsive bids may be placed by FAHH's on behalf of the seller up
              to the amount of the reserve ahead of the live auction. Upon the
              start of the live auction, if your maximum bid has not yet been
              met, the auctioneer will execute the bid on your behalf at the
              lowest price possible and never for more than the maximum bid
              amount you indicate.
            </p>
            <p>
              To place an ‘absentee bid' or ‘advance bid' ahead of the live
              auction, find your desired lot and select ‘Place Bid.' Set your
              maximum bid amount. Once you have chosen your bid amount, select
              ‘Continue to Confirm Bid.' You will then be asked to either log in
              or sign up for a FAHH's account. Select the FAHH's Account Number
              you wish to bid under, accept the Terms & Conditions, and confirm
              your bid. You will be asked to do this only once, upon placing
              your first bid in the auction. You will receive a confirmation of
              your absentee or advance bid on screen and via email. If you are
              outbid during this portion of the auction (before the live auction
              begins), you will receive notification on FAHHs.com if you are
              logged in, via email, and via FAHH's mobile app push notifications
              (if enabled in your settings). The advance and absentee portion of
              the auction will culminate in the live auction on the advertised
              date.
            </p>
          </div>
          <hr /> */}
          <div>
            <p className="howBidWorkHeadingTexts">AUCTION GLOSSARY</p>
            <p className="howBidWorkSubHeadingTexts">Maximum Bid</p>
            <p>
              Your maximum bid is the highest amount you would like to bid for a
              lot. It will be executed by FineArt.HipHop at the lowest price
              possible, and never for more than the maximum bid amount you
              indicate. Please note that if two clients enter the same maximum
              bid, the bid which was entered first will have priority.
            </p>
            <p>
              For auctions with absentee bidding, your maximum bid will be
              executed by the auctioneer on your behalf during the live auction
              and you will be notified if you won the auction for a particular
              lot. You can increase your maximum bid at any point ahead of the
              live auction.
            </p>
            <p>
              For auctions with advance bidding, the bidding platform will
              automatically place incremental bids on your behalf against any
              competition up to the maximum bid you have placed. The bidding
              platform will execute bids beginning at the lowest increment. If
              your maximum bid is outbid by another prospective buyer, you will
              be alerted on FineArt.HipHop if you are logged in and via email
              for a chance to increase your bid.
            </p>
            <p className="howBidWorkSubHeadingTexts">Starting Bids</p>
            <p>
              Each lot has a starting bid, which is the lowest possible bid you
              can place on a lot. You may bid at or above the starting bid (see
              above for how to place a Maximum Bid). Please note that leaving a
              bid at the starting bid may not necessarily mean you have met the
              reserve, though you will be notified if you are the leading bidder
              or have been outbid. Please note that FineArt.HipHop reserves the
              right to lower the starting bid prior to the start of the live
              auction.
            </p>
            <p className="howBidWorkSubHeadingTexts">Quick Bid</p>
            <p>
              After placing an initial bid on your desired lot, you will be
              presented with the option to place a “Quick Bid”. This is a faster
              way to increase your bid to the next increment. Upon completion,
              your bid will be placed.
            </p>
            <p className="howBidWorkSubHeadingTexts">My Active Bids</p>
            <p>
              Once you have placed a bid on a lot in an auction, you can view
              that bid and any other active bids near the top of the auction
              page, in a dynamic section called ‘My Active Bids.' This section
              displays the status and allows you to manage all your active bids
              on lots in the auction.
            </p>
          </div>
          <hr />
          <div className="assisatnceBiddingDiv">
            <p className="howBidWorkHeadingTexts assisatnceBiddingText">
              FOR ASSISTANCE WITH REGISTRATION & BIDDING
            </p>
            <p>
              For assistance with registration and bidding, please contact{" "}
              <a href="mailto:support@fineart.hiphop">support@fineart.hiphop</a>{" "}
            </p>
            {/* <p className="enquiriesMail">enquiries@fahhs.com</p>
            <p>From the US +1 212 606 7000</p>
            <p>From the UK +44 (0) 20 7293 5000</p>
            <p>Hong Kong +852 2524 8121</p>
            <p className="assistanceText">
              For assistance with registration and bidding, please contact the
              FAHH's Bids Department in the sale location directly.
            </p>
            <p>bids.newyork@fahhs.com</p>
            <p>bids.london@fahhs.com</p>
            <p>bids.hongkong@fahhs.com</p>
            <p>bids.paris@fahhs.com</p>
            <p>bids.geneva@fahhs.com</p>
            <p>bids.milan@fahhs.com</p> */}
            {/* <p className="encourageText">
              We encourage you to also contact our specialists, whose contact
              information is included on the sale Catalog page.
            </p> */}
          </div>
        </div>
      </div>
    );
  }
}
export default HowBidWorks;
