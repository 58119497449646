import React, { Component } from "react";
import "./style.css";
class TermsAndConditions extends React.Component {
  componentDidMount() {
    document.title = "FAHH-Terms and Conditions";
  }
  render() {
    return (
      <div className="contactUsContainer">
        <p className="contact-us-main-header">Terms and Conditions</p>
        <div className="shipping-box-header terms-con-add-space">
          <p className="pri-poli-header-con">
            FineArt.HipHop Retail Terms of Sale
          </p>
          <p className="pri-poli-header-con-sec">
            THIS DOCUMENT CONTAINS IMPORTANT INFORMATION REGARDING YOUR RIGHTS
            AND OBLIGATIONS, AS WELL AS CONDITIONS, LIMITATIONS, AND EXCLUSIONS
            THAT APPLY TO YOU. PLEASE READ IT CAREFULLY. <br />
            <div></div>
            BY PLACING AN ORDER FOR PRODUCTS ON THE DIGITAL PLATFORM (AS DEFINED
            BELOW), YOU ACCEPT AND AGREE TO BE BOUND BY THESE TERMS OF SALE
            (“TERMS OF SALE”) (INCLUDING THE ARBITRATION AGREEMENT AND CLASS
            ACTION WAIVER SET FORTH HEREIN PURSUANT TO WHICH YOU AGREE THAT
            DISPUTES BETWEEN YOU AND US WILL BE RESOLVED BY BINDING, INDIVIDUAL
            ARBITRATION (EXCEPT AS OTHERWISE PROVIDED HEREIN) AND YOU WAIVE YOUR
            RIGHT TO PARTICIPATE IN A CLASS ACTION LAWSUIT OR CLASS-WIDE
            ARBITRATION), THE WEBSITE TERMS OF USE located on fineart.hiphop,
            and our Privacy Policy (COLLECTIVELY, THE “pURCHASE TERMS”), AND YOU
            REPRESENT THAT (A) YOUR PURCHASE AND USE OF PRODUCTS MADE AVAILABLE
            ON THE DIGITAL PLATFORM WILL BE IN ACCORDANCE WITH THE PURCHASE
            TERMS AND ALL APPLICABLE LAWS, RULES AND REGULATIONS AND (B) YOU ARE
            OF LEGAL AGE TO ENTER INTO A BINDING CONTRACT. If you do not agree
            to the Purchase Terms, you may not purchase products on the Digital
            Platform. <br /> <div></div>
            The following Terms of Sale are entered into by and between you and
            FineArt.HipHop (“FineArt.HipHop” or “we” or “us” or “our” or other
            similar pronouns) and, together with any documents incorporated
            herein by reference, govern your purchase and our offer and sale of
            products through the e-commerce site available through
            FineArt.HipHop website available at www.fineart.hiphop (the
            “FineArt.HipHop Website”), whether accessed through a desktop,
            mobile device, tablet, mobile application or other means
            (collectively, the “Digital Platform”). These Terms of Sale are an
            integral part of, and subject to, the Terms of Use that apply
            generally to the FineArt.HipHop Website. You should also carefully
            review all Purchase Terms before placing an order for products on
            the Digital Platform.
          </p>
          <p className="pri-poli-header-con-sec">
            We may change these Terms of Sale from time to time in our sole
            discretion, at any time without notice to you, by posting such
            changes on the FineArt.HipHop Website. It is your responsibility to
            periodically check the Terms of Sale for changes. You will know if
            these Terms of Sale have been revised since your last visit to the
            Digital Platform by referring to the “Last Modified” date at the
            bottom of this page. IF YOU DO NOT AGREE TO THE CURRENT TERMS OF
            SALE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO REFRAIN FROM PLACING
            ORDERS FOR PRODUCTS THROUGH THE DIGITAL PLATFORM.{" "}
          </p>
          <div className="terms-and-con-rules-sec">
            <p>
              1.<span> Our Role</span>. You acknowledge that we customarily
              offer products for sale on behalf of third-party sellers, which
              may be unnamed, on a consignment basis. As a result, in offering
              certain products on the Digital Platform and accepting and
              fulfilling orders we are acting as the applicable seller’s agent
              and we do not take or transfer title to the product offered for
              sale on the Digital Platform. In other instances, the products we
              offer for sale through the Digital Platform are owned by
              FineArt.HipHop. <br /> <div></div>
              We do not produce, endorse, or recommend any of the products made
              available through the Digital Platform. With respect to products
              that are offered for sale on a consignment basis, we are not
              affiliated with, endorsed by, or otherwise associated with any
              consignor or seller, or the retailer, designer, manufacturer,
              artist, author or other creator of the products. You acknowledge
              that the products offered on the Digital Platform are often
              antique or vintage in nature or have been previously owned and
              therefore may show signs of age and wear. We offer a limited
              Authenticity Guarantee with respect to products offered for sale
              through the Digital Platform.
              <br /> <div></div>
              EXCEPT AS SET FORTH IN THE AUTHENTICITY GUARANTEE, WE ASSUME NO
              OBLIGATION TO INDEPENDENTLY VERIFY OR INVESTIGATE ANY PRODUCT, THE
              RIGHTS OF ANY SELLER TO SELL ANY PRODUCT, OR THE QUALITY OR
              CONDITION OF ANY PRODUCT OFFERED FOR SALE THROUGH THE DIGITAL
              PLATFORM.
              <br /> <div></div>
              Notwithstanding the disclaimers of representations and warranties
              in Section 13 of these Terms of Sale, unless otherwise agreed to
              in writing with FineArt.HipHop, if the products offered on the
              Digital Platform are described using CAPITALIZED TYPE (the
              "CAPITAL HEADING"), then FineArt.HipHop guarantees for a period of
              four years from the date of the sale of the product and only to
              the original purchaser of record that the product is as described
              in the CAPITAL HEADING. In the event FineArt.HipHop determines, in
              its sole discretion, that the product description in the CAPITAL
              HEADING is incorrect, then your sole remedy, to the exclusion of
              any other remedy available to you by law or otherwise, shall be
              the refund of the purchase price paid in exchange for the product
              which must be returned to FineArt.HipHop in the same condition as
              when sold to you. The benefits of this guarantee are not
              assignable and shall be applicable only to the original purchaser
              of record and shall apply unless otherwise agreed to in writing
              with FineArt.HipHop.
            </p>
            <p>
              2.<span> Orders</span>. You agree that your order is an offer to
              buy, under these Terms of Sale, all products listed in your order.
              You may not cancel an order following its placement. All orders
              are subject to our acceptance, and we will not be obligated to
              fulfill any order unless and until we accept it. Orders are
              subject to product availability, and we may, in our sole
              discretion, choose to limit, cancel, refrain from processing, or
              prohibit an order for any reason. This may occur, for example, if
              the product ordered is out of stock (including if another
              purchaser places an order for a particular product before you do)
              or has been mispriced, if we suspect the order is fraudulent, or
              in other circumstances we deem appropriate in our sole discretion.
              We also reserve the right to take steps to verify your identity
              before accepting or processing your order.
              <br /> <div></div>
              If we accept your order, we will send you a confirmation email
              with your order number, a summary of the items ordered, and
              shipping and pricing details (“Order Confirmation”). Our
              acceptance of your order and the formation of a contract of sale
              between you and us with respect to the relevant product(s) will
              not take place unless and until you have received your Order
              Confirmation email and your method of payment is charged. If your
              order cannot be completed for any reason, we will notify you using
              the information you provide when placing your order. Certain
              products that you purchase through the Digital Platform may be
              subject to additional terms and conditions presented to you at the
              time of such purchase. For the avoidance of doubt, purchases of
              auction items, whether made through the Digital Platform or
              otherwise, are not subject to these Terms of Sale and instead are
              subject to the terms and conditions set forth in the applicable
              Conditions of Sale/Conditions of Business and Terms of
              Guarantee/Authenticity Guarantee that govern each auction, as
              presented separately in connection with such auction and, if
              applicable, certain additional terms and conditions for online
              bidding.
              <br /> <div></div>
              We reserve the right to modify, suspend or discontinue,
              temporarily or permanently, the offering of any products through
              the Digital Platform at any time, in our sole discretion. A
              REFERENCE TO A PRODUCT ON THE DIGITAL PLATFORM DOES NOT IMPLY OR
              GUARANTEE THAT IT IS OR WILL BE AVAILABLE IN YOUR LOCATION OR AT
              THE TIME OF YOUR ORDER.
            </p>
            <p>
              3.<span> Product Descriptions</span>. We attempt to provide
              accurate descriptions and images of products offered through the
              Digital Platform. It is your responsibility to review all of the
              information provided about a product before placing an order.
              However, we do not warrant that the product descriptions,
              condition reports, images or other information provided are
              accurate, complete, precise, reliable, current or error-free. You
              acknowledge that the products are sold on an “as-is” basis.{" "}
            </p>
            <p>
              4.<span> Prices</span>. All prices posted on the Digital Platform
              are subject to change without notice. We attempt to provide
              accurate pricing information regarding the products available on
              the Digital Platform, but cannot offer guarantees against pricing
              errors and are not responsible for typographical, or other pricing
              errors. If an error occurs, we will inform you using the
              information you provide when placing your order and you may either
              confirm your order (at the corrected product pricing) or cancel
              the order. We reserve the right to cancel any order arising from a
              pricing error. Prices on the Digital Platform are shown in US
              Dollars unless otherwise indicated. For international orders,
              duties and taxes may be included in the price depending on the
              destination country.{" "}
            </p>
            <p>
              5.<span> Promotional Offers; Discounts</span>. From time to time,
              we may offer special promotional offers and/or discounts that may
              apply to purchases through the Digital Platform. You agree to be
              bound by any additional terms and conditions that apply with
              respect to any such special promotional offers or discounts.
              Promotional offers and discounts may be offered on a time-limited
              basis. Some products may be excluded from promotional offers or
              discounts. Such exclusions may be noted on the applicable product
              detail page and will be detailed in the terms and conditions of
              the relevant promotional offer or discount.{" "}
            </p>
            <p>
              6.<span> Payment</span>. You agree to pay in full the prices for
              any purchases you make by payment by Visa, MasterCard, American
              Express, or ACH. We may process payments through third-party
              service providers, which may post operating rules related to
              payment on their respective websites and change such rules from
              time to time. <br /> <div></div>
              By placing an order, you authorize us, and our third-party service
              providers, to charge your selected payment method for all amounts
              presented to you in the checkout and purchase process. You
              represent and warrant that: (a) the payment information (including
              any credit or debit card information) you supply to us is true,
              correct, and complete; (b) you are duly authorized to use such
              payment method for the purchase; (c) charges incurred by you will
              be honored by your credit or debit card company (if applicable);
              and (d) you will pay charges incurred by you at the posted prices,
              including all applicable taxes. If payment is not received by us
              from your credit or debit card issuer or its agents, you agree to
              pay all amounts due promptly upon our first demand. 7. Shipping &
              Tax. Products ordered through the Digital Platform will be shipped
              to the destination specified in the Order Confirmation. Please
              note that FineArt.HipHop is not able to ship products to certain
              destinations, including to any country that is currently subject
              to a U.S. embargo, sanctions or other export controls. Please
              contact FineArt.HipHop to confirm whether we ship to your intended
              destination. Once an Order Confirmation is issued, FineArt.HipHop
              will arrange for shipment of the ordered products to you through a
              third party delivery service provider. When available,
              FineArt.HipHop will provide a shipment tracking number upon
              shipment of all or any part of your order.
              <br /> <div></div>
              Shipping and delivery dates and times are estimates only and
              cannot be guaranteed, and we are not liable for any loss or damage
              caused by a delay in shipment. Title and risk of loss pass to you
              upon our, or the applicable seller’s, transfer of products to the
              delivery service provider. Posted prices do not include taxes and,
              unless otherwise specified on the relevant product page, do not
              include charges for shipping and handling. Applicable taxes and,
              if applicable, shipping and handling charges will be added to your
              merchandise total and will be itemized in your shopping cart and
              in your Order Confirmation. International orders may be subject to
              additional local taxes, import duties or other local requirements.
              Unless exempted by law, the purchaser will be required to pay the
              combined New York State and local sales tax or any applicable
              compensating use tax of another state, and if applicable, any
              other tax on the total purchase price. New York sales tax is
              charged on the posted price and any other applicable charges on
              the product picked up or delivered in New York state regardless of
              the state or country in which the purchaser resides or does
              business. Purchasers who wish to use their own shipper that is not
              a considered a “common carrier” by the New York Department of
              Taxation and Finance will be charged New York sales tax on the
              entire charge regardless of the destination of the product.
              Purchasers who wish to use their own shipper that is not a
              considered a “common carrier” by the New York Department of
              Taxation and Finance will be charged New York sales tax on the
              entire charge regardless of the destination of the product. The
              rate of such combined tax is 8.875% in New York City and ranges
              from 7% to 8.625% elsewhere in New York. FineArt.HipHop is
              required to collect sales or use tax on products and related
              charges, where applicable, delivered into these states: AL, CA,
              CO, CT, DC, FL, GA, HI, IL, IN, IA, KY, ME, MD, MA, MI, MN, MO,
              NE, NV, NJ, NY, NC, OH, OK, PA, RI, SC, TX, UT, VT, WA, WI and WY.
            </p>
            <p>
              8.<span> Acceptance and Returns</span>. Any individual at the
              delivery address who accepts a delivery from the delivery service
              provider is presumed to be authorized to receive such delivery. In
              cases in which you have designated an alternative receiver, such
              person shall accept the delivery under all of the same terms and
              conditions that would apply had you accepted the delivery
              yourself.
            </p>
            <p>
              9.<span> Your Privacy</span>. FineArt.HipHop will hold and process
              your personal information and may share it with another
              FineArt.HipHop affiliate or subsidiary company for use as
              described in, and in line with, FineArt.HipHop Privacy Policy
              published on FineArt.HipHop website.
            </p>
            <p>
              10.<span> Your Obligations</span>. FineArt.HipHop will hold and
              process your personal information and may share it with another
              FineArt.HipHop affiliate or subsidiary company for use as
              described in, and in line with, FineArt.HipHop Privacy Policy
              published on FineArt.HipHop website{" "}
            </p>
            <p>
              11.<span> Customer Service</span>. You may contact our Client
              Service Department via email at: support@fineart.hiphop.{" "}
            </p>
            <p>
              12.<span> Indemnification</span>. You agree to defend, indemnify
              on demand and keep FineArt.HipHop and our affiliates, parents,
              subsidiaries and each of our and their respective officers,
              employees, agents, partners, content providers, service providers,
              suppliers and licensors (collectively, the “Released Parties”)
              indemnified, and hold the Released Parties harmless from any and
              all claims, liabilities, costs, losses (including without
              limitation consequential and indirect losses) and expenses,
              including reasonable attorneys' fees, arising in any way from (a)
              your purchase or use of any products made available through the
              Digital Platform, or any related services or information, or (b)
              your fraudulent or deceptive acts or omissions, or breach or
              violation of the law (including infringement of any intellectual
              property or other right of any person or entity) or of the Terms
              of Sale. We will provide notice to you promptly of any such claim,
              suit, or proceeding. We reserve the right, at our own expense, to
              assume the exclusive defense and control of any matter otherwise
              subject to indemnification by you, and in such case, you agree to
              assist and cooperate with our defense of such claim. In any event,
              you shall not settle any such claim without our prior written
              approval.{" "}
            </p>
            <p>
              13.<span> DISCLAIMER OF WARRANTIES</span>. YOU EXPRESSLY
              UNDERSTAND AND AGREE THAT YOU ARE SOLELY RESPONSIBLE FOR, AND
              ASSUME ALL RISKS RELATED TO, THE PURCHASE, HANDLING, STORAGE AND
              USE OF ANY PRODUCTS MADE AVAILABLE TO YOU THROUGH THE DIGITAL
              PLATFORM. ALL PRODUCTS, SERVICES AND INFORMATION MADE AVAILABLE
              THROUGH THE DIGITAL PLATFORM ARE PROVIDED ON AN “AS IS” AND “AS
              AVAILABLE” BASIS. WITHOUT LIMITING THE FOREGOING, THE RELEASED
              PARTIES (A) MAKE NO WARRANTIES OR REPRESENTATIONS REGARDING ANY
              PRODUCTS, SERVICES OR INFORMATION MADE AVAILABLE OR PROVIDED
              THROUGH THE DIGITAL PLATFORM, AND (B) HEREBY DISCLAIM, AND YOU
              HEREBY WAIVE, ANY AND ALL WARRANTIES AND REPRESENTATIONS WITH
              RESPECT TO SUCH PRODUCTS, SERVICES AND INFORMATION, INCLUDING
              WITHOUT LIMITATION (1) THE IMPLIED WARRANTIES OF TITLE,
              NON-INFRINGEMENT, INTEGRATION, MERCHANTABILITY AND FITNESS FOR A
              PARTICULAR PURPOSE, (2) ANY WARRANTIES IMPLIED BY ANY COURSE OF
              PERFORMANCE OR USAGE OF TRADE, (3) ANY AND ALL WARRANTIES AND
              REPRESENTATIONS MADE IN PRODUCT OR SERVICES LITERATURE, OR
              OTHERWISE ON THE DIGITAL PLATFORM OR IN CORRESPONDENCE WITH
              FINEART.HIPHOP OR ITS AGENTS, AND (4) ANY REPRESENTATION OR
              WARRANTY AS TO authenticity, attribution, the physical condition,
              size, quality, rarity, importance, provenance, restoration,
              exhibitions, literature, or historical relevance of the Property
              and no statement anywhere, whether oral or in writing, shall be
              deemed such a representation or warranty. <br /> <div></div>
              THE RELEASED PARTIES FURTHER DO NOT WARRANT OR GUARANTEE THAT (X)
              THE PRODUCTS OR RELATED SERVICES MADE AVAILABLE THROUGH THE
              DIGITAL PLATFORM WILL MEET YOUR REQUIREMENTS OR THAT THE QUALITY
              OF ANY PRODUCTS OR RELATED SERVICES AVAILABLE ON THE DIGITAL
              PLATFORM WILL MEET YOUR EXPECTATIONS OR (Y) ANY INFORMATION
              PROVIDED WITH RESPECT TO ANY PRODUCT OR RELATED SERVICE MADE
              AVAILABLE THROUGH THE DIGITAL PLATFORM WILL BE TIMELY, ACCURATE,
              RELIABLE, PRECISE, THOROUGH, CORRECT OR COMPLETE.
            </p>
            <p>
              14.<span> LIMITATION OF LIABILITY</span>. IN NO EVENT SHALL ANY
              RELEASED PARTY BE LIABLE, WHETHER IN AN ACTION BASED ON CONTRACT
              INDEMNIFICATION, OBLIGATION, OR TORT (INCLUDING WITHOUT LIMITATION
              NEGLIGENCE), COLLATERALLY OR ARISING FROM ANY STATUTORY DUTY,
              PRE-CONTRACT OR OTHER REPRESENTATIONS, OR OTHERWISE, HOWEVER
              ARISING, FOR ANY ECONOMIC LOSSES (INCLUDING, WITHOUT LIMITATION,
              LOSS OF REVENUES, PROFITS, CONTRACTS, BUSINESS, GOODWILL OR
              ANTICIPATED SAVINGS) OR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL,
              CONSEQUENTIAL, EXEMPLARY OR PUNITIVE DAMAGES ARISING FROM, OR
              DIRECTLY OR INDIRECTLY RELATED TO (A) ANY BREACH OF THE TERMS OF
              SALE, (B) THE PURCHASE OR USE OF, OR THE INABILITY TO USE,
              PRODUCTS, SERVICES OR INFORMATION MADE AVAILABLE THROUGH THE
              DIGITAL PLATFORM, (C) ANY INACCURACY OR OMISSION CONCERNING ANY OF
              THE INFORMATION PROVIDED ON THE DIGITAL PLATFORM WITH RESPECT TO
              THE PRODUCTS OR RELATED SERVICES MADE AVAILABLE THEREON; OR (D)
              LOST BUSINESS OR LOST SALES, IN EACH CASE EVEN IF SUCH RELEASED
              PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. <br />{" "}
              <div></div>
              SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF
              LIABILITY FOR DIRECT, INCIDENTAL OR CONSEQUENTIAL DAMAGES SO SOME
              OF THE ABOVE LIMITATIONS MAY NOT APPLY TO CERTAIN USERS. <br />{" "}
              <div></div>
              IN NO EVENT SHALL THE TOTAL AGGREGATE LIABILITY OF THE RELEASED
              PARTIES FOR ALL DAMAGES, LOSSES, AND CAUSES OF ACTION (WHETHER IN
              CONTRACT OR TORT, INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE OR
              OTHERWISE) ARISING FROM THE TERMS OF SALE OR YOUR PURCHASE OR USE
              OF THE DIGITAL PLATFORM, PRODUCTS, SERVICES OR INFORMATION MADE
              AVAILABLE THROUGH THE DIGITAL PLATFORM EXCEED, IN THE AGGREGATE,
              FIFTY DOLLARS ($50.00).
            </p>
            <p>
              15.<span> Dispute Resolution</span>. EXCEPT AS OTHERWISE PROVIDED
              UNDER THE PURCHASE TERMS, ALL DISPUTES AND CLAIMS BETWEEN YOU AND
              FINEART.HIPHOP THAT ARISE OUT OF OR IN CONNECTION WITH, OR RELATE
              TO, THE PURCHASE TERMS SHALL BE RESOLVED, AT THE FILING PARTY'S
              ELECTION, IN EITHER A SMALL CLAIMS COURT OR BY FINAL AND BINDING
              ARBITRATION ADMINISTERED BY THE AMERICAN ARBITRATION ASSOCIATION
              UNDER ITS COMMERCIAL ARBITRATION RULES AND SUPPLEMENTARY
              PROCEDURES FOR CONSUMER-RELATED DISPUTES, WHICH ARBITRATION SHALL
              TAKE PLACE IN NEW YORK, NEW YORK. ALL DISPUTES IN ARBITRATION WILL
              BE HANDLED SOLELY BETWEEN THE NAMED PARTIES, AND NOT ON ANY
              REPRESENTATIVE OR CLASS BASIS. FURTHER, UNLESS BOTH YOU AND
              FINEART.HIPHOP AGREE OTHERWISE, THE ARBITRATOR MAY NOT CONSOLIDATE
              MORE THAN ONE PERSON'S CLAIMS, AND MAY NOT OTHERWISE PRESIDE OVER
              ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING. THE ARBITRATOR
              MAY AWARD DECLARATORY OR INJUNCTIVE RELIEF ONLY IN FAVOR OF THE
              INDIVIDUAL PARTY SEEKING RELIEF AND ONLY TO THE EXTENT NECESSARY
              TO PROVIDE RELIEF WARRANTED BY THAT PARTY'S INDIVIDUAL CLAIM
              WITHOUT AFFECTING OTHER INDIVIDUALS. <br /> <div></div>
              YOU UNDERSTAND AND AGREE THAT ANY DISPUTE RESOLUTION PROCEEDINGS,
              WHETHER IN ARBITRATION OR COURT, WILL BE CONDUCTED ONLY ON AN
              INDIVIDUAL BASIS AND NOT IN A CLASS OR REPRESENTATIVE ACTION OR AS
              A NAMED OR UNNAMED MEMBER IN A CLASS, CONSOLIDATED, REPRESENTATIVE
              OR PRIVATE ATTORNEY GENERAL ACTION, UNLESS BOTH YOU AND
              FINEART.HIPHOP SPECIFICALLY AGREE TO DO SO IN WRITING FOLLOWING
              INITIATION OF THE ARBITRATION. THIS PROVISION DOES NOT PRECLUDE
              YOUR PARTICIPATION AS A MEMBER IN A CLASS ACTION FILED ON OR
              BEFORE AUGUST 20, 2011. THIS PROVISION IS NOT APPLICABLE TO THE
              EXTENT SUCH WAIVER IS PROHIBITED BY LAW.
              <br /> <div></div>
              YOU UNDERSTAND THAT YOU AND FINEART.HIPHOP ARE AGREEING TO GIVE UP
              ANY RIGHTS TO LITIGATE CLAIMS BEFORE A JURY.
            </p>
            <p>
              16.<span> Miscellaneous</span>. We will not be liable or
              responsible to you, nor be deemed to have defaulted or breached
              these Terms of Sale, for any failure or delay in our performance
              under these Terms of Sale when and to the extent such failure or
              delay is caused by or results from acts or circumstances beyond
              our reasonable control, including, without limitation, acts of
              God, flood, fire, earthquake, explosion, governmental actions,
              war, invasion or hostilities (whether war is declared or not),
              terrorist threats or acts, riot or other civil unrest, national
              emergency, revolution, insurrection, epidemic, lockouts, strikes
              or other labor disputes (whether or not relating to our
              workforce), restraints or delays affecting carriers, inability or
              delay in obtaining supplies of adequate or suitable materials, or
              telecommunication breakdown or power outage. The Purchase Terms,
              the Order Confirmation, and any other terms incorporated herein or
              therein by reference constitute the sole and entire agreement
              between you and FineArt.HipHop with respect to your purchase of
              products through the Digital Platform, and supersede all prior and
              contemporaneous understandings, agreements, representations and
              warranties, both written and oral, with respect thereto. <br />{" "}
              <div></div>
              The Purchase Terms and the relationship between you and us shall
              be governed by the laws of the State of New York, without regard
              to its conflict of law provisions. You agree that any cause of
              action that may arise under the Purchase Terms shall be commenced
              and be heard in the appropriate court in the State of New York,
              County of New York. You agree to submit to the personal and
              exclusive jurisdiction of the courts located within New York
              County in the State of New York. Our failure to exercise or
              enforce any right or provision of the Purchase Terms shall not
              constitute a waiver of such right or provision.
              <br /> <div></div>
              Any waiver of any provision of these Purchase Terms by
              FineArt.HipHop must be made in writing and signed by an authorized
              representative of FineArt.HipHop and the provision to be waived.
              Headings used in the Purchase Terms are for convenience only and
              are not to be relied upon. If any provision of the Purchase Terms
              is found by a court of competent jurisdiction to be invalid, the
              parties nevertheless agree that the court should endeavor to give
              effect to the parties' intentions as reflected in the provision,
              and the other provisions of the Purchase Terms remain in full
              force and effect. The Purchase Terms will inure to the benefit of,
              and are intended to be enforceable by, FineArt.HipHop successors,
              assigns and licensees. There are no third party beneficiaries to
              the Purchase Terms.
            </p>
            <p>
              17.<span> Export and Permits</span>. It is the purchaser’s sole
              responsibility to identify and obtain any necessary export,
              import, firearm, endangered species or other permit for the lot.
              Any notices in the sale e-catalog reflect FineArt.HipHop
              reasonable opinion at the time of cataloging and are for general
              guidance only; FineArt.HipHop and the consignor make no
              representations or warranties as to whether any lot is or is not
              subject to export or import restrictions or any embargoes.{" "}
            </p>
            <p>
              18.<span> Governing Law and Jurisdiction</span>. These Conditions
              of Sale and Terms of Guarantee, as well as the purchaser’s and our
              respective rights and obligations hereunder, shall be governed by
              and construed and enforced in accordance with the laws of the
              State of New Jersey. By placing an order on the Digital Platform,
              you shall be deemed to have consented to the exclusive
              jurisdiction of the state courts of, and the federal courts
              sitting in, the State of New Jersey. All parties agree, however,
              that FineArt.HipHop shall retain the right to bring proceedings in
              a court other than the state and federal courts sitting in the
              State of New Jersey.{" "}
            </p>
            <p>
              19.<span> Packing and Shipping</span>. We are not responsible for
              the acts or omissions in our packing or shipping of purchased
              items or of other carriers or packers of purchased items, whether
              or not recommended by us. Packing and handling of purchased items
              is at the entire risk of the purchaser.{" "}
            </p>
            <p>
              20.<span> Data Protection</span>. FineArt.HipHop will hold and
              process your personal information and may share it with
              FineArt.HipHop subsidiaries and affiliates for use as described
              in, and in line with, FineArt.HipHop Privacy Policy published on
              FineArt.HipHop website at www.fineart.hiphop or available on
              request by email to enquiries@fineart.hiphop.{" "}
            </p>
            <p>
              21.<span> Cancellation Right</span>. The Consumer Rights Directive
              (Directive 2011/83/EU) and related regulations implemented by the
              European Union (“EU”) member states (the “Regulations”) give
              consumers who are habitually resident within an EU member state
              (an “EU Purchaser”) who purchase goods and services online or
              otherwise “at a distance” certain cancellation rights under the
              Regulations. <br /> <div></div>
              Under the Regulations, an EU Purchaser who purchases property
              during the sale from a seller that is a trader has the right to
              cancel the contract (provided that such contract is a distance
              contract or an off-premises contract for the purposes of the
              Regulations) for any reason and without incurring liability within
              a period of fourteen days after the day on which the property
              comes into the physical possession of either the EU Purchaser or a
              person identified by the EU Purchaser to take possession of the
              property (but not the carrier of the property) (the “Goods
              Cancellation Period”).
              <br /> <div></div>
              Under the Regulations, in connection with the purchase of property
              from a seller that is not a trader, an EU Purchaser who purchases
              shipping services from FineArt.HipHop (the “Shipping Services”)
              has the right to cancel the Shipping Services for any reason
              within a period of fourteen days after the date on which the
              contract to provide the Shipping Services is concluded (the
              “Services Cancellation Period”) unless where at the EU Purchaser’s
              express request it has been agreed that the Shipping Services will
              begin during the Services Cancellation Period. In the case that
              the Shipping Services have begun prior to the expiry of the
              Services Cancellation Period, the EU Purchaser will have the right
              to cancel the Shipping Services during the Services Cancellation
              Period until the Shipping Services have been fully performed after
              which point the cancellation right will no longer apply. If the EU
              Purchaser lawfully cancels the contract for Shipping Services,
              FineArt.HipHop will reimburse the EU Purchaser all payments
              received from the EU Purchaser in relation to the Shipping
              Services provided always that if the EU Purchaser requested
              FineArt.HipHop to begin the performance of the Shipping Services
              during the Services Cancellation Period, the EU Purchaser will
              remain liable for FineArt.HipHop reasonable costs. To exercise the
              right to cancel, the EU Purchaser must inform FineArt.HipHop, Inc.
              at: Post Sale Services, 368 Rector Street, Unit 216, Perth Amboy,
              NJ 08861; telephone: +1 973 670 9337;
              uspostsaleservices@fineart.hiphop; of its decision to cancel the
              contract for the purchase of property and/or for the provision of
              Shipping Services (as applicable) by a clear statement (e.g. a
              letter sent by post, fax or email) or using the model cancellation
              form, a copy of which can be obtained from FineArt.HipHop, Inc. at
              the address noted above. In order to meet the applicable
              cancellation deadline, the EU Purchaser is required to send
              his/her communication concerning the exercise of the cancellation
              right (i) in relation to a purchase of property before the Goods
              Cancellation Period has expired and (ii) in relation to the
              provision of Delivery Services before the Services Cancellation
              Period has expired. In connection with any cancellation,
              FineArt.HipHop may require that you furnish us, upon our request,
              with any additional information necessary for FineArt.HipHop to
              assess whether in our would be deemed a trader under the
              Regulations.
              <br /> <div></div>
              If you cancel the contract for the purchase of property within the
              applicable Goods Cancellation Period, FineArt.HipHop, Inc. will
              reimburse you all payments received from you, including the costs
              of delivery but not any extra costs arising if you chose a type of
              delivery other than the least expensive type of standard delivery
              offered by us; or any import duties we incur as a result of you
              returning the property to us. FineArt.HipHop, Inc. may make a
              deduction from the reimbursement for loss in value of any property
              supplied, if the loss is the result of unnecessary handling by
              you. FineArt.HipHop, Inc. will make the reimbursement without
              undue delay, and not later than (i) 14 calendar days after the
              date on which we receive back from you any property supplied; or
              (ii) (if earlier) 14 calendar days after the date on which you
              provide evidence that you have returned the property.
              FineArt.HipHop, Inc. will make the reimbursement using the same
              means of payment as you used for the initial transaction, unless
              you have expressly agreed otherwise; in any event, you will not
              incur any fees as a result of the reimbursement. FineArt.HipHop,
              Inc. may withhold reimbursement until we have received the
              property back or you have supplied evidence of having sent back
              the property, whichever is earliest. You must send back the
              property or return them to us at such address as we may specify
              for the purpose, without undue delay and in any event not later
              than 14 calendar days from the date after the date on which you
              communicate your cancellation to us. The deadline is met if you
              send back the property before the period of 14 calendar days has
              expired. You will have to bear the costs of returning the
              property. If FineArt.HipHop, Inc. arranged for the property to be
              delivered we estimate that the cost of returning the property by
              the same means is likely to be similar to the cost of delivery.
              You are only liable for any diminished value of the property
              resulting from the handling other than what is necessary to
              establish the nature, characteristics and functioning of the lot.
              If you cancel the contract for the supply of Shipping Services
              within the applicable Services Cancellation Period, we will
              reimburse to you any payments received from you in respect of the
              service. However, if you have requested us to begin the
              performance of services during the Services Cancellation Period,
              you shall pay us an amount which is in proportion to what has been
              performed until you have communicated us your cancellation from
              this contract, in comparison with the full coverage of the
              contract.
            </p>
            <p>
              22.<span> Further Inquiries</span>. You may direct questions about
              the Purchase Terms, or other issues, to:{" "}
            </p>
            <p className="address-privacy-poli">
              Fineart.HipHop <br />
              368 Rector Street, Unit 216 <br />
              Perth Amboy, NJ 08861 <br />
              Attn: Website Management{" "}
            </p>
          </div>
          <p className="pri-poli-header-con-sec">
            <span>Last Modified: August 12, 2023 </span>{" "}
          </p>
        </div>
      </div>
    );
  }
}
export default TermsAndConditions;
