import React from "react";

import Carousel from "react-bootstrap/Carousel";
import { Link } from "react-router-dom";

import WordBanner from "./../../assets/image/word_banner.jpg";

import "./css/TheWordSlider.css";

import { getAllSiteManagersCall } from "./../../library/Requests/SiteManager";

import { getAllCategories } from "./../../library/Requests";

class TheWordSlider extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      allData: [],
      allCategories: [],
      hover: false,
    };
  }

  componentDidMount() {
    this.getAllSiteManagers();
  }
  getAllSiteManagers = async () => {
    const resp = await getAllSiteManagersCall("2");
    this.setState({ allData: resp?.data });
    const cate = await getAllCategories();
    this.setState({ allCategories: cate?.data });
  };

  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  onHover = () => {
    // this.setState({hover:true});
  };

  onLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    const { allData, allCategories, hover } = this.state;

    console.log(allData);
    return (
      <>
        <div className="word_slider_content new-word-content-section-btns">
          <Carousel interval={996000} fade>
            {/* {items.map((i) => { */}
            {(allData || []).map((i: any, index: any) => {
              let cateName = allCategories.find(
                (i2: any) => i.categoryId == i2?.id
              )?.category;
              let userLink = i.textLink;
              // if (i?.articleId && i?.articleId != "") {
              //   userLink = `/viewArticle/${i?.id}`;
              // }

              // var pageLink =
              //   index === 0 ? "/introducingFineArtHipHop" : `/theBirthOfHipHop`;
              const isRead = index === 0 || index === 2 ? true : false;
              const readClassNa = isRead ? "" : "View_class_nmae";
              // if (index === 3) {
              //   pageLink =
              //     "https://www.youtube.com/watch?v=pkZzDB6aHes&t=0s&ab_channel=Mr.ThrowbackThursdayPodcast";
              // } else if (index === 1) {
              //   pageLink =
              //     "https://www.instagram.com/tv/CKzcltVHcuJ/?igshid=MDJmNzVkMjY%3D";
              // }
              const pageLink = userLink;
              return (
                <Carousel.Item key={i.id} className="d-lg-flex">
                  <div className="slider_img">
                    <img
                      className="w-100"
                      src={i.image}
                      alt="benner-slider-img"
                    />
                  </div>
                  <Carousel.Caption className="text-left position-relative text-dark">
                    {hover && <span className="hoverText">{i.title}</span>}
                    <h3
                      onMouseEnter={this.onHover}
                      onMouseLeave={this.onLeave}
                      className="wordSliderTitle wordLineClamp"
                    >
                      {i.title}
                    </h3>
                    {/* <span>{cateName}</span> */}
                    <p>{i.subTitle}</p>
                    <a
                      href={pageLink}
                      target="_blank"
                      className={`text-decoration-none d-inline-block ${readClassNa}`}
                    >
                      {isRead ? "READ" : "VIEW"}
                    </a>
                  </Carousel.Caption>
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
      </>
    );
  }
}
export default TheWordSlider;
