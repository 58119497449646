import * as React from "react";
import { Link } from "react-router-dom";

import LiveActionLIstContent from "../../components/LiveActionLIstContent";
import LiveActionBoxTwo from "../../components/LiveActionBoxTwo";
import LiveActionVideoContent from "../../components/LiveActionVideoContent";
import { LOGINID, appendToMesaages } from "./../../Scenes/common";
import {
  getCollectionItemsCall,
  placeAutoBidCall,
  getcollectionitembyidCall,
  getbidincrementCall,
  getlivecollectionitemsCall,
  getCollectionByIdCall,
  getcurrenciesCall,
  followbidCall,
  gethighestbid,
  placeLiveBidCall,
} from "./../../library/Requests";

import "./css/LiveAuctionLanding.css";
import * as signalR from "@microsoft/signalr";
import { SERVER_IP } from "./../../library/constants.js";
import CustomModal from "./../../components/CustomModel";

class LiveAuctionLanding extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      currenciesList: [],
      collectionData: {},
      collectionItems: [],
      sortColumn: null,
      sortDirection: "asc",
      searchText: "",
      jsonData: {},
      collectionItemsFull: [],
      collectionId: 0,
      lotData: {},
      highestBidDetails: {},
      highestBidDetailsFull: {},
      lotItemStatus: "",
      bidIncrementData: [],
      dropdownvalues: [],
      showAlertModel: false,
      titleText: "",
      DeleteBtnText: "Delete",
      CancelBtnText: "Cancel",
      isAuctionCompleted: false,
      streamCode: "",
      BiddingLodding: false,
    };
  }
  componentDidMount() {
    this.getBidincrementData();
    this.getcurrenciesDataCall();
    this.getDataonLoad(null, "");
    const self = this;

    // const connection1 = new signalR.HubConnectionBuilder()
    //   .withUrl(`${SERVER_IP}/hubs/BidHub`)
    //   .build();
    // connection1.on("StartLiveSignal", (response) => {
    //   console.log(
    //     "We got signal! and the message is: " +
    //       response +
    //       " TIME " +
    //       new Date().getTime()
    //   );
    //   // Bind GetHightestBids here
    //   console.log(response);
    // });
    // connection1
    //   .start()
    //   .then((result) => {
    //     connection1.on("StartLiveSignal", (response) => {
    //       console.log(
    //         "We got signal! and the message is: " +
    //           response +
    //           " TIME " +
    //           new Date().getTime()
    //       );
    //       console.log(response);
    //       // Bind GetHightestBids here
    //       self.deleteHistoryData();
    //       // self.getDataonLoad();
    //       self.UpdateNewCollection(response);
    //       self.setState({
    //         streamCode: response.data.streamCode,
    //       });
    //     });
    //   })
    //   .catch((err) => console.error(err.toString()));

    // const connection = new signalR.HubConnectionBuilder()
    //   .withUrl(`${SERVER_IP}/hubs/BidHub`)
    //   .withAutomaticReconnect()
    //   .build();
    // connection.on("BidInsertSignal", (response) => {
    //   console.log(
    //     "We got signal! and the message is: " +
    //       response +
    //       " TIME " +
    //       new Date().getTime()
    //   );
    //   // Bind GetHightestBids here
    //   // alert(response);
    // });
    // connection
    //   .start()
    //   .then((result) => {
    //     connection.on("BidInsertSignal", (response) => {
    //       console.log(
    //         "We got signal! and the message is: " +
    //           response +
    //           " TIME " +
    //           new Date().getTime()
    //       );
    //       console.log(response);
    //       // Bind GetHightestBids here
    //       // self.getHighestBid(null);
    //       self.updateLatestData(response);
    //       if (response?.registrationNo && response?.currentBidAmount) {
    //         appendToMesaages(
    //           "#" +
    //             response?.registrationNo +
    //             "-sent_Online_" +
    //             response?.currentBidAmount
    //         );
    //       }
    //     });
    //   })
    //   .catch((err) => console.error(err.toString()));

    // const connection2 = new signalR.HubConnectionBuilder()
    //   .withUrl(`${SERVER_IP}/hubs/BidHub`)
    //   .build();
    // connection2.on("StatusSignal", (response) => {
    //   console.log(
    //     "We got signal! and the message is 111111 BidInsertSignal: " +
    //       response +
    //       " TIME " +
    //       new Date().getTime()
    //   );
    //   // Bind GetHightestBids here
    //   // alert(response);
    // });
    // connection2
    //   .start()
    //   .then((result) => {
    //     connection2.on("StatusSignal", (response) => {
    //       console.log(
    //         "We got signal! and the message is  2222222 BidInsertSignal: " +
    //           response +
    //           " TIME " +
    //           new Date().getTime()
    //       );
    //       console.log(response);
    //       // Bind GetHightestBids here
    //       self.deleteHistoryData();
    //       self.getDataonLoad(response, "StatusSignal");
    //     });
    //   })
    //   .catch((err) => console.error(err.toString()));

    // const connection3 = new signalR.HubConnectionBuilder()
    //   .withUrl(`${SERVER_IP}/hubs/BidHub`)
    //   .build();
    // connection3.on("AuctionClosedSignal", (response) => {
    //   console.log(
    //     "We got signal! and the message is 111111 BidInsertSignal: " +
    //       response +
    //       " TIME " +
    //       new Date().getTime()
    //   );
    //   // Bind GetHightestBids here
    //   // alert(response);
    // });
    // connection3
    //   .start()
    //   .then((result) => {
    //     connection3.on("AuctionClosedSignal", (response) => {
    //       console.log(
    //         "We got signal! and the message is  2222222 BidInsertSignal: " +
    //           response +
    //           " TIME " +
    //           new Date().getTime()
    //       );
    //       // Bind GetHightestBids here
    //       console.log(response);
    //       self.deleteHistoryData();
    //       self.auctionClosed(response);
    //     });
    //   })
    //   .catch((err) => console.error(err.toString()));

    // const connection4 = new signalR.HubConnectionBuilder()
    //   .withUrl(`${SERVER_IP}/hubs/BidHub`)
    //   .build();
    // connection4.on("BidingBotSignal", (response) => {
    //   console.log("We got signal! and the message is: " + response);
    //   // Bind GetHightestBids here
    //   // alert(response);
    // });
    // connection4
    //   .start()
    //   .then((result) => {
    //     connection4.on("BidingBotSignal", (response) => {
    //       console.log("We got signal! and the message is: " + response);
    //       console.log(response);
    //       // Bind GetHightestBids here
    //       self.getHighestBid(null);
    //     });
    //   })
    //   .catch((err) => console.error(err.toString()));
  }
  getBidincrementData = async () => {
    const jsonVal = await getbidincrementCall();
    this.setState({ bidIncrementData: jsonVal?.increment });
  };
  deleteHistoryData = () => {
    var elems = document.querySelectorAll<HTMLElement>("#messagesListParent");
    var index = 0,
      length = elems.length;
    for (; index < length; index++) {
      elems[index].innerHTML = " ";
    }
  };
  updateLatestData = (response: any) => {
    var LotId = response?.itemId;
    var pageLotId = this.state?.lotData?.id;
    if (LotId == pageLotId) {
      if (
        response?.errors?.length > 0 &&
        response?.errors[0] != undefined &&
        response?.errors[0] != ""
      ) {
        this.setState({
          showAlertModel: true,
          titleText: response?.errors[0],
          DeleteBtnText: "",
          CancelBtnText: "ok",
        });
      } else if (response?.nexBidAmount) {
        let highestBidDetails1 = this.state.highestBidDetails;
        let highestBidDetailsFull1 = this.state.highestBidDetailsFull;

        highestBidDetailsFull1.firstName = response?.firstName;
        highestBidDetailsFull1.lastName = response?.lastName;
        highestBidDetailsFull1.nextBidAmount = response?.nexBidAmount;

        highestBidDetails1.amount = response?.currentBidAmount;
        highestBidDetails1.id = response?.id;
        highestBidDetails1.metResevedPrice = response?.metResevedPrice;
        highestBidDetails1.registrationNo = response?.registrationNo;

        // this.setState({highestBidDetails:bidResp?.bidDetails});
        this.setState(
          {
            highestBidDetailsFull: highestBidDetailsFull1,
            highestBidDetails: highestBidDetails1,
          },
          () => {
            this.generateDropDowns(highestBidDetails1);
          }
        );
      } else {
        this.getHighestBid(null);
      }
    }
  };

  UpdateNewCollection = (response: any) => {
    var nextLotId = response?.data?.itemId;
    var collectionID = response?.data?.collectionId;
    const id = this.props?.match?.params?.id;
    const { collectionItems } = this.state;
    if (collectionID == id && nextLotId != "") {
      let finaalAction = collectionItems.filter(
        (item: any) => item.id == nextLotId
      );
      if (finaalAction?.length && finaalAction?.length > 0) {
        let lotDeta = finaalAction[0];
        this.getlotFullData(lotDeta?.id);
        this.setState({ lotData: lotDeta, lotItemStatus: lotDeta?.itemStatus });
        this.getHighestBid(lotDeta?.id);
      }
    } else {
      // this.getDataonLoad(null);
    }
  };
  auctionClosed = (response: any) => {
    this.setState({ isAuctionCompleted: true });
  };
  getlotFullData = async (id: any) => {
    let resp = await getcollectionitembyidCall(id);
    this.setState({ lotData: resp?.data });
  };
  getcurrenciesDataCall = async () => {
    const resp = await getcurrenciesCall();
    this.setState({ currenciesList: resp?.data });
  };
  getDataonLoad = async (response: any, signalType: any) => {
    if (response && response?.status) {
      this.setState({ lotItemStatus: response?.status });
    }
    const { sortColumn, sortDirection } = this.state;
    const id = this.props?.match?.params?.id;
    if (id) {
      const body = {
        page: 1,
        pageSize: 50,
        getOnlyTotalCount: false,
        collectionId: id,
        sortColumn: sortColumn,
        sortDirection: sortDirection,
        customerId: LOGINID,
        status: [1, 6, 7],
      };
      this.setState({ jsonData: body, collectionId: id });
      this.getCollectionItems(body);
      if (signalType != "StatusSignal") {
        this.getCollectionData(id);
      }
    }
  };
  getCollectionItems = async (body: any) => {
    // let resp = await getCollectionItemsCall(body);
    let resp = await getlivecollectionitemsCall(body);

    this.setState({ collectionItems: resp?.data, collectionItemsFull: resp });
    // triggerEventForProfileUpdate(UPDATE_FILTERS);
    if (resp?.data?.length > 0) {
      let finaalAction = resp?.data.filter((item: any) => item.isLive == true);
      if (finaalAction?.length && finaalAction?.length > 0) {
        let lotDeta = finaalAction[0];
        this.getlotFullData(lotDeta?.id);
        this.setState({ lotData: lotDeta, lotItemStatus: lotDeta?.itemStatus });
        this.getHighestBid(lotDeta?.id);
      }
    }
  };
  getHighestBid = async (id: any) => {
    let lotIdsnew = 0;
    if (id) {
      lotIdsnew = id;
    } else {
      lotIdsnew = this.state.lotData?.id;
    }
    if (lotIdsnew) {
      const bidResp = await gethighestbid(lotIdsnew);
      this.setState({
        highestBidDetails: bidResp?.bidDetails,
        highestBidDetailsFull: bidResp,
      });
      this.generateDropDowns(bidResp?.bidDetails);
    }
  };
  getCollectionData = async (id: any) => {
    let resp = await getCollectionByIdCall(id);
    this.setState({ collectionData: resp?.data });
    if (resp?.data?.endOnUtc && resp?.data?.endOnUtc != "") {
      this.setState({ isAuctionCompleted: true });
    }
  };
  updateSelectedLot = async (index: any) => {
    const { collectionItems } = this.state;
    this.setState({ lotData: collectionItems[index] });
    this.getHighestBid(collectionItems[index]?.id);
  };
  triggerBidCall = async (amount: any, typebid: any) => {
    const { lotData, currenciesList, collectionData } = this.state;
    let currencyName = currenciesList.find(
      (i: any) => collectionData?.currency == i?.id
    )?.currencyCode;

    if (typebid == "maxBid") {
      const body = {
        autoBid: {
          itemId: lotData?.id,
          maxAmount: amount,
          currency: currencyName,
          registrationNo: collectionData?.registrationNo,
          type: "Online",
          customerId: LOGINID,
        },
      };
      this.setState({ BiddingLodding: true });
      let resp = await placeAutoBidCall(body);
      this.setState({ BiddingLodding: false });
      if (
        resp?.errors?.length > 0 &&
        resp?.errors[0] != undefined &&
        resp?.errors[0] != ""
      ) {
        this.setState({
          showAlertModel: true,
          titleText: resp?.errors[0],
          DeleteBtnText: "",
          CancelBtnText: "ok",
        });
      }
    } else {
      const body = {
        itemId: lotData?.id,
        amount: amount,
        currency: currencyName,
        registrationNo: collectionData?.registrationNo,
        type: "Online",
      };
      this.setState({ BiddingLodding: true });
      let resp = await placeLiveBidCall(body);
      this.setState({ BiddingLodding: false });
      if (
        resp?.errors?.length > 0 &&
        resp?.errors[0] != undefined &&
        resp?.errors[0] != ""
      ) {
        this.setState({
          showAlertModel: true,
          titleText: resp?.errors[0],
          DeleteBtnText: "",
          CancelBtnText: "ok",
        });
      }
      // /api/Bid/placeLiveBid
    }
  };
  generateDropDowns = (bidDetails: any) => {
    const { bidIncrementData } = this.state;

    const iniPrice = bidDetails?.amount
      ? bidDetails?.amount
      : this.state?.lotData?.initialBiddingPrice;
    if (iniPrice && bidIncrementData) {
      const amou1 = iniPrice;
      let bidAmout = parseInt(amou1);
      let tempFullStr = "";
      (bidIncrementData || []).map((item: any, index: any) => {
        if (item.priceFrom <= bidAmout && item.priceTo >= bidAmout) {
          let increMA = item.increment;
          bidAmout = bidAmout + increMA;
          tempFullStr = tempFullStr + "___" + bidAmout;
          const priceDiff = item.priceTo - item.priceFrom;
          const repeat = (priceDiff / increMA).toFixed();
          const repeatT = parseInt(repeat) + 2;
          for (var i = 0; i < repeatT; i++) {
            if (item.priceFrom <= bidAmout && item.priceTo >= bidAmout) {
              bidAmout = bidAmout + increMA;
              tempFullStr = tempFullStr + "___" + bidAmout;
            }
          }
        }
      });
      var dropdownvalues = tempFullStr.split("___");
      this.setState({ dropdownvalues: dropdownvalues });
    }
  };
  DeleteConfirm = () => {};
  viewMoreClick = () => {
    if (this.state?.collectionData?.id)
      this.props.history.push(
        `/collectionDetails/${this.state?.collectionData?.id}`
      );
  };

  render() {
    const idCollection = this.props?.match?.params?.id;
    const {
      currenciesList,
      collectionData,
      collectionItems,
      collectionItemsFull,
      lotData,
      highestBidDetails,
      highestBidDetailsFull,
      dropdownvalues,
      lotItemStatus,
      showAlertModel,
      titleText,
      DeleteBtnText,
      CancelBtnText,
      isAuctionCompleted,
      streamCode,
    } = this.state;
    return (
      <>
        <div className="live_auction_page live_auction_flex d-flex flex-wrap">
          <LiveActionLIstContent
            currenciesList={currenciesList}
            collectionData={collectionData}
            collectionItems={collectionItems}
            updateSelectedLot={this.updateSelectedLot}
            lotData={lotData}
          />
          <LiveActionBoxTwo
            lotData={lotData}
            currenciesList={currenciesList}
            collectionData={collectionData}
          />
          <LiveActionVideoContent
            lotData={lotData}
            BiddingLodding={this.state.BiddingLodding}
            currenciesList={currenciesList}
            collectionData={collectionData}
            highestBidDetails={highestBidDetails}
            triggerBidCall={this.triggerBidCall}
            idCollection={idCollection}
            highestBidDetailsFull={highestBidDetailsFull}
            lotItemStatus={lotItemStatus}
            dropdownvalues={dropdownvalues}
            streamCode={streamCode}
          />
          <CustomModal
            show={showAlertModel}
            titleText={titleText}
            deleteText={DeleteBtnText}
            cancelText={CancelBtnText}
            onCancel={() => this.setState({ showAlertModel: false })}
            onDelete={() => this.DeleteConfirm()}
          />
          <CustomModal
            viewMoreClick={this.viewMoreClick}
            show={isAuctionCompleted}
            isAuctionCompleted={true}
          />
        </div>
      </>
    );
  }
}

export default LiveAuctionLanding;
