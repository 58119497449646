import { useState } from "react";
import { ReactComponent as NextIcon } from "../../assets/HomePage/hero-image-left-arrow.svg";
import styled from "styled-components";
import { ReactComponent as NextIconWhite } from "../../assets/HomePage/hero-image-right-white-icon.svg";

const PreviousArrow = (props: any) => {
  const { onClick } = props;
  const [hovered, setHovered] = useState(false);
  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <PrevArrowStyles>
      <div
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="next-arrow-sec"
        onClick={onClick}
      >
        {!hovered ? (
          <div className="next-icon">
            <NextIcon onClick={() => onClick()} />
          </div>
        ) : (
          <>
            <div className="next-icon-white">
              <NextIconWhite
                onClick={() => onClick()}
                className="next-icon-white"
              />
            </div>
            <div className="next-button">Previous</div>
          </>
        )}
      </div>
    </PrevArrowStyles>
  );
};

export default PreviousArrow;

export const PrevArrowStyles = styled.div`
  .next-arrow-sec {
    position: absolute;
    top: 46%;
    left: 16px;
    border-radius: 24px;
    display: flex;
    cursor: pointer;
  }
  .next-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 24px;
    background: #fff;
    z-index: 2;
    svg {
      width: 26px;
      height: 26px;
    }
  }

  .next-icon-white {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 24px;
    background: #000;
    z-index: 2;
    svg {
      width: 26px;
      height: 26px;
      transform: rotate(180deg);
    }
  }
  .next-button {
    background: #fff;
    width: 114px;
    border-top-right-radius: 24px;
    border-bottom-right-radius: 24px;
    position: absolute;
    height: 40px;
    left: 18px;
    z-index: 1;
    padding: 10px 0 10px 30px;
    color: #000;
    // font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.5px;
    text-align: left;
  }
`;
